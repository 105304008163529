// import sum from 'lodash/sum'
// import merge from 'lodash/merge'
import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import moment from 'moment'
// import { getFormValues } from 'redux-form'
import { denormalize } from 'normalizr'

import { currentCustomer, customerAssortment, isPo98Customer } from 'store/customers/selectors'
import { order as orderSchema } from 'store/schema'
import { createSelector } from 'store/selectors'

import { DOUBLE_NESTED_BRANDS, SOX_COMPLIANCE_MATERIAL_GROUP_CODES } from 'utils/constants'

const fullState = (state) => state
const allOrders = (state) => omit(state.orders, '_persist')
// const createSAQFormRegisteredFields = state => state.form.createSAQ?.registeredFields
// const saqFormValues = state => getFormValues('createSAQ')(state)
const preferredLanguage = (state) => state.auth.user?.preferredLanguage || 'english'
const saqValuesFromInsight = (state, props) => props.location.state

export const lastOrderSync = createSelector(allOrders, (orders) =>
  Object.values(orders).reduce(
    (acc, { updatedAt, status }) =>
      ['processing', 'addedToOrder', 'failure'].some((s) => s === status) && updatedAt
        ? acc
          ? moment.max(acc, moment(updatedAt))
          : moment(updatedAt)
        : acc,
    null
  )
)

const customerIdFromProps = (state, props) => +props.customerId

export const lastSubmittedEmergencyOrder = createSelector(allOrders, customerIdFromProps, (orders, customerId) => {
  return Object.values(orders).reduce(
    (max, o) =>
      o.customerId === customerId &&
      o.type === 'emergency' &&
      !['needsApproval', 'awaitingManagerApproval', 'awaitingRetailerApproval'].includes(o.status)
        ? moment.max(max, moment(o.createdAt))
        : max,
    moment(0)
  )
})

// export const isEmergencyOrder = createSelector(saqFormValues, values => values?.type !== 'saq')

/*
const totalsByBrand = createSelector(saqFormValues, saqRegisteredFields, (values, registeredFields) => {
  if (!values || !registeredFields) return {}
  const updatedTotals = Object.entries(values).reduce((acc, [key, value]) => {
    if (typeof value !== 'object') {
      return acc
    }
    const brandRegisteredFields = registeredFields[key]
    if (!brandRegisteredFields) return acc
    const validEntries = pick(value, brandRegisteredFields)
    acc[key] = sum(Object.values(validEntries))
    return acc
  }, {})
  return updatedTotals
})

export const saqTotalForBrand = createSelector(totalsByBrand, totalsByBrand => {
  return brand => {
    const qtysToSum = pick(totalsByBrand, brand)
    return sum(Object.values(qtysToSum))
  }
})

export const saqTotalQuantity = createSelector(productsByBrand, totalsByBrand, (productsByBrand, totalsByBrand) => {
  const saqProductKeys = Object.entries(productsByBrand).reduce((acc, [brand, products]) => {
    const hasVariants = !Array.isArray(products)
    return hasVariants ? acc.concat(Object.keys(products)) : acc.concat(brand)
  }, [])
  return sum(saqProductKeys.map(brand => totalsByBrand[brand] || 0))
})
*/

export const orderIdFromUrl = (state, props) => props.orderId
const baseOrderFromUrl = createSelector(orderIdFromUrl, allOrders, (orderId, allOrders) => {
  return orderId ? allOrders[orderId] : null
})
export const orderFromUrl = createSelector(baseOrderFromUrl, fullState, (order, state) => {
  if (!order) return null
  return denormalize(order, orderSchema, state)
})

export const initialSaqValuesFromInsight = createSelector(
  customerAssortment,
  saqValuesFromInsight,
  (assortment, saqValuesFromRouteState) => {
    if (!saqValuesFromRouteState) return null
    const { projectId, saqValues } = saqValuesFromRouteState
    if (!projectId && isEmpty(saqValues)) return null
    const productIds = Object.keys(saqValues)
    const productsToPopulate = assortment.filter(({ id }) => productIds.includes(String(id)))
    const initialVals = productsToPopulate.reduce((acc, { brand, variant, id, isBlocked }) => {
      const label = camelCase(DOUBLE_NESTED_BRANDS.includes(brand) ? variant : brand)
      const skuQty = isBlocked ? 0 : saqValues[id]
      return {
        ...acc,
        [label]: { ...acc[label], [`p${id}`]: skuQty }
      }
    }, {})
    return { projectId, entries: initialVals }
  }
)

export const isSaqTypeBlocked = createSelector(currentCustomer, (currentCustomer) => {
  return moment().isSameOrAfter('2024-06-30') && ['NEEDS', 'NEEDS FAST FUEL'].includes(currentCustomer?.banner.name)
})

export const orderInitialValues = createSelector(
  customerAssortment,
  isPo98Customer,
  preferredLanguage,
  baseOrderFromUrl,
  initialSaqValuesFromInsight,
  currentCustomer,
  isSaqTypeBlocked,
  (assortment, isPo98, preferredLanguage, order, initialSaqValuesFromInsight, currentCustomer, isSaqTypeBlocked) => {
    const { entries: originalEntries = [], ...originalValues } = order || {}
    const { entries: i2aInitialEntries = null, projectId = null } = initialSaqValuesFromInsight || {}
    const baseValues = {
      preferredLanguage,
      type: isPo98 ? 'po98' : isSaqTypeBlocked ? 'emergency' : 'saq',
      emergencyFlag: isSaqTypeBlocked,
      sendEmailCopy: isPo98,
      projectId
    }
    const po98Values = isPo98
      ? {
          po98Reason: '',
          po98CoordinatorName: '',
          po98CoordinatorApproved: '',
          po98Comments: ''
        }
      : null

    const entries = assortment.reduce((acc, { englishBrand, englishVariant, id, isBlocked }) => {
      const name = camelCase(DOUBLE_NESTED_BRANDS.includes(englishBrand) ? englishVariant : englishBrand)
      const { qty } = originalEntries.find(({ productId }) => productId === id) || { qty: 0 }
      const existingProductGroup = acc[name] || null
      return {
        ...acc,
        [name]: {
          ...existingProductGroup,
          [`p${id}`]: isBlocked ? 0 : qty
        }
      }
    }, {})

    return {
      ...baseValues,
      ...po98Values,
      ...originalValues,
      entries: { ...entries, ...i2aInitialEntries }
    }
  }
)

export const saqSoxMaterialGroupIds = createSelector(customerAssortment, (customerAssortment) => {
  if (!customerAssortment?.length) return []
  return customerAssortment
    .filter(({ materialGroupCode }) => SOX_COMPLIANCE_MATERIAL_GROUP_CODES.includes(materialGroupCode))
    .map(({ id }) => String(id))
})
