import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import { func, object } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchFilters, fetchGeography } from 'store/priceCaptureCompliance/actions'
import { getFilters, getGeography } from 'store/priceCaptureCompliance/selectors'

import Card from 'components/card'
import DataCombined from 'components/DataTable/DataCombined'
import DataTable from 'components/DataTable/DataTable'
import EmptyState from 'components/EmptyState'
import FilteredDropdown from 'components/FilteredDropdown'
import Pagination from 'components/Pagination'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES, DEFAULT_TABLE_PAGE_SIZE, PLACEHOLDERS, SECTOR_LEVELS } from 'utils/constants'
import { formatCurrency, formatPercent } from 'utils/formatters'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import GeographyFilters from '../GeographyFilters'
import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'

const createOptions = (filters) => {
  let options = []
  if (typeof filters[0] === 'string') {
    options = filters.map((filter) => ({ label: filter, value: filter }))
  } else {
    options = filters.map((filter) => ({ label: filter.name, value: filter.id }))
  }
  return sortBy(options, 'label')
}

const GeographyTable = ({ span, fetchFilters, fetchGeography }) => {
  const { translate } = useContext(LangContext)
  const { sectorType, sectorId } = useParams()
  const { currentProductType: productType } = useContext(SectorContext)
  const [error, setError] = useState()
  const [geography, setGeography] = useState('territory')

  const BASE_COLS = [
    {
      field: 'name',
      headerName: 'Region'
    },
    {
      field: 'storesUpdated',
      headerName: translate('app.storesUpdated'),
      filter: 'footprint'
    },
    {
      field: 'storesRemaining',
      headerName: translate('app.storesRemaining'),
      filter: 'footprint'
    },
    {
      field: 'storesCount',
      headerName: translate('app.totalStores'),
      filter: 'footprint'
    },
    {
      field: 'skusUpdated',
      headerName: translate('app.skusUpdated'),
      filter: 'sku_completion'
    },
    {
      field: 'skusRemaining',
      headerName: translate('app.skusRemaining'),
      filter: 'sku_completion'
    },
    {
      field: 'skusTotal',
      headerName: translate('app.skusTotal'),
      filter: 'sku_completion'
    },
    {
      field: 'captureStatus',
      headerName: 'Status',
      filter: 'sku_geography'
    },
    {
      field: 'price',
      headerName: 'Price',
      filter: 'customer_sku_geography'
    }
  ]

  const defaultFilters = {
    channel: 'all',
    ownershipType: 'all',
    headoffice: 'all',
    banner: 'all',
    tier: 'all',
    material: 'all'
  }

  const [filters, setFilters] = useState(defaultFilters)

  const [page, setPage] = useState(1)

  const filterDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.FILTERS, {
    sectorType,
    sectorId,
    productType
  })
  const areFiltersLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: filterDataKey }))
  const geographyOptions = useSelector((state) => getFilters(state, { productType }))

  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const geographyDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.GEOGRAPHY, {
    sectorType,
    sectorId,
    productType,
    geography,
    filters,
    offset
  })

  const areGeographyLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: geographyDataKey }))
  const rawGeographies = useSelector((state) => getGeography(state) || [])

  const makeRow = useCallback(
    ({
      id,
      name,
      storesUpdated,
      storesPercent,
      storesRemaining,
      storesCount,
      skusUpdated,
      skusPercent,
      skusRemaining,
      skusTotal,
      isCaptured,
      upc,
      price
    }) => {
      const shouldLinkTo =
        ['region', 'district', 'territory', 'customer'].includes(geography) ||
        (geography === 'sku' && sectorType === SECTOR_LEVELS.CUSTOMER)

      const link =
        shouldLinkTo && geography === 'sku' && sectorType === SECTOR_LEVELS.CUSTOMER
          ? `/customer/${sectorId}/actions/pricing/${upc}`
          : `/${geography}/${id}/pace/amplify/price-capture`

      const compoundName = geography === 'sku' ? `${name} - ${id}` : name

      const row = {
        name: shouldLinkTo ? <Link to={link}>{compoundName}</Link> : compoundName,
        skusUpdated: (
          <DataCombined
            primary={skusUpdated ? (+skusUpdated).toLocaleString() : PLACEHOLDERS.NO_VALUE}
            secondary={`${
              skusPercent ? formatPercent(skusPercent, { convertDecimal: true }) : PLACEHOLDERS.NO_VALUE_PERCENT
            }`}
          />
        ),
        skusRemaining: skusRemaining ? (+skusRemaining).toLocaleString() : PLACEHOLDERS.NO_VALUE,
        skusTotal: skusTotal ? (+skusTotal).toLocaleString() : PLACEHOLDERS.NO_VALUE
      }

      if (sectorType === SECTOR_LEVELS.CUSTOMER) {
        row.captureStatus = isCaptured ? translate('app.updated') : translate('app.notUpdated')
        if (geography === 'sku') {
          row.price = formatCurrency(price)
        }
      }
      if (!(sectorType === SECTOR_LEVELS.CUSTOMER && geography === 'sku')) {
        row.storesUpdated = (
          <DataCombined
            primary={storesUpdated ? (+storesUpdated).toLocaleString() : PLACEHOLDERS.NO_VALUE}
            secondary={`${
              storesPercent ? formatPercent(storesPercent, { convertDecimal: true }) : PLACEHOLDERS.NO_VALUE_PERCENT
            }`}
          />
        )
        row.storesRemaining = storesRemaining ? (+storesRemaining).toLocaleString() : PLACEHOLDERS.NO_VALUE
        row.storesCount = storesCount ? (+storesCount).toLocaleString() : PLACEHOLDERS.NO_VALUE
      }
      return row
    },
    [geography, sectorType, sectorId]
  )

  const geographies = useMemo(() => {
    const { channel, ownershipType, banner, headoffice, tier, material } = filters
    const geographies =
      rawGeographies[
        `geography-${geography}-${productType}-${channel}-${ownershipType}-${banner}-${headoffice}-${tier}-${material}-offset-${offset}`
      ] || []
    return geographies.map(makeRow)
  }, [rawGeographies, sectorType, sectorId, geography, productType, filters, areGeographyLoading, offset])

  const channelOptions = useMemo(() => {
    const options = createOptions(geographyOptions?.channels || [])
    return [{ label: translate('app.allChannels'), value: 'all' }].concat(options)
  }, [sectorType, sectorId, productType, areFiltersLoading])
  const ownershipOptions = useMemo(() => {
    const options = createOptions(geographyOptions?.ownershipTypes || [])
    return [{ label: translate('app.allOwnershipTypes'), value: 'all' }].concat(options)
  }, [sectorType, sectorId, productType, areFiltersLoading])
  const headofficeOptions = useMemo(() => {
    const options = createOptions(geographyOptions?.headoffices || [])
    return [{ label: translate('app.allHeadoffices'), value: 'all' }].concat(options)
  }, [sectorType, sectorId, productType, areFiltersLoading])
  const bannerOptions = useMemo(() => {
    const options = createOptions(geographyOptions?.banners || [])
    return [{ label: translate('app.allBanners'), value: 'all' }].concat(options)
  }, [sectorType, sectorId, productType, areFiltersLoading])
  const tierOptions = useMemo(() => {
    const options = createOptions(geographyOptions?.tiers || [])
    return [{ label: translate('app.allTiers'), value: 'all' }].concat(options)
  }, [sectorType, sectorId, productType, areFiltersLoading])
  const materialOptions = useMemo(() => {
    const options = createOptions(geographyOptions?.materials || [])
    return [{ label: translate('app.allBVLS'), value: 'all' }].concat(options)
  }, [sectorType, sectorId, productType, areFiltersLoading])

  const isMounted = useRef()

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    fetchFilters({ productType, sectorType, sectorId, dataKey: filterDataKey })
      .then(() => setError())
      .catch((e) => setError(e))
  }, [sectorType, sectorId, productType])

  useEffect(() => {
    setPage(1)
    fetchGeography({
      dataKey: geographyDataKey,
      sectorType,
      sectorId,
      productType,
      geography,
      filters,
      offset,
      limit: DEFAULT_TABLE_PAGE_SIZE
    })
      .then(() => setError())
      .catch((e) => setError(e))
  }, [sectorType, sectorId, productType, geography, filters])

  useEffect(() => {
    fetchGeography({
      dataKey: geographyDataKey,
      sectorType,
      sectorId,
      productType,
      geography,
      filters,
      offset,
      limit: DEFAULT_TABLE_PAGE_SIZE
    })
      .then(() => setError())
      .catch((e) => setError(e))
  }, [offset])

  useEffect(() => {
    setPage(1)
  }, [geography, filters])

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (isEmpty(geographies) || geographies.length < DEFAULT_TABLE_PAGE_SIZE) return
    setPage(page + 1)
  }

  const getContent = () => {
    if (areGeographyLoading) return <WrappedSpinner icon="spinner" />

    if (error) {
      return <EmptyState title={getErrorMessage(error)} />
    }

    if (!geographies || !geographies.length) return <EmptyState title="Nothing to display" />

    let cols = BASE_COLS
    cols[0].headerName = translate(displayOptTableLabels(geography))
    if (sectorType !== SECTOR_LEVELS.CUSTOMER || geography !== 'sku') {
      cols = cols.filter((c) => c.filter !== 'customer_sku_geography')
    }
    if (sectorType !== SECTOR_LEVELS.CUSTOMER) {
      cols = cols.filter((c) => c.filter !== 'sku_geography')
    } else {
      cols = cols.filter((c) => c.filter !== 'footprint')
    }

    return <DataTable columns={cols} rows={geographies} fillContainer />
  }

  const appliedFilters = Object.values(filters).filter((value) => value !== 'all').length

  const resetFilters = () => {
    setFilters(defaultFilters)
  }

  if (areFiltersLoading)
    return (
      <Card
        title="Performance"
        disclaimer="The KPIs below are calculated using data from the previous day and may not reflect today's price capture"
        span={span}
      >
        <WrappedSpinner icon="spinner" />
      </Card>
    )

  const geoFilters = [
    {
      label: translate('app.tier'),
      searchPlaceholder: translate('app.placeholders.searchTier'),
      options: tierOptions,
      value: filters.tier,
      onChange: (value) => setFilters({ ...filters, tier: value })
    },
    {
      label: translate('app.channel'),
      searchPlaceholder: translate('app.placeholders.searchChannel'),
      options: channelOptions,
      value: filters.channel,
      onChange: (value) => setFilters({ ...filters, channel: value })
    },
    {
      label: translate('app.ownership'),
      searchPlaceholder: translate('app.placeholders.searchOwnership'),
      options: ownershipOptions,
      value: filters.ownershipType,
      onChange: (value) => setFilters({ ...filters, ownershipType: value })
    },
    {
      label: translate('app.banner'),
      searchPlaceholder: translate('app.placeholders.searchBanner'),
      options: bannerOptions,
      value: filters.banner,
      onChange: (value) => setFilters({ ...filters, banner: value })
    },
    {
      label: translate('app.headOffice'),
      searchPlaceholder: translate('app.placeholders.searchHeadOffice'),
      options: headofficeOptions,
      value: filters.headoffice,
      onChange: (value) => setFilters({ ...filters, headoffice: value })
    },
    {
      label: translate('app.material'),
      searchPlaceholder: translate('app.placeholders.searchMaterial'),
      options: materialOptions,
      value: filters.material,
      onChange: (value) => setFilters({ ...filters, material: value })
    }
  ]

  return (
    <Card
      hideOnSmallScreens={true}
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <GeographyToggle
          key="price-capture-geo-toggle"
          includeChannel
          includeBrand
          includeOwnership
          includeSku
          includeBrandVariant
          geography={geography}
          setGeography={setGeography}
        />,
        <GeographyFilters
          key="geography-filters"
          appliedFilters={appliedFilters}
          resetFilters={resetFilters}
          filters={geoFilters.map((filter, i) => (
            <FilteredDropdown
              key={i}
              label={filter.label}
              searchPlaceholder={filter.searchPlaceholder}
              options={filter.options}
              value={filter.value}
              onChange={filter.onChange}
            />
          ))}
        />
      ]}
      actions={[
        <Pagination
          key="price-capture-pagination"
          currentPage={page}
          onClickPrev={prevPage}
          onClickNext={nextPage}
          disabled={areGeographyLoading}
          disableNextButton={geographies.length < DEFAULT_TABLE_PAGE_SIZE}
        />
      ]}
    >
      {getContent()}
    </Card>
  )
}

GeographyTable.propTypes = {
  span: object,
  fetchFilters: func,
  fetchGeography: func
}

export default connect(null, {
  fetchFilters,
  fetchGeography
})(GeographyTable)
