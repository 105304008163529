import React, { useState } from 'react'
import { mix, stripUnit } from 'polished'
import { array } from 'prop-types'
import styled, { css } from 'styled-components'

import Icon from 'components/Icon'

import { black, borderColor, greyDark, white } from 'styles/colors'
import { animationCurve, animationTime, borderRadius, boxShadow, tabBarHeight } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { smallFontSize } from 'styles/typography'

const Container = styled.div`
  position: fixed;
  bottom: calc(${tabBarHeight} + ${spacing.medium});
  right: ${spacing.medium};
  z-index: 40;
`

const Button = styled.button`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  padding: ${spacing.medium};
  background-color: ${(props) => props.color || '#162A5F'};
  transition: background-color ${animationTime} ${animationCurve};
  ${boxShadow};

  ${media.breakpoint`
    width: 64px;
    height: 64px;
    bottom: ${spacing.large};
    right: ${spacing.large};
    padding: 20px;
  `};

  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) => mix(0.93, props.color || '#162A5F', black)};
  }
`

const IconWrap = styled.div`
  display: flex;
  transition: transform ${animationTime} ${animationCurve};

  ${(props) =>
    props.rotated &&
    css`
      transform: rotate(45deg);
    `};
`

const Menu = styled.div`
  position: absolute;
  bottom: 100%;
  right: 0;
  width: 180px;
  margin-bottom: ${spacing.small};
  background-color: ${white};
  border-radius: ${borderRadius};
  padding: ${spacing.medium};
  display: grid;
  grid-gap: ${stripUnit(spacing.small) * 1.5 + 'px'};
  opacity: 0;
  transition: opacity ${animationTime} ${animationCurve};
  border: 1px solid ${borderColor};
  ${boxShadow};

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
    `};

  ${(props) =>
    !props.visible &&
    css`
      pointer-events: none;
    `};
`

const Item = styled.button`
  display: flex;
  align-items: center;
  gap: ${spacing.small};
  padding: ${spacing.small};
  margin: -${spacing.small};
  font-size: ${smallFontSize};
  transition: color ${animationTime} ${animationCurve};

  &:hover {
    color: ${greyDark};
  }
`

const FAB = ({ items }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const handleMenuClick = ({ handleClick, ...item }) => {
    if (handleClick) handleClick(item)
    setMenuOpen(false)
  }
  return (
    <Container>
      {/* {menuOpen && <Overlay onClick={() => setMenuOpen(false)} />} */}
      <Button onClick={() => setMenuOpen(!menuOpen)}>
        <IconWrap rotated={menuOpen}>
          <Icon icon="plus" white />
        </IconWrap>
      </Button>

      <Menu visible={menuOpen}>
        {items.map((item, i) => (
          <Item key={i} onClick={() => handleMenuClick(item)}>
            <Icon icon={item.icon} small />
            {item.label}
          </Item>
        ))}
      </Menu>
    </Container>
  )
}

FAB.propTypes = {
  items: array.isRequired
}

export default FAB
