import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { arrayOf, bool, func, number, object, shape } from 'prop-types'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import theme from 'tailwindcss/colors'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchTrendingInventory } from 'store/inventory/actions'
import { getCurrentTrending } from 'store/inventory/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import SegmentControl from 'components/SegmentControl'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { formatNumber } from 'utils/formatters'
import { createDataKey, generateYAxis, getErrorMessage } from 'utils/helpers'

import InventoryCaptureContext from './InventoryCaptureContext'

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload[0]) {
    const value = formatNumber(payload[0].value)
    return (
      <div className="w-auto -translate-x-1/2 space-y-1 rounded bg-black/90 px-3 py-2 text-white">{`${value}%`}</div>
    )
  }
}

CustomTooltip.propTypes = {
  active: bool,
  payload: arrayOf(shape({ value: number }))
}

const Trending = ({ span, fetchTrendingInventory }) => {
  const [view, setView] = useState('oos')
  const [error, setError] = useState()

  const { sectorType, sectorId } = useParams()
  const { translate } = useContext(LangContext)
  const { currentProductType: activeProductType } = useContext(SectorContext)

  const { vapeCategory } = useContext(InventoryCaptureContext)

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.INVENTORY_OOS.TRENDING, {
    sectorType,
    sectorId,
    productType: activeProductType,
    vapeCategory
  })
  const isTrendingLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const data = useSelector((state) => getCurrentTrending(state, { activeProductType, vapeCategory }))

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (activeProductType !== 'vape' && vapeCategory !== 'all') return

    fetchTrendingInventory({ sectorType, sectorId, dataKey, activeProductType, vapeCategory })
      .then(() => {
        if (isMounted.current) setError()
      })
      .catch((e) => {
        if (isMounted.current) setError(e)
      })
  }, [sectorType, sectorId, activeProductType, vapeCategory])

  return (
    <Card
      title={translate('app.trendedView')}
      span={span}
      headerActions={[
        <SegmentControl
          key={0}
          name="View type"
          onChange={(e) => setView(e.target.value)}
          value={view}
          options={[
            {
              label: translate('app.OOS'),
              value: 'oos'
            },
            {
              label: translate('app.InvCapture'),
              value: 'inventory'
            }
          ]}
        />
      ]}
    >
      {isTrendingLoading ? (
        <WrappedSpinner icon="spinner" />
      ) : (
        <>
          {error ? (
            <EmptyState title={getErrorMessage(error)} />
          ) : (
            <div className="h-[300px] w-full pt-5">
              <ResponsiveContainer height="99%" width="99%">
                <LineChart
                  margin={{ left: 20, right: 20, top: 10 }}
                  data={data[view].map((d) => ({ ...d, data: d.data * 100 }))}
                >
                  <CartesianGrid stroke={theme.slate[300]} vertical={false} />
                  <XAxis
                    style={{
                      fontSize: '10px'
                    }}
                    axisLine={false}
                    dataKey="name"
                    dy={10}
                    stroke={theme.slate[500]}
                    tickLine={false}
                    minTickGap={1}
                    padding={{ left: 2, right: 2 }}
                  />
                  <YAxis
                    allowDecimals={false}
                    interval="preserveStartEnd"
                    style={{
                      fontSize: '12px'
                    }}
                    axisLine={false}
                    dx={-8}
                    stroke={theme.slate[500]}
                    tickLine={false}
                    unit={'%'}
                    width={32}
                    domain={generateYAxis({
                      entries: data[view].map((d) => ({ ...d, data: d.data * 100 })),
                      dataFormat: 'percent'
                    })}
                  />
                  <Tooltip
                    content={<CustomTooltip />}
                    wrapperStyle={{
                      outline: 'none'
                    }}
                    animationDuration={300}
                    allowEscapeViewBox={{ x: true }}
                    cursor={false}
                    offset={0}
                  />
                  <Line
                    dataKey="data"
                    strokeWidth={3}
                    stroke="#D43C80"
                    dot={false}
                    activeDot={{ stroke: theme.white, strokeWidth: 4, r: 6 }}
                    isAnimationActive={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}
        </>
      )}
    </Card>
  )
}

Trending.propTypes = {
  span: object,
  fetchTrendingInventory: func
}

export default connect(null, {
  fetchTrendingInventory
})(Trending)
