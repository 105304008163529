import React from 'react'
import { camelCase } from 'lodash'
import { array, bool, number, object } from 'prop-types'
import tw, { styled } from 'twin.macro'

import { greyLightest, white } from 'styles/colors'

const TD = styled.td`
  ${tw`px-3 py-3 first:pl-0 last:pr-0 group-last:pb-0 text-sm font-medium text-slate-900`}
`

const ValueWrapper = styled.div(({ alignCenter }) => [tw`flex items-center`, alignCenter && tw` justify-center`])

function getTableBgColor(alternatingColumns, index) {
  if (alternatingColumns === 0 || index === 0) return white

  if (Math.floor((index - 1) / alternatingColumns) % 2 === 0) {
    return white
  }
  return greyLightest
}

const TableRow = ({ rowData, alignCenter, alternatingColumns }) => {
  return (
    <tr className="group">
      {Object.entries(rowData).map(([key, value], index) => {
        return (
          <TD
            key={key}
            style={{
              backgroundColor: getTableBgColor(alternatingColumns, index)
            }}
          >
            <ValueWrapper alignCenter={key === 'geo' ? false : alignCenter}>{value}</ValueWrapper>
          </TD>
        )
      })}
    </tr>
  )
}

TableRow.propTypes = {
  rowData: object,
  alignCenter: bool,
  alternatingColumns: number
}

const Table = styled.table(({ fillContainer, fullWidth }) => [
  tw`relative table-auto border-separate border-spacing-0`,
  fillContainer && tw`h-full w-full`,
  fullWidth && !fillContainer && tw`w-full`
])

const TableHeader = styled.th(({ alignCenter }) => [
  tw`text-center align-bottom bg-white z-10 top-0 pb-3 px-3 first:pl-0 last:pr-0 text-sm font-medium text-slate-500 border-b border-slate-300`
])

/**
 * @typedef Column
 * @property {string} title The title (label) of the column
 * @property {string} field The field (value) if the column
 * @property {Column[]} subColumns Any subcolumns included inside the column
 *
 * +-----------------------------------+
 * |               Title               |
 * +-----------------+-----------------+
 * | subColumn.title | subColumn.title |
 * +-----------------+-----------------+
 */
/**
 * @typedef ColumnSpecification
 * @property {string} title Title (label) of the column
 * @property {string} field Field (value) of the column
 * @property {number} rowspan Amount of rows the cell is going to take
 * @property {number} colspan Amount of columns the cell is going to take
 * @property {ColumnSpecification[]} subColumns
 */

/**
 *
 * @param {ColumnSpecification} column
 * @param {number} finalHeight
 */
const calculateRowspan = (column, finalHeight) => {
  if (column.subColumns && column.subColumns.length) {
    column.subColumns.forEach((col) => {
      calculateRowspan(col, column.height)
    })
  }
  column.rowspan = finalHeight - column.height
  delete column.height
}

/**
 * Recursive function creating the WideDataTable column specifications. Only fill the first parameter
 * Calculates the rowspans and colspans a header needs to take.
 * @param {Column[]} columns The columsn to create
 * @param {string} [title] Do not fill. Used by the recursive function
 * @param {string} [field] Do not fill. Used by the recursive function
 *
 * @returns {ColumnSpecification[] | ColumnSpecification} will return a single ColumnSpecification during recursivity only. Otherwise always expect a ColumnSpecification[]
 */
export const createColumns = (columns, title = '', field = '') => {
  const self = {
    title,
    field,
    height: 1, // This value is used to calculate the final rowspan and will be deleted before returning the specification
    rowspan: 1,
    colspan: 1,
    subColumns: []
  }
  if (columns?.length) {
    // Setting colspan to 0 to avoid having a span 1 too wide
    self.colspan = 0
    self.height += 1
    // Create subcolumns and calculate height
    columns.forEach((subColumn) => {
      const iterationResult = createColumns(
        subColumn.subColumns,
        subColumn.title,
        camelCase(`${self.field}_${subColumn.field}`)
      )
      if (iterationResult.height >= self.height) {
        self.height = iterationResult.height + 1
      }
      self.colspan += iterationResult.colspan
      self.subColumns.push(iterationResult)
    })
  }
  // we are the root, only return the subcolumns
  if (!title) {
    self.subColumns.forEach((column) => {
      calculateRowspan(column, self.height)
    })
    return self.subColumns
  }
  return self
}

/**
 * @param {ColumnSpecification} spec
 * @returns
 */
const createCell = (spec, rowIndex) => {
  return (
    <TableHeader key={spec.field + rowIndex} colSpan={spec.colspan} rowSpan={spec.rowspan}>
      {spec.title}
    </TableHeader>
  )
}

/**
 * @param {ColumnSpecification[]} columns
 */
const createHeader = (columns) => {
  /**
   * @param {any[]} acc
   * @param {ColumnSpecification} column
   * @param {number} index
   * @param {any[]} array
   * @param {number} depth
   */
  function reducer(depth, acc, column) {
    if (acc.length < depth) acc.push([])
    if (column.subColumns && column.subColumns.length) {
      column.subColumns.reduce(reducer.bind(this, depth + 1), acc)
    }
    const currentRow = acc[depth - 1]
    currentRow.push(createCell(column, currentRow.length))
    return acc
  }

  return (
    <thead>
      {columns.reduce(reducer.bind(null, 1), []).map((row, i) => (
        <tr key={i}>{row}</tr>
      ))}
    </thead>
  )
}

/**
 * @param {object} props
 * @param {ColumnSpecification[]} props.columns
 * @returns
 */
const WideDataTable = ({ columns, rows, alignCenter, fillContainer, fullWidth, alternatingColumns = 0 }) => {
  return (
    <div className="flow-root">
      <div className="-mx-6 overflow-x-auto">
        <div className="relative inline-block min-w-full max-w-[500px] px-6 align-middle">
          <Table fillContainer={fillContainer} fullWidth={fullWidth}>
            {createHeader(columns)}
            <tbody>
              {rows.map((row, i) => (
                <TableRow key={i} rowData={row} alignCenter={alignCenter} alternatingColumns={alternatingColumns} />
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  )
}

WideDataTable.propTypes = {
  columns: array,
  rows: array,
  alignCenter: bool,
  fillContainer: bool,
  fullWidth: bool,
  alternatingColumns: number
}

export default WideDataTable
