import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func, object } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchStoreFootprint } from 'store/priceCaptureCompliance/actions'
import { getStoreFootprint } from 'store/priceCaptureCompliance/selectors'

import Card from 'components/card'
import DataTable from 'components/DataTable/DataTable'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const StoreFootprint = ({ span, fetchStoreFootprint }) => {
  const [error, setError] = useState()
  const { sectorType, sectorId } = useParams()
  const { currentProductType: productType } = useContext(SectorContext)
  const { translate } = useContext(LangContext)

  const COLS = [
    {
      field: 'y',
      headerName: ''
    },
    {
      field: 'pos',
      headerName: translate('app.acronyms.POS')
    },
    {
      field: 'ind',
      headerName: translate('app.independent')
    },
    {
      field: 'vs',
      headerName: translate('app.vapeshop')
    }
  ]

  const storeFootprint = useSelector((state) => getStoreFootprint(state, { productType }))

  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.STORE_FOOTPRINT, {
    sectorType,
    sectorId,
    productType
  })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  useEffect(() => {
    fetchStoreFootprint({ dataKey, sectorType, sectorId, productType })
      .then(() => isMounted.current && setError())
      .catch((e) => isMounted.current && setError(e))
  }, [sectorType, sectorId, productType])

  const getContent = () => {
    if (sectorType === 'customer') return <EmptyState title="No relevant data" />

    if (isLoading) return <WrappedSpinner icon="spinner" />

    if (error) {
      return <EmptyState title={getErrorMessage(error)} />
    }

    const rows = [
      {
        y: translate('app.storesUpdated'),
        pos: storeFootprint.POS.storesUpdated,
        ind: storeFootprint.IND.storesUpdated,
        vs: storeFootprint.VS.storesUpdated
      },
      {
        y: translate('app.storesRemaining'),
        pos: storeFootprint.POS.storesRemaining,
        ind: storeFootprint.IND.storesRemaining,
        vs: storeFootprint.VS.storesRemaining
      },
      {
        y: `${translate('app.storesUpdated')} (%)`,
        pos: `${storeFootprint.POS.storesPercent}%`,
        ind: `${storeFootprint.IND.storesPercent}%`,
        vs: `${storeFootprint.VS.storesPercent}%`
      }
    ]

    return <DataTable columns={COLS} rows={rows} fillContainer />
  }
  return (
    <Card title="Store Footprint" span={span} displayAmplify={false}>
      {getContent()}
    </Card>
  )
}

StoreFootprint.propTypes = {
  span: object,
  fetchStoreFootprint: func
}

export default connect(null, {
  fetchStoreFootprint
})(StoreFootprint)
