// Note: Style template code.

import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Promise } from 'bluebird'
import config from 'config'
import { arrayOf, func, object, string } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchPlanningEngagement } from 'store/Engagement/actions'
import { planningEngagementData } from 'store/Engagement/selectors'
import { fetchActivity } from 'store/extraHub/actions'
import { getCurrentActivity } from 'store/extraHub/selectors'

import IconButton from 'components/button/IconButton'
import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import LoadingCard from 'components/LoadingCard'
import CardPlaceholder from 'components/Placeholders/CardPlaceholder'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const HubData = ({ children, description }) => {
  return (
    <div className="flex w-full flex-col items-center">
      <span className="text-xl font-medium text-slate-900">{children}</span>
      <span className="text-2xs font-medium text-slate-500">{description}</span>
    </div>
  )
}

HubData.propTypes = {
  children: arrayOf(string),
  description: string
}

const Eyebrow = tw.hr`w-full h-px bg-slate-100`

const Header = tw.span`text-lg font-semibold text-slate-500 w-full flex justify-center`

const TopSection = tw.div`space-y-5`

const BottomSection = tw.div`flex flex-col gap-5 grow`

const THTop = tw.th`text-lg font-medium text-slate-500 text-center`

const THLeft = tw.th`text-lg font-medium text-slate-500 text-left`

const TData1 = tw.td`font-medium text-xl text-slate-900 text-center`

const TData2 = tw.td`font-medium text-xl text-slate-500 text-center`

const Title = tw.span`inline-flex items-center h-9 text-xl font-semibold text-slate-900`

const EngagementCard = ({ fetchPlanningEngagement, fetchActivity, span, amplifyAction }) => {
  const { translate } = useContext(LangContext)
  const { currentSector, selectedLevel } = useContext(SectorContext)

  const { sectorType, sectorId } = useParams()

  const isMounted = useRef()

  const [error, setError] = useState()
  const engagementData = useSelector((state) => planningEngagementData(state))
  const currentActivity = useSelector((state) => getCurrentActivity(state))

  const dataKey = createDataKey(DATAKEY_TYPES.PLANNING_ENGAGEMENT, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id
  })

  const dataKeyExtraHub = createDataKey(DATAKEY_TYPES.PLANNING_ENGAGEMENT_EXTRA_HUB, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id
  })

  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const isExtraHubLoading = useSelector((state) => isDataKeyLoading(state, { dataKeyExtraHub }))

  useEffect(() => {
    isMounted.current = true
    setError()

    if (currentSector[selectedLevel]?.id) {
      Promise.all([
        fetchPlanningEngagement({ id: currentSector[selectedLevel].id, sectorLevel: selectedLevel }, dataKey),
        config.featureFlags.extrahubPace ? fetchActivity({ dataKey: dataKeyExtraHub, sectorType, sectorId }) : true
      ]).catch((error) => {
        if (isMounted.current) setError(getErrorMessage(error))
      })
    }

    return () => {
      isMounted.current = false
    }
  }, [currentSector, selectedLevel])

  return (
    <Card title={translate('app.engagement')} span={span}>
      {error ? (
        <EmptyState title={translate('app.warn.errorOccured')} subtitle={error} />
      ) : (
        <>
          <TopSection>
            <Eyebrow />
            <div className="flex grow flex-col items-center gap-3">
              <Header>{translate('app.callsPerDay')}</Header>
              {isLoading ? (
                <WrappedSpinner icon="spinner" />
              ) : (
                <table className="w-full table-auto border-separate border-spacing-y-2">
                  <tbody>
                    <tr>
                      <th></th>
                      <THTop>{translate('common.visit')}</THTop>
                      <THTop>{translate('common.time')}</THTop>
                    </tr>
                    <tr>
                      <THLeft>{translate('common.week')}</THLeft>
                      <TData1>
                        {engagementData.week.completionPercentage * 100
                          ? (engagementData.week.completionPercentage * 100).toLocaleString()
                          : '-'}
                        %
                      </TData1>
                      <TData1>
                        {engagementData.week.averageCallTime
                          ? engagementData.week.averageCallTime.toLocaleString()
                          : '-'}
                        min
                      </TData1>
                    </tr>
                    <tr>
                      <THLeft>{translate('common.cycle')}</THLeft>
                      <TData2>
                        {engagementData.cycle.completionPercentage
                          ? (engagementData.cycle.completionPercentage * 100).toLocaleString()
                          : '-'}
                        %
                      </TData2>
                      <TData2>
                        {engagementData.cycle.averageCallTime
                          ? engagementData.cycle.averageCallTime.toLocaleString()
                          : '-'}
                        min
                      </TData2>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </TopSection>
          {config.featureFlags.extrahubPace ? (
            <BottomSection>
              <Eyebrow />
              <div className="flex grow flex-col gap-3">
                {isExtraHubLoading ? (
                  <WrappedSpinner icon="spinner" />
                ) : (
                  <>
                    <div className="flex items-center justify-between">
                      <Title>{translate('app.extraHubActivity')}</Title>
                      {amplifyAction && (
                        <div className="space-x-3">
                          <IconButton secondary icon="three-stars" onClick={amplifyAction}>
                            {translate('app.pace.amplify')}
                          </IconButton>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col space-y-3">
                      <div className="flex">
                        <HubData
                          description={`of ${currentActivity.totalErp || '0'} ${translate('app.stores').toLowerCase()}`}
                        >
                          {currentActivity.erp || '-'}%
                        </HubData>
                        <HubData
                          description={`of ${currentActivity.totalUser || '0'} ${translate('app.users').toLowerCase()}`}
                        >
                          {currentActivity.user || '-'}%
                        </HubData>
                      </div>
                      <HubData description={translate('app.availableRedeemed')}>${currentActivity.coins || '-'}</HubData>
                    </div>
                  </>
                )}
              </div>
            </BottomSection>
          ) : (
            <BottomSection>
              <Eyebrow />
              <div className="flex grow flex-col gap-3">
                <Title>{translate('app.extraHubActivity')}</Title>
                <CardPlaceholder>
                  <div className="flex w-full flex-col gap-3">
                    <div className="flex">
                      <HubData description="of 15 stores">87%</HubData>
                      <HubData description="of 56 users">86%</HubData>
                    </div>
                    <HubData description={translate('app.availableRedeemed')}>$431</HubData>
                  </div>
                </CardPlaceholder>
              </div>
            </BottomSection>
          )}
        </>
      )}

      <LoadingCard dataKey={dataKey} />
    </Card>
  )
}

EngagementCard.propTypes = {
  fetchPlanningEngagement: func,
  fetchActivity: func,
  span: object,
  amplifyAction: func
}

const mapActionCreators = {
  fetchPlanningEngagement,
  fetchActivity
}

export default connect(null, mapActionCreators)(EngagementCard)
