import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import { func, number, string } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchDistroGeographyData } from 'store/distro/actions'
import * as api from 'store/distro/endpoints'
import { geographyDistroData } from 'store/distro/selectors'

import Card from 'components/card'
import DataCompare from 'components/DataTable/DataCompare'
import DataGraph from 'components/DataTable/DataGraph'
import DataTable from 'components/DataTable/DataTable'
import EmptyState from 'components/EmptyState'
import FilteredDropdown from 'components/FilteredDropdown'
import Pagination from 'components/Pagination'
import Spinner from 'components/Spinner'

import { DATAKEY_TYPES, DEFAULT_TABLE_PAGE_SIZE } from 'utils/constants'
import { formatNumber, formatPercent } from 'utils/formatters'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import GeographyFilters from '../GeographyFilters'
import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'

import DistroContext from './DistroContext'

const SpinnerContainer = tw.div`flex justify-center items-center w-full h-full`

function toFixed(value) {
  return Math.round((value || 0) * 100) / 100
}

const AmplifyDistroTableCard = ({ span, fetchDistroGeographyData, vapeCategory = 'all' }) => {
  const { translate } = useContext(LangContext)
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const { vapeChannel } = useContext(DistroContext)

  const [geography, setGeography] = useState('region')
  const [error, setError] = useState()
  const [ownershipFilter, setOwnershipFilter] = useState('all')
  const [brandsFilter, setBrandsFilter] = useState('all')
  const [materialFilter, setMaterialFilter] = useState('all')
  const [page, setPage] = useState(1)

  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (isEmpty(geographyData) || geographyData.length < DEFAULT_TABLE_PAGE_SIZE) return
    setPage(page + 1)
  }

  const tableFilters = useMemo(() => {
    return {
      ...(ownershipFilter !== 'all' ? { ownershipFilter } : {}),
      ...(brandsFilter !== 'all' ? { brandsFilter } : {}),
      ...(materialFilter !== 'all' ? { materialFilter } : {})
    }
  }, [ownershipFilter, brandsFilter, materialFilter])

  const { data: filterOptions } = useQuery({
    queryKey: ['distroFilterOptions'],
    queryFn: async () => {
      const { data } = await api.fetchDistroFilterOptions()
      return data
    },
    staleTime: 1000 * 60 * 15 // 15 minutes
  })

  const mounted = useRef(false)

  const columns = [
    {
      field: 'geo',
      headerName: translate('app.geo.region')
    },
    selectedLevel !== 'customer' && {
      field: 'stores',
      headerName: translate('app.storesNotInDistro')
    },
    selectedLevel !== 'customer' && {
      field: 'storesTotal',
      headerName: translate('app.totalStores')
    },
    selectedLevel !== 'customer' && {
      field: 'storedistro',
      headerName: translate('app.storeDistro')
    },
    {
      field: 'skus',
      headerName: translate('app.SKUNotInDistro')
    },
    {
      field: 'skusTotal',
      headerName: translate('app.totalSKUs')
    },
    {
      field: 'skudistro',
      headerName: translate('app.SKUDistro')
    },
    !['customer', 'territory'].includes(selectedLevel) && {
      field: 'weighteddistro',
      headerName: translate('app.wdistro')
    },
    !['customer'].includes(selectedLevel) && {
      field: 'salescontribution',
      headerName: translate('app.salesContribution')
    },
    selectedLevel !== 'customer' && {
      field: 'weightedL13',
      headerName: translate('app.weightedDistroTrend'),
      isLarge: true
    },
    selectedLevel === 'customer' && {
      field: 'skuL13',
      headerName: translate('app.SKUDistroTrend'),
      isLarge: true
    }
  ].filter(Boolean)

  columns[0].headerName = translate(displayOptTableLabels(geography))

  const geographyData = useSelector((state) =>
    geographyDistroData(state, {
      activeProductType: currentProductType,
      geography,
      vapeCategory,
      vapeChannel,
      offset,
      filters: tableFilters
    })
  )

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.DISTRO.GEOGRAPHY, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id,
    productType: currentProductType,
    filters: tableFilters,
    geography,
    offset,
    vapeCategory,
    vapeChannel
  })

  const isGeographyLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    setError()
    if (currentSector[selectedLevel]?.id) {
      setPage(1)
      fetchDistroGeographyData(
        {
          sectorId: currentSector[selectedLevel].id,
          sectorType: selectedLevel,
          geography,
          productType: currentProductType,
          vapeCategory,
          vapeChannel,
          offset,
          filters: tableFilters,
          limit: DEFAULT_TABLE_PAGE_SIZE
        },
        dataKey
      ).catch((error) => {
        if (mounted.current) setError(getErrorMessage(error))
      })
    }
  }, [currentSector, selectedLevel, currentProductType, geography, vapeCategory, vapeChannel, tableFilters])

  useEffect(() => {
    setError()
    if (currentSector[selectedLevel]?.id) {
      fetchDistroGeographyData(
        {
          sectorId: currentSector[selectedLevel].id,
          sectorType: selectedLevel,
          geography,
          productType: currentProductType,
          vapeCategory,
          vapeChannel,
          offset,
          filters: tableFilters,
          limit: DEFAULT_TABLE_PAGE_SIZE
        },
        dataKey
      ).catch((error) => {
        if (mounted.current) setError(getErrorMessage(error))
      })
    }
  }, [offset])

  const graphRows = useMemo(() => {
    if (Object.values(geographyData)?.length) {
      return Object.values(geographyData).map((d) => {
        const displayName = geography === 'sku' ? `${d.name} - ${d.id}` : d.name
        return {
          geo: d.linkTo ? <Link to={d.linkTo}>{displayName}</Link> : displayName,
          stores: formatNumber(d.nbStoresNotInDistro),
          storesTotal: formatNumber(d.nbStoresTotal),
          storedistro: <DataCompare last={toFixed(d.storeDistro)} variation={d.storeDistroDiff} isPercent />,
          skus: formatNumber(+d.nbSkusNotInDistro),
          skusTotal: formatNumber(+d.nbSkusTotal),
          skudistro: <DataCompare last={toFixed(d.skuDistro)} variation={d.skuDistroDiff} isPercent />,
          weighteddistro: <DataCompare last={toFixed(d.salesDistro)} variation={d.salesDistroDiff} isPercent />,
          salescontribution: (
            <DataCompare last={toFixed(d.salesContribution)} variation={d.salesContributionDiff} isPercent />
          ),
          weightedL13: (
            <DataGraph
              color="#53CCF8"
              data={d.weightedL13}
              dataFormatter={(v) => formatPercent(v, { convertDecimal: false })}
            />
          ),
          skuL13: (
            <DataGraph
              color="#53CCF8"
              data={d.skuL13}
              dataFormatter={(v) => formatPercent(v, { convertDecimal: false })}
            />
          )
        }
      })
    }
    return []
  }, [geographyData])

  const allDescriptionLabel =
    currentProductType === 'fmc' ? translate('app.allBVLS') : translate('app.allMaterialDescription')
  const allBrandLabel = currentProductType === 'fmc' ? translate('app.allBrands') : translate('app.allBrandVariants')

  const ownershipOptions = [
    { label: translate('app.allOwnershipTypes'), value: 'all' },
    { label: translate('app.DODOIndependent'), value: 'DODO & Independent' },
    { label: translate('app.acronyms.COCO'), value: 'COCO' },
    { label: translate('app.acronyms.CODO'), value: 'CODO' }
  ]

  const brandsOptions = [
    { label: allBrandLabel, value: 'all' },
    ...(filterOptions && currentProductType === 'fmc'
      ? filterOptions.brands
          .filter((b) => b.english_brand && b.product_type === currentProductType)
          .map((b) => ({ label: b.english_brand, value: b.english_brand }))
      : []),
    ...(filterOptions && currentProductType === 'vape'
      ? filterOptions.brandVariants
          .filter((b) => b.english_variant && b.product_type === currentProductType)
          .map((b) => ({ label: b.english_variant, value: b.english_variant }))
      : [])
  ]

  const materialOptions = [
    { label: allDescriptionLabel, value: 'all' },
    ...(filterOptions
      ? filterOptions.skus
          .filter((b) => b.english_name && b.product_type === currentProductType)
          .map((b) => ({ label: b.english_name, value: b.id }))
      : [])
  ]

  const getContent = (rows) => {
    if (error) {
      return <EmptyState title={translate('app.warn.errorOccured')} subtitle={getErrorMessage(error)} />
    }

    if (isGeographyLoading) {
      return (
        <SpinnerContainer>
          <Spinner icon="spinner" />
        </SpinnerContainer>
      )
    }

    if (!rows || !rows.length) {
      return <EmptyState title={translate('app.warn.nothingToDisplay')} />
    }

    return <DataTable columns={columns} rows={rows} fillContainer />
  }

  const appliedFilters = [ownershipFilter, brandsFilter, materialFilter].filter((value) => value !== 'all').length
  const resetFilters = () => {
    setOwnershipFilter('all')
    setBrandsFilter('all')
    setMaterialFilter('all')
  }

  const geoFilters = [
    {
      label: translate('app.ownership'),
      searchPlaceholder: translate('app.placeholders.searchOwnership'),
      options: ownershipOptions,
      value: ownershipFilter,
      onChange: (value) => setOwnershipFilter(value)
    },
    {
      label: currentProductType === 'fmc' ? translate('app.brands') : translate('app.brandVariant'),
      searchPlaceholder: translate('app.placeholders.searchBrand'),
      options: brandsOptions,
      value: brandsFilter,
      onChange: (value) => setBrandsFilter(value)
    },
    {
      label: translate('app.material'),
      searchPlaceholder: translate('app.placeholders.searchMaterial'),
      options: materialOptions,
      value: materialFilter,
      onChange: (value) => setMaterialFilter(value)
    }
  ]

  return (
    <Card
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <GeographyToggle
          key="geography-toggle"
          includeBrand
          includeOwnership
          includeSku
          includeBrandVariant
          geography={geography}
          setGeography={setGeography}
        />,
        <GeographyFilters
          key="geography-filters"
          appliedFilters={appliedFilters}
          resetFilters={resetFilters}
          filters={geoFilters.map((filter, i) => (
            <FilteredDropdown
              key={i}
              label={filter.label}
              searchPlaceholder={filter.searchPlaceholder}
              options={filter.options}
              value={filter.value}
              onChange={filter.onChange}
            />
          ))}
        />
      ]}
      hideOnSmallScreens={true}
      actions={[
        <Pagination
          key="pagination"
          currentPage={page}
          onClickPrev={prevPage}
          onClickNext={nextPage}
          disabled={isGeographyLoading}
          disableNextButton={graphRows.length < DEFAULT_TABLE_PAGE_SIZE}
        />
      ]}
    >
      {getContent(graphRows)}
    </Card>
  )
}

AmplifyDistroTableCard.propTypes = {
  span: number,
  fetchDistroGeographyData: func,
  vapeCategory: string
}

export default connect(null, { fetchDistroGeographyData })(AmplifyDistroTableCard)
