import React, { Children, cloneElement, useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Field, useFormikContext } from 'formik'
import { stripUnit } from 'polished'
import { any, bool, node, number, object, string } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'

import { getCurrentCustomer } from 'store/sector/selectors'

import Icon from 'components/Icon'
import Pill from 'components/Pill'
import QuantitySelect from 'components/QuantitySelect'

import { MAX_ZONNIC_CTNS, SKU_MAX_QTY_BY_BANNER, ZONNIC_MATERIAL_GROUP_CODE } from 'utils/constants'

import { borderColor, primaryColor, red, secondaryColor, white } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { tinyFontSize } from 'styles/typography'

const Container = styled.div`
  position: relative;
  display: block;
  background-color: ${white};
  border-top: 1px solid ${borderColor};

  ${(props) =>
    !props.isSubrow &&
    media.breakpoint`
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};

    &:first-child {
      border-top-left-radius: ${borderRadius};
      border-top-right-radius: ${borderRadius};
    }

    &:last-child {
      border-bottom-left-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    }
  `};

  &:last-child {
    border-bottom: ${(props) => (props.isSubrow ? '0' : '1')}px solid ${borderColor};
    ${(props) =>
      props.isSubrow &&
      `
      padding-bottom: 0;
    `};
  }
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: ${spacing.medium};
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`

const Content = styled.main`
  display: ${(props) => (props.expanded ? 'block' : 'none')};
  padding: ${spacing.medium};
  padding-top: 0;
  ${(props) =>
    props.hasSubrows &&
    `
    padding-bottom: 0;
  `};
`

const Quantity = styled(Pill)`
  margin-left: auto;
  font-variant-numeric: tabular-nums;
  text-align: center;
`

const Entry = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${spacing.xLarge};
  margin: 0;

  & + & {
    margin-top: ${stripUnit(spacing.small) * 1.5 + 'px'};
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Chevron = styled(({ expanded, ...rest }) => Icon(rest))`
  margin-right: ${spacing.small};
  flex-shrink: 0;
  transform: ${(props) => (props.expanded ? 'rotate(90deg)' : 'none')};
`
const ErrorMessage = styled.small`
  color: ${red};
  display: flex;
  flex-direction: row;
  margin-top: ${spacing.small};
  font-size: ${tinyFontSize};
`
const DetailMessage = styled(ErrorMessage)`
  color: ${secondaryColor};
`
function productIsZonnicAndRestricted(materialGroupCode) {
  return materialGroupCode === ZONNIC_MATERIAL_GROUP_CODE && new Date() < new Date('2023-11-18')
}

// https://bat-canada.atlassian.net/jira/software/projects/NOD/boards/5?selectedIssue=NOD-1542
// function isCa20RyderInON(customer) {
//   return customer?.address.state === 'ON' && customer?.carrier === 'CA20-RYDER'
// }
const oosChecksBySku = {
  // '10160881': customer => ['BC', 'AB', 'SK', 'MB'].includes(customer?.address.state),
  // '10185563': customer => isCa20RyderInON(customer)
}
//

function isProductOutOfStock(sku, customer) {
  const oosChecker = oosChecksBySku[sku]
  if (!oosChecker) return false
  return oosChecker(customer)
}

function SAQAccordion({
  label,
  brand,
  products,
  order,
  children,
  isSubrow,
  parentCollapsed,
  parent,
  hasSubrows,
  totalProducts,
  isRestrictedBannerInRestrictedPeriod
}) {
  const [expanded, setExpanded] = useState(false)
  const minimalValue = 0
  const customer = useSelector((state) => getCurrentCustomer(state))
  const formikContext = useFormikContext()
  const { translate } = useContext(LangContext)

  useEffect(() => {
    parentCollapsed && setExpanded(false)
  }, [parentCollapsed])

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const getStep = useCallback(
    (materialGroupCode, productId) => {
      // Keeping this code in case we want to do mutlipliers again
      // https://bat-canada.atlassian.net/browse/NOD-1537
      // https://bat-canada.atlassian.net/browse/ADV-548
      // https://bat-canada.atlassian.net/browse/ADV-551
      return 1
    },
    [customer?.outletSubtype, customer?.address.state]
  )

  const validateQuantity = useCallback(
    (quantity, materialGroupCode, productId) => {
      if (+quantity % getStep(materialGroupCode, productId))
        return `Only quantities in multiples of 10 can be created for SAQ’s and emergency orders with disposables`
      if (+quantity < minimalValue) return `You can't order less than ${minimalValue} items`
      if (productIsZonnicAndRestricted(materialGroupCode) && +quantity > MAX_ZONNIC_CTNS)
        return `You cannot order more than ${MAX_ZONNIC_CTNS} Zonnic products`
    },
    [getStep]
  )

  return (
    <Container expanded={expanded} isSubrow={isSubrow}>
      <Header onClick={toggleExpanded}>
        <TitleWrap>
          <Chevron icon="right-chevron" accent expanded={expanded} />
          <h3 className="notranslate text-lg font-medium" translate="no">
            {label || brand}
          </h3>
        </TitleWrap>
        {totalProducts > 0 && <Quantity color={primaryColor}>{totalProducts}</Quantity>}
      </Header>
      <Content expanded={expanded} hasSubrows={Boolean(children)}>
        {children && Children.map(children, (child) => cloneElement(child, { parentCollapsed: !expanded }))}
        {products &&
          products.length &&
          products.map(({ name, id, materialGroupCode, isBlocked, blockedSkuMessage }) => {
            const disabled = isRestrictedBannerInRestrictedPeriod && SKU_MAX_QTY_BY_BANNER.skus.includes(id)
            const skuOutOfStock = isProductOutOfStock(id, customer)
            const max = productIsZonnicAndRestricted(materialGroupCode) ? { max: 10 } : null
            const step = getStep(materialGroupCode, id)
            return (
              <Entry key={`${brand}-${id}`}>
                <Row>
                  <p className="notranslate text-sm" translate="no">
                    {name}
                  </p>
                  {skuOutOfStock ? (
                    <span>Out of stock</span>
                  ) : (
                    <Field
                      name={`${parent}.${brand}.p${id}`}
                      component={QuantitySelect}
                      type="number"
                      min={minimalValue}
                      validate={(value) => validateQuantity(value, materialGroupCode, id)}
                      disabled={disabled || skuOutOfStock || isBlocked}
                      step={step}
                      {...max}
                    />
                  )}
                </Row>
                {isBlocked && (
                  <DetailMessage>{blockedSkuMessage || translate('app.errors.skuTempUnavailable')}</DetailMessage>
                )}
                {formikContext.errors &&
                  formikContext.errors[parent] &&
                  formikContext.errors[parent][brand] &&
                  formikContext.errors[parent][brand][`p${id}`] && (
                    <ErrorMessage>{formikContext.errors[parent][brand][`p${id}`]}</ErrorMessage>
                  )}
              </Entry>
            )
          })}
      </Content>
    </Container>
  )
}

SAQAccordion.propTypes = {
  label: string,
  products: any,
  brand: string,
  order: object,
  children: node,
  isSubrow: bool,
  parentCollapsed: bool,
  parent: string,
  hasSubrows: bool,
  totalProducts: number,
  isRestrictedBannerInRestrictedPeriod: bool
}

export default SAQAccordion
