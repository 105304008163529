import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { matchRoutes, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import MediaContext from 'context/MediaContext'
import SectorContext, { SectorProvider } from 'context/SectorContext'

import MobileHeader from 'components/MobileHeader'
import SectorPicker from 'components/SectorPicker'
import MobilePicker from 'components/SectorPicker/MobilePicker'

import { PRODUCT_TYPE_ROUTES } from 'utils/constants'

const SectorRedirector = () => {
  const { sectorType, sectorId } = useParams()
  const { currentSector, selectedLevel } = useContext(SectorContext)
  const { isWideLayout } = useContext(MediaContext)
  const navigate = useNavigate()
  const location = useLocation()
  const containerRef = useRef()

  const showType = useMemo(() => PRODUCT_TYPE_ROUTES.some((r) => location.pathname.includes(r)))

  useEffect(() => {
    containerRef.current?.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    // When we redirect to a generic link, we add the sector type and id
    if (!sectorType && !sectorId && currentSector && selectedLevel) {
      navigate(`/${selectedLevel}/${currentSector[selectedLevel].id}${location.pathname}`)
    }
  }, [selectedLevel, currentSector, location.pathname])

  useEffect(() => {
    // When we change the sector in the URL and a sector was already selected, we update the sector type and id
    if (
      sectorType &&
      sectorId &&
      !isEmpty(currentSector) &&
      selectedLevel &&
      currentSector[selectedLevel] &&
      (sectorType !== selectedLevel || currentSector[selectedLevel].id !== sectorId)
    ) {
      const [routeParams] = matchRoutes([{ path: '/:sectorType/:sectorId/*' }], location)
      const route = location.pathname.replace(
        routeParams.pathnameBase,
        `/${selectedLevel}/${currentSector[selectedLevel].id}`
      )
      navigate(route)
    }
  }, [selectedLevel, currentSector])

  return (
    <div className="no-scrollbar relative flex w-full min-w-0 flex-col">
      {isWideLayout ? (
        <div className="max-md:hidden">
          <SectorPicker showType={showType} />
        </div>
      ) : (
        <MobileHeader>
          <MobilePicker />
        </MobileHeader>
      )}
      <div className="overflow-auto" ref={containerRef}>
        <Outlet />
      </div>
    </div>
  )
}

const SectorLayout = () => {
  return (
    <SectorProvider>
      <SectorRedirector>
        <Outlet />
      </SectorRedirector>
    </SectorProvider>
  )
}
export default SectorLayout
