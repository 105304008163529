import { normalize } from 'normalizr'
import { createAction } from 'redux-actions'

import { mergeEntities } from 'store/actions'
import { addCycleCampaignsToCustomer } from 'store/customers/actions'
import { setDataFetch } from 'store/dataFetches/actions'
import { addCycleCampaignsToDistrict } from 'store/districts/actions'
import { addCycleCampaignsToRegion } from 'store/regions/actions'
import { cycleCampaigns as cycleCampaignSchema } from 'store/schema'
import { addCycleCampaignsToTerritory } from 'store/territories/actions'

import { DATA_UPDATE_STATUS } from 'utils/constants'
import { validateStoreDataKey } from 'utils/helpers'

import { PLANNING_SELECTOR_ACTIONS } from '../actions'

import * as api from './endpoints'

export const addCycleCampaigns = createAction('Add Cycle Campaigns')

const CAMPAIGN_ACTIONS = {
  customer: addCycleCampaignsToCustomer,
  territory: addCycleCampaignsToTerritory,
  district: addCycleCampaignsToDistrict,
  region: addCycleCampaignsToRegion
}
export const fetchCycleCampaigns = createAction(
  'Fetch Cycle Campaigns',
  ({ sectorType, sectorId, dataKey }) =>
    (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const { data } = await api.fetchCycleCampaigns(sectorType, sectorId)

        const {
          entities: { cycleCampaigns }
        } = normalize(data.cycleCampaigns, [cycleCampaignSchema])
        if (!cycleCampaigns) return

        const campaignIds = Object.entries(cycleCampaigns)?.length ? Object.keys(cycleCampaigns).map((key) => +key) : []
        dispatch(CAMPAIGN_ACTIONS[sectorType]?.({ cycleCampaigns: campaignIds, id: sectorId }))
        dispatch(mergeEntities({ cycleCampaigns }))
      })
)

export const fetchCycleCampaignData = createAction(
  'Fetch Cycle Campaigns',
  (props, dataKey) => async (dispatch, getState) => {
    const { campaignId, sectorId, sectorType } = props

    validateStoreDataKey(getState(), dispatch, dataKey, async () => {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      const { data } = await api.fetchCycleCampaignData(campaignId, sectorId, sectorType)
      const addResultToScope = PLANNING_SELECTOR_ACTIONS[sectorType].addCycleCampaigns

      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )
    })
  }
)
