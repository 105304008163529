import React, { useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { ActionHeaderProvider } from 'context/ActionHeaderContext'
import LangContext from 'context/LangContext'

import BreadcrumbHeader from 'components/BreadcrumbHeader'
import CheckForValidScope from 'components/guards/CheckForValidScope'

import HEADER_CONTENT from './HeaderContent'

const CloseLayout = () => {
  useEffect(() => {
    document.title = 'Close'
  }, [])

  const { translate } = useContext(LangContext)

  return (
    <div className="grid w-full gap-5 p-5">
      <ActionHeaderProvider>
        <BreadcrumbHeader headerContent={HEADER_CONTENT} root=":sectorType/:sectorId/pace/close" />
        <div className="col-span-12">
          <CheckForValidScope pageName={translate('app.pace.close')}>
            <Outlet />
          </CheckForValidScope>
        </div>
      </ActionHeaderProvider>
    </div>
  )
}

export default CloseLayout
