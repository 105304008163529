import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import countBy from 'lodash/countBy'
import orderBy from 'lodash/orderBy'

import ActionHeaderContext from 'context/ActionHeaderContext'

import { getLatestComments } from 'store/auth/selectors'

import Container from 'components/Container'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import InboxMessage from 'components/InboxMessage'
import MessageStatusFilters from 'components/MessageStatusFilters'

import { green, greyLight, red, yellow } from 'styles/colors'

const sortOptions = [
  { value: 'latest', label: 'Latest' },
  { value: 'oldest', label: 'Oldest' }
]

const sorters = {
  latest: (comments) => orderBy(comments, ['createdAt'], ['desc']),
  oldest: (comments) => orderBy(comments, 'createdAt'),
  territory: (comments) => orderBy(comments, 'territory')
}
const Inbox = () => {
  const { addAction } = useContext(ActionHeaderContext)
  const comments = useSelector((state) => getLatestComments(state))
  const isVFUser = useSelector(({ auth }) =>
    ['telesalesRepresentative', 'customerService'].includes(auth.user.groupCode)
  )

  const statusColors = {
    complete: green,
    actionRequiredByVF: isVFUser ? red : greyLight,
    actionRequiredByTM: isVFUser ? greyLight : red,
    reserved: yellow
  }
  const [statusFilters, updateStatusFilters] = useState({
    complete: false,
    actionRequiredByVF: false,
    actionRequiredByTM: false,
    reserved: false
  })
  const [filteredComments, setFilteredComments] = useState(comments)

  const [sortOption, setSortOption] = useState('latest')

  useEffect(() => {
    addAction({
      // This is intentionally left empty. Removes the button in the Header when coming from customer scope/sector
      getCreateButton: () => {}
    })
  }, [])

  useEffect(() => {
    const filtered = Object.values(statusFilters).some(Boolean)
      ? comments.filter(({ status }) => statusFilters[status])
      : comments
    setFilteredComments(sorters[sortOption](filtered))
  }, [statusFilters, comments, sortOption])

  const statusCounts = countBy(comments, 'status')
  if (!comments?.length) return <EmptyState title="No comments" subtitle="There are no comments to view" />

  return (
    <Container>
      <Dropdown secondary onChange={(e) => setSortOption(e.target.value)} value={sortOption} options={sortOptions} />
      <MessageStatusFilters
        {...statusCounts}
        statusFilters={statusFilters}
        statusColors={statusColors}
        updateStatusFilters={updateStatusFilters}
      />
      {filteredComments?.length ? (
        <div>
          {filteredComments.map(({ status, ...c }) => (
            <InboxMessage key={c.id} {...c} status={{ status, color: statusColors[status] }} />
          ))}
        </div>
      ) : (
        <EmptyState title="No comments" subtitle="There are no comments to view" />
      )}
    </Container>
  )
}

export default Inbox
