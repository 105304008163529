import React, { useContext, useState } from 'react'
import { func, node, number } from 'prop-types'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'
import Icon from 'components/Icon'
import { Modal } from 'components/Modal'

const GeographyFilters = ({ filters, appliedFilters = 0, resetFilters }) => {
  const { translate } = useContext(LangContext)
  const [modalOpen, setModalOpen] = useState(false)

  const hasAppliedFilters = appliedFilters > 0

  return (
    <>
      <Modal
        title={translate('common.filters')}
        open={modalOpen}
        onOpenChange={setModalOpen}
        onHandeClose={() => setModalOpen(false)}
      >
        <div className="space-y-6">
          {appliedFilters !== 0 && (
            <div className="flex items-center justify-between rounded-md bg-brand-50 py-3 pl-4 pr-3 text-sm font-medium text-brand-900 ring-1 ring-brand-700/60">
              <p>{appliedFilters} selected</p>
              <Button tertiary onClick={resetFilters}>
                {translate('common.clearFilters')}
              </Button>
            </div>
          )}
          {filters.map((filter, i) => (
            <div key={i}>{filter}</div>
          ))}
        </div>
      </Modal>
      <button
        onClick={() => setModalOpen(true)}
        className={`${
          hasAppliedFilters ? 'font-medium text-brand-800' : 'text-slate-800'
        } relative flex h-9 items-center gap-2 rounded-md bg-white  px-4 shadow-md ring-1 ring-slate-900/10 transition hover:bg-slate-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500 active:bg-slate-500/5`}
      >
        {hasAppliedFilters ? (
          <div className="-ml-0.5 flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-brand-100 text-2xs text-brand">
            {appliedFilters}
          </div>
        ) : (
          <Icon className="-ml-0.5 flex" icon="filter-list" compact />
        )}
        <span className="flex items-center whitespace-nowrap">{translate('common.filters')}</span>
      </button>
    </>
  )
}

GeographyFilters.propTypes = { filters: node.isRequired, appliedFilters: number, resetFilters: func.isRequired }

export default GeographyFilters
