// Note: Style template code.

import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import config from 'config'
import { func, node, number, object, string } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchPlanningHealthCheck } from 'store/HealthCheck/actions'
import { planningHealthCheckData } from 'store/HealthCheck/selectors'

import IconButton from 'components/button/IconButton'
import Card from 'components/card'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import { DistroCardPlaceholder, InventoryCardPlaceholder } from 'components/Placeholders/HealthCheckCardPlaceholder'
import Spinner from 'components/Spinner'
import VariationIndicator from 'components/VariationIndicator'

import { DATAKEY_TYPES, DISTRO_VAPE_CHANNEL } from 'utils/constants'
import { createDataKey, getErrorMessage, parseNumberString } from 'utils/helpers'

const Header = tw.span`text-sm font-medium text-slate-500`

const Data = ({ header, children, variation, additional }) => {
  return (
    <div className="flex w-full flex-col items-center space-y-2">
      <Header>{header}</Header>
      <div className="flex flex-col items-center space-y-2">
        <span className="text-2xl font-medium text-slate-900 @xs/card:text-3xl">{children}</span>
        <div className="flex items-center space-x-0.5">
          <VariationIndicator variation={+variation} />
          <span className="text-3xs font-medium text-slate-500 @xs/card:text-2xs">
            {variation > 0 ? '+' : variation < 0 ? '-' : ''}
            {parseNumberString(Math.abs(variation))}
            {additional}
          </span>
        </div>
      </div>
    </div>
  )
}

Data.propTypes = {
  header: string,
  additional: string,
  children: node,
  variation: number
}

const DataSm = ({ header, children }) => (
  <div className="flex flex-col items-center space-y-2">
    <Header>{header}</Header>
    {children}
  </div>
)

DataSm.propTypes = {
  header: string,
  children: node
}

const Eyebrow = tw.hr`w-full h-px bg-slate-100`

const VerticalLine = tw.div`w-px h-20 bg-slate-200 mx-2`

const TopSection = tw.div`space-y-5`

const BottomSection = tw.div`flex w-full space-x-2  items-center`

const Title = tw.span`inline-flex items-center h-9 text-xl font-semibold text-slate-900`

const SpinnerContainer = tw.div`flex justify-center items-center w-full h-full`

const timeframe = 1
const vapeCategory = 'all'

const HealthCheckCard = ({ fetchPlanningHealthCheck, span, amplifyAction, oosInvAmplify }) => {
  const { translate } = useContext(LangContext)
  const { currentSector, selectedLevel } = useContext(SectorContext)

  const isMounted = useRef()

  const [error, setError] = useState()
  const [vapeChannel, setVapeChannel] = useState(DISTRO_VAPE_CHANNEL[0].value)

  const healthCheckData = useSelector((state) =>
    planningHealthCheckData(state, { timeframe, vapeCategory, vapeChannel })
  )

  const { currentProductType } = useContext(SectorContext)

  const dataKey = createDataKey(DATAKEY_TYPES.PLANNING_HEALTH_CHECK, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id,
    productType: currentProductType,
    timeframe,
    vapeChannel
  })

  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  useEffect(() => {
    if (!config.featureFlags.distroPace && !config.featureFlags.inventoryPace) return

    isMounted.current = true
    setError()

    if (currentSector[selectedLevel]?.id) {
      fetchPlanningHealthCheck(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          productType: currentProductType,
          vapeCategory,
          timeframe,
          vapeChannel
        },
        dataKey
      ).catch((error) => {
        if (isMounted.current) setError(getErrorMessage(error))
      })
    }

    return () => {
      isMounted.current = false
    }
  }, [currentSector, selectedLevel, currentProductType, vapeChannel])

  const cardProps = {
    title: translate('app.healthCheck'),
    span,
    amplifyAction: config.featureFlags.distroPace ? amplifyAction : null,
    amplifySize: 2
  }

  const renderContent = () => {
    if (error) {
      return <EmptyState title={'app.warn.errorOccured'} subtitle={error} />
    }

    if (isLoading) {
      return (
        <SpinnerContainer>
          <Spinner icon="spinner" />
        </SpinnerContainer>
      )
    }

    return (
      <>
        <TopSection>
          <Eyebrow />
          {config.featureFlags.distroPace ? (
            <DistroSection
              healthCheckData={healthCheckData}
              activeProductType={currentProductType}
              currentChannel={vapeChannel}
              setChannel={setVapeChannel}
            />
          ) : (
            <DistroCardPlaceholder />
          )}
          <Eyebrow />
        </TopSection>

        <div className={`flex items-center ${oosInvAmplify && 'justify-between'}`}>
          <Title>{translate('app.invCaptureOOS')}</Title>
          {oosInvAmplify && config.featureFlags.inventoryPace && (
            <div className="space-x-3">
              <IconButton secondary icon="three-stars" onClick={oosInvAmplify}>
                {translate('app.pace.amplify')}
              </IconButton>
            </div>
          )}
        </div>

        {config.featureFlags.inventoryPace ? (
          <InventorySection healthCheckData={healthCheckData} />
        ) : (
          <InventoryCardPlaceholder />
        )}
      </>
    )
  }

  return <Card {...cardProps}>{renderContent()}</Card>
}

HealthCheckCard.propTypes = {
  fetchPlanningHealthCheck: func,
  span: object,
  amplifyAction: func,
  oosInvAmplify: func
}

const DistroSection = ({ healthCheckData, activeProductType, currentChannel, setChannel }) => {
  const { translate } = useContext(LangContext)
  const { distro } = healthCheckData

  const isDropdownInteractive = activeProductType === 'vape'

  return (
    <div className="space-y-4">
      <Dropdown
        disabled={!isDropdownInteractive}
        onChange={(e) => setChannel(e.target.value)}
        value={isDropdownInteractive ? currentChannel : DISTRO_VAPE_CHANNEL[0].value}
        options={DISTRO_VAPE_CHANNEL}
      />

      <Data header={translate('app.wdistro')} variation={distro.variation || 0} additional="% vs PW">
        {distro.percent || 0}%
      </Data>

      <div className="flex items-center">
        <div className="w-full">
          <DataSm header={translate('app.stores')}>
            <span className="flex space-x-1 text-2xl font-medium  text-slate-900 @xs/card:text-3xl">
              {distro.stores || 0}%
            </span>
            <div className="flex items-center space-x-2">
              <VariationIndicator variation={distro.storesVariation} />
              <span className="text-3xs font-medium text-slate-500 @xs/card:text-2xs">
                {distro.storesVariation > 0 && '+'}
                {parseNumberString(distro.storesVariation)}
                {translate('app.percentPW')}
              </span>
            </div>
          </DataSm>
        </div>

        <VerticalLine />

        <div className="w-full">
          <DataSm header={translate('app.acronyms.SKUs')}>
            <span className="flex text-2xl font-medium text-slate-900 @xs/card:text-3xl">
              {healthCheckData.distro.skus || 0}%
            </span>
            <div className="flex items-center space-x-2">
              <VariationIndicator variation={distro.skusVariation} />
              <span className="text-3xs font-medium text-slate-500 @xs/card:text-2xs">
                {distro.skusVariation > 0 && '+'}
                {parseNumberString(distro.skusVariation)}
                {translate('app.percentPW')}
              </span>
            </div>
          </DataSm>
        </div>
      </div>
    </div>
  )
}

DistroSection.propTypes = {
  healthCheckData: object,
  activeProductType: string,
  currentChannel: string,
  setChannel: func
}

const InventorySection = ({ healthCheckData }) => {
  const { translate } = useContext(LangContext)

  return (
    <BottomSection>
      <div className="w-full">
        <Data
          header={translate('app.acronyms.OOS')}
          variation={healthCheckData.oos.variation}
          additional={translate('app.percentPW')}
        >
          {healthCheckData.oos.percent}%
        </Data>
      </div>
      <VerticalLine />
      <div className="w-full">
        <Data
          header={translate('app.invCapture')}
          variation={healthCheckData.inventoryCaptures.variation}
          additional={translate('app.percentPW')}
        >
          {healthCheckData.inventoryCaptures.percent}%
        </Data>
      </div>
    </BottomSection>
  )
}

InventorySection.propTypes = {
  healthCheckData: object
}

const mapActionCreators = {
  fetchPlanningHealthCheck
}

export default connect(null, mapActionCreators)(HealthCheckCard)
