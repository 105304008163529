import { normalize } from 'normalizr'
import { createAction } from 'redux-actions'

import { mergeEntities } from 'store/actions'
import { registerSync } from 'store/api'
import { setDataFetch } from 'store/dataFetches/actions'
import outbox from 'store/outbox'
import { territory as TerritorySchema, territoryFetchSchema } from 'store/schema'

import { DATA_UPDATE_STATUS } from 'utils/constants'
import { dataIsStillValid, ERRORS, validateStoreDataKey } from 'utils/helpers'

import * as api from './endpoints'

export const fetchTerritory = createAction(
  'Fetch territory data',
  (territoryId, dataKey) => async (dispatch, getState) => {
    if (!window.navigator.onLine || !territoryId) return
    const dataFetchesState = getState().dataFetches

    if (!dataIsStillValid(dataFetchesState, dataKey)) {
      try {
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
        const { data } = await api.fetchTerritory(territoryId)
        if (data.territory) {
          const { entities } = normalize(data, territoryFetchSchema)
          dispatch(mergeEntities(entities))
        }
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
      } catch (e) {
        console.error(e)
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      }
    }
  }
)

export const fetchTerritoryCustomerList = createAction(
  'Fetch territory customer list',
  (territoryId, loadingKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches

    if (dataIsStillValid(dataFetchesState, loadingKey)) return
    try {
      if (!window.navigator.onLine) throw ERRORS.offline
      dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.LOADING }))
      const { data } = await api.fetchTerritoryCustomerList(territoryId)
      if (!data.territory) return dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.OVER }))
      const { entities } = normalize(data, territoryFetchSchema)
      dispatch(mergeEntities(entities))
      dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.OVER }))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

export const fetchTerritoryPerformance = createAction(
  'Fetch territory performance',
  (territoryId, loadingKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches
    if (dataIsStillValid(dataFetchesState, loadingKey)) return
    try {
      if (!window.navigator.onLine || !territoryId) return
      dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.LOADING }))
      const { data } = await api.fetchTerritoryPerformance(territoryId)
      if (!data.territory) return dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.OVER }))
      const { entities } = normalize(data, territoryFetchSchema)
      dispatch(mergeEntities(entities))
      dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.OVER }))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

export const fetchTerritoryIntel = createAction(
  'Fetch territory intel',
  (territoryId, loadingKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches
    if (dataIsStillValid(dataFetchesState, loadingKey)) return
    try {
      dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline
      if (!territoryId) throw ERRORS.missingTerritoryId
      const { data } = await api.fetchTerritoryIntel(territoryId)
      if (!data.territory) return dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.OVER }))

      const { entities } = normalize(data, territoryFetchSchema)
      dispatch(mergeEntities(entities))
      dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.OVER }))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

const queueOfflineSequence = async ({ territoryId, sequence }) => {
  const perfReorderOutbox = (await outbox.getItem('perf-reorder')) || {}
  await outbox.setItem('perf-reorder', { ...perfReorderOutbox, [territoryId]: sequence })
  return registerSync('submit-pending-perf-reorder')
}

export const updatePerfSequence = createAction('update territory performance order')

export const submitPerformanceReorder = createAction(
  'Submit performance reorder',
  (territorySequence) => async (dispatch) => {
    // { territoryId: 'werwer', sequence: [] }
    let newSequence
    try {
      if (window.navigator.onLine) {
        const {
          data: { employeeTerritory }
        } = await api.submitTerritoryPerfReorder(territorySequence)
        newSequence = employeeTerritory
      } else {
        queueOfflineSequence(territorySequence)
        newSequence = territorySequence
      }
      dispatch(updatePerfSequence(newSequence))
    } catch (err) {
      if (err.message !== 'Network Error') {
        throw err
      }
      queueOfflineSequence(territorySequence)
      dispatch(updatePerfSequence(territorySequence))
    }
  }
)

export const fetchTerritoryGoFunds = createAction(
  'Refetch territory go funds',
  (territoryId, key) => async (dispatch) => {
    try {
      if (!window.navigator.onLine || !territoryId) return
      dispatch(setDataFetch({ dataKey: key, status: DATA_UPDATE_STATUS.LOADING }))
      const { data } = await api.fetchTerritoryGoFunds(territoryId)
      const { entities } = normalize(data.territory, TerritorySchema)
      dispatch(mergeEntities(entities))
      dispatch(setDataFetch({ dataKey: key, status: DATA_UPDATE_STATUS.OVER }))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey: key, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

export const clearQueuedSequences = createAction('Clear submitted sequences from store')

export const addIntelToTerritory = createAction('Add intel to territory')
export const removeIntelFromTerritory = createAction('Delete territory intel')
export const addSellinGeographyToTerritory = createAction('Add Sell-in Geography to territory')
export const addSellinRunRateToTerritory = createAction('Add Sell-in Run Rate to territory')
export const addSellinOrderCompletionToTerritory = createAction('Add Sell-in Order Completion to territory')
export const addDistroTrendedDataToTerritory = createAction('Add Distro Treneded Data to territory')
export const addDistroBrandDataToTerritory = createAction('Add Distro Brand Data to territory')
export const addDistroMaterialDataToTerritory = createAction('Add Distro Material Data to territory')
export const addDistroGeographyDataToTerritory = createAction('Add Distro Geography Data to territory')
export const addDistroSummaryDataToTerritory = createAction('Add Distro Summary Data to territory')
export const addPlanningSellinValuesToTerritory = createAction('Add Planning Sell-in Values to territory')
export const addPlanningSelloutValuesToTerritory = createAction('Add Planning Sell-out Values to territory')
export const addPlanningHealthCheckValuesToTerritory = createAction('Add Planning Health Check Values to territory')
export const addPlanningPriceCheckValuesToTerritory = createAction('Add Planning Price Check Values to territory')
export const addPlanningEngagementValuesToTerritory = createAction('Add Planning Engagement Values to territory')
export const addPlanningCycleCampaignValuesToTerritory = createAction('Add Cycle Campaign Values to territory')
export const addTerritoryProjectLinks = createAction('Add project links to territory')

export const addAmplifySelloutValuesToTerritory = createAction('Add Amplify Sell-out Values to territory')
export const addAmplifySelloutGeographyValuesToTerritory = createAction(
  'Add Amplify Sell-out Geography Values to territory'
)
export const addAmplifyInventoryOosTrendingValuesToTerritory = createAction(
  'Add Amplify Inventory OOS Trending Values to territory'
)
export const addAmplifyInventoryOosBrandsValuesToTerritory = createAction(
  'Add Amplify Inventory OOS Brand Values to territory'
)
export const addAmplifyInventoryOosMaterialValuesToTerritory = createAction(
  'Add Amplify Inventory OOS Material Values to territory'
)
export const addAmplifyInventoryOosGeographyValuesToTerritory = createAction(
  'Add Amplify Inventory OOS Geography Values to territory'
)
export const addAmplifyInventoryOosFiltersValuesToTerritory = createAction(
  'Add Amplify Inventory OOS Filters Values to territory'
)

export const addAmplifyExtraHubTrendingValuesToTerritory = createAction(
  'Add Amplify Extra Hub Trending Values to Territory'
)
export const addAmplifyExtraHubEngagementValuesToTerritory = createAction(
  'Add Amplify Extra Hub Engagement Values to Territory'
)
export const addAmplifyExtraHubGeographyValuesToTerritory = createAction(
  'Add Amplify Extra Hub Geography Values to Territory'
)
export const addAmplifyExtraHubFiltersValuesToTerritory = createAction(
  'Add Amplify Extra Hub Filters Values to Territory'
)
export const addAmplifyExtraHubActivityValuesToTerritory = createAction(
  'Add Amplify Extra Hub Activity Values to Territory'
)

export const addAmplifyPriceCaptureComplianceFootprintValuesToTerritory = createAction(
  'Add Amplify Price Capture Compliance Footprint Values to Territory'
)
export const addAmplifyPriceCaptureComplianceBrandCompletionValuesToTerritory = createAction(
  'Add Amplify Price Capture Compliance Brand Completion Values to Territory'
)
export const addAmplifyPriceCaptureComplianceFiltersValuesToTerritory = createAction(
  'Add Amplify Price Capture Compliance Filters Values to Territory'
)
export const addAmplifyPriceCaptureComplianceGeographyValuesToTerritory = createAction(
  'Add Amplify Price Capture Compliance Geography Values to Territory'
)
export const addAmplifyPriceComplianceStrategyComplianceValuesToTerritory = createAction(
  'Add Amplify Price Compliance Strategy Compliance Values to Territory'
)
export const addAmplifyPriceComplianceEdlpComplianceValuesToTerritory = createAction(
  'Add Amplify Price Compliance Edlp Compliance Values to Territory'
)
export const addAmplifyPriceComplianceGeographyComplianceValuesToTerritory = createAction(
  'Add Amplify Price Compliance Geography Compliance Values to Territory'
)
export const addAmplifyPriceComplianceGeographyFiltersValuesToTerritory = createAction(
  'Add Amplify Price Compliance Geography Filters Values to Territory'
)

export const fetchTerritorySellInPrograms = createAction(
  'Fetch Sell-In Programs',
  (territoryId, loadingKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches
    if (dataIsStillValid(dataFetchesState, loadingKey)) return
    try {
      if (!window.navigator.onLine) throw ERRORS.offline
      if (!territoryId) throw ERRORS.missingTerritoryId
      dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.LOADING }))
      const { data } = await api.fetchTerritorySellInPrograms(territoryId)
      const { entities } = normalize(data, territoryFetchSchema)
      dispatch(mergeEntities(entities))
      dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.OVER }))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

export const fetchTerritoryProjectLinks = createAction(
  'Fetch Territory Project Links',
  (territoryId, key) => async (dispatch, getState) => {
    validateStoreDataKey(getState(), dispatch, key, async () => {
      if (!territoryId) throw ERRORS.missingTerritoryId
      const { data } = await api.getTerritoryProjects(territoryId)
      const { projectLinks, customers } = data.territory
      dispatch(addTerritoryProjectLinks({ territoryId, projectLinks, customers }))
    })
  }
)

export const addCycleCampaignsToTerritory = createAction('Add Cycle Campaign to Territory')
