import React, { useContext, useEffect, useRef } from 'react'
import config from 'config'
import { bool } from 'prop-types'

import GAnalyticsContext from 'context/GAnalyticsContext'
import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import SegmentControl from 'components/SegmentControl'

import { PRODUCT_TYPE_OPTIONS } from 'utils/constants'

const ProductTypePicker = ({ showLabel = true }) => {
  const { translate } = useContext(LangContext)
  const { currentProductType, setProductType } = useContext(SectorContext) || {}
  const { startTracking, stopTracking, addUsageEvent } = useContext(GAnalyticsContext)

  const mounted = useRef()

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    const currentKey = createGAKey(currentProductType)
    startTracking(currentKey)
    return () => {
      stopTracking(currentKey)
    }
  }, [currentProductType])

  const filteredOptions = config.featureFlags.nrtPace
    ? PRODUCT_TYPE_OPTIONS
    : PRODUCT_TYPE_OPTIONS.filter((o) => o.value !== 'nrt')

  return (
    <SegmentControl
      label={showLabel ? translate('common.productType') : ''}
      name="product-type"
      onChange={(e) => {
        const newValue = e.target.value
        setProductType(newValue)
        addUsageEvent(`${newValue}_click`)
      }}
      value={currentProductType}
      options={filteredOptions}
    />
  )
}

ProductTypePicker.propTypes = {
  showLabel: bool
}

export default ProductTypePicker

function createGAKey(currentValue) {
  return `${currentValue}_view_s`
}
