import React from 'react'
import snakeCase from 'lodash/snakeCase'
import { array, bool, func, object, string } from 'prop-types'
import tw, { styled } from 'twin.macro'

import Icon from 'components/Icon'

const TD = tw.td`px-3 py-3 first:pl-0 last:pr-0 group-last:pb-0 text-sm font-medium text-slate-900`

const ValueWrapper = styled.div(({ alignCenter }) => [tw`flex items-center`, alignCenter && tw` justify-center`])

const TableRow = ({ rowData, alignCenter, columns }) => {
  const rowHeaderCol = rowData[columns[0].field]
  return (
    <tr className="group">
      {columns.map(({ field }, idx) => {
        const value = rowData[field]
        const key = `${field}_${snakeCase(rowHeaderCol)}_${idx}`
        return (
          <TD key={key}>
            <ValueWrapper alignCenter={alignCenter}>{value}</ValueWrapper>
          </TD>
        )
      })}
    </tr>
  )
}

TableRow.propTypes = {
  rowData: object,
  alignCenter: bool,
  columns: array.isRequired
}

const Table = styled.table(({ fillContainer, fullWidth }) => [
  tw`relative table-auto border-separate border-spacing-0`,
  fillContainer && tw`h-full w-full`,
  fullWidth && !fillContainer && tw`w-full`
])

const TableHeader = styled.th(({ alignCenter, isClickable, isLarge }) => [
  tw`sticky bg-white z-10 top-0 pb-3 px-3 first:pl-0 last:pr-0 text-base font-medium text-slate-500 border-b border-slate-300`,
  alignCenter ? tw`text-center` : tw`text-left`,
  isClickable ? tw`cursor-pointer` : tw`cursor-default`,
  isLarge ? tw` min-w-52` : tw``
])

const HeaderContent = styled.div(() => tw`flex items-center`)

const Chevron = styled(({ direction, ...rest }) => Icon(rest))`
  flex-shrink: 0;
  transform: ${(props) => (props.direction === 'asc' ? 'rotate(180deg)' : 'none')};

  @media print {
    display: none;
  }
`

const DataTable = ({
  columns,
  rows,
  alignCenter,
  fillContainer,
  fullWidth,
  onColumnClick,
  activeColumn,
  unClickableColumns
}) => {
  return (
    <div className="flow-root h-full">
      <Table fillContainer={fillContainer} fullWidth={fullWidth}>
        <thead>
          <tr>
            {columns.map((column) => {
              const isClickable = unClickableColumns.length && !unClickableColumns.includes(column.field)
              const handleClick = isClickable ? onColumnClick : () => null
              return (
                <TableHeader
                  key={column.field}
                  scope="col"
                  alignCenter={alignCenter}
                  isLarge={column.isLarge}
                  isClickable={isClickable}
                  onClick={() => handleClick(column.field)}
                >
                  <HeaderContent>
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <span>{column.headerName}</span>
                        <span>{column.additionnal}</span>
                      </div>

                      <p className="text-sm font-normal">{column.subHeader}</p>
                    </div>
                    {activeColumn?.column === column.field ? (
                      <div className="shrink-0">
                        <Chevron icon="down-chevron" direction={activeColumn?.order} />
                      </div>
                    ) : null}
                  </HeaderContent>
                </TableHeader>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <TableRow key={i} rowData={row} alignCenter={alignCenter} columns={columns} />
          ))}
        </tbody>
      </Table>
    </div>
  )
}

DataTable.propTypes = {
  columns: array,
  rows: array,
  alignCenter: bool,
  fillContainer: bool,
  fullWidth: bool,
  onColumnClick: func,
  activeColumn: string,
  unClickableColumns: array
}
DataTable.defaultProps = {
  onColumnClick: () => null,
  unClickableColumns: []
}

export default DataTable
