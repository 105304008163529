import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { stripUnit } from 'polished'
import { bool, node, number, object, oneOfType, string } from 'prop-types'
import styled, { css } from 'styled-components'

import Button from 'components/button/Button'
import Icon from 'components/Icon'
import SmallCaps from 'components/SmallCaps'

import {
  GO_FUND_PAYMENT_TYPES,
  MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_IN,
  MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_OUT,
  POWER_HOUR
} from 'utils/constants'

import { borderColor, goBlue, grey, greyLight, red, secondaryColor, white, yellow } from 'styles/colors'
import { animationCurve, animationTime, borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { H3, smallFontSize, tinyFontSize } from 'styles/typography'

const Actions = styled.div`
  position: absolute;
  z-index: 10;
  top: ${spacing.medium};
  right: ${spacing.medium};
`

const ActionLink = styled(Link)`
  color: ${greyLight};
  transition: color ${animationTime} ${animationCurve};
  margin-left: ${spacing.small}

  &:hover,
  &:focus {
    color: ${grey}
  }
`

const Container = styled.div`
  position: relative;
  display: block;
  background-color: ${white};
  border-bottom: 1px solid ${borderColor};
  padding: ${spacing.medium};

  ${media.breakpoint`
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};

    &:first-child {
      border-top-left-radius: ${borderRadius};
      border-top-right-radius: ${borderRadius};
    }

    &:last-child {
      border-bottom-left-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    }
  `};

  &:first-child {
    border-top: 1px solid ${borderColor};
  }
`

const Name = styled(H3)`
  margin-bottom: ${spacing.tiny};
  padding-right: ${spacing.xxxLarge};
`

const Focus = styled.small`
  display: block;
  color: ${greyLight};
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
`

const ProgramDate = styled.div`
  display: flex;
  align-items: center;
  font-size: ${smallFontSize};
`

const Calendar = styled(Icon)`
  fill: ${secondaryColor};
  flex-shrink: 0;
  margin-right: ${spacing.small};
`

const PaymentDueWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
  font-size: ${smallFontSize};
`

const Warning = styled(Icon)`
  flex-shrink: 0;
  margin-right: ${spacing.small};
`

const RequestExceptionButton = styled(Button)`
  border: 1px solid ${goBlue};
  color: ${goBlue};
`

const PayNowButton = styled(Button)`
  color: ${white};
  background-color: ${goBlue};
  margin-right: 5px;
  margin-bottom: 5px;
`

const TrackCartonSalesButton = styled(Button)`
  color: ${white};
  background-color: ${goBlue};
  margin-right: 5px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};

  &:last-child {
    margin-bottom: 0;
  }
`

// const LastOrder = styled.small`
//   display: block;
//   font-size: ${tinyFontSize};
//   color: ${greyLight};
// `
const GoStatWrap = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${tinyFontSize};
  ${(props) =>
    props.color &&
    css`
      color: ${red};
    `};
  align-items: center;
`

export const GoStat = ({ label, value, color }) => {
  if (!label) return <div />
  return (
    <GoStatWrap color={color}>
      <b>{label}</b>
      <span>{value}</span>
    </GoStatWrap>
  )
}

GoStat.propTypes = {
  label: string,
  value: oneOfType([number, string]),
  color: string
}

export const ShareGrowthProgramStats = ({
  program: { programCardInfos, posCtnVolume },
  payout,
  isCompleted,
  children
}) => {
  const { targetShare, startingShare, l4Share, performance, perfColor } = programCardInfos

  return (
    <>
      <Row>
        <GoStat label="Target share" value={`${targetShare || '-'}%`} />
        <GoStat label="Starting share" value={`${startingShare || '-'}%`} />
        <GoStat label="L4 Share" value={`${l4Share || '-'}%`} />
        {posCtnVolume && <GoStat label="Performance" value={`${performance}%`} color={perfColor} />}
      </Row>
      {!isCompleted && (
        <Row>
          <GoStat label="Actuals sales to date" value={`${posCtnVolume || 0} ctns`} />
          <GoStat label="Pot. payout to date" value={payout || '-'} />
          {children || <GoStat />}
        </Row>
      )}
    </>
  )
}

ShareGrowthProgramStats.propTypes = {
  program: object.isRequired,
  payout: string,
  isCompleted: bool,
  children: node
}

export const TrackedProgramStats = ({ program, payout, isCompleted, children }) => {
  const { perCartonCost, programCardInfos } = program
  const { target, actualCtns, performance } = programCardInfos

  return (
    <>
      <Row>
        <GoStat label="Target volume" value={target || '-'} />
        <GoStat label="Per carton" value={`$${perCartonCost}`} />
        <GoStat label="Performance" value={`${performance || '-'}%`} />
      </Row>
      {!isCompleted && (
        <Row>
          <GoStat label="Actuals til date" value={`${actualCtns || 0} ctns`} />
          <GoStat label="Pot. payout to date" value={payout || '-'} />
          {children || <GoStat />}
        </Row>
      )}
    </>
  )
}

TrackedProgramStats.propTypes = {
  program: object.isRequired,
  payout: string,
  isCompleted: bool,
  children: node
}

const PaymentDue = ({ daysTilPayDue, finalCost, adjustmentSubmittedAt }) => {
  if (adjustmentSubmittedAt)
    return (
      <PaymentDueWrap>
        <Warning icon="warning" small />
        <span>
          Payment adjustment ${finalCost} submitted {moment(adjustmentSubmittedAt).format('MMMM D')}
        </span>
      </PaymentDueWrap>
    )
  if (daysTilPayDue === 0)
    return (
      <PaymentDueWrap style={{ color: red }}>
        <Warning icon="warning" small />
        <span>Last day to submit an exception</span>
      </PaymentDueWrap>
    )
  return (
    <PaymentDueWrap style={{ color: yellow }}>
      <Warning icon="warning" small />
      <span>
        {daysTilPayDue} day{daysTilPayDue === 1 ? '' : 's'} left to submit an exception
      </span>
    </PaymentDueWrap>
  )
}

PaymentDue.propTypes = {
  daysTilPayDue: number,
  finalCost: number,
  adjustmentSubmittedAt: string
}

const ExceptionButton = ({ programsPath, programId }) => {
  return (
    <RequestExceptionButton small to={`${programsPath}/${programId}/exception`} offlineDisabled>
      Request exception
    </RequestExceptionButton>
  )
}

ExceptionButton.propTypes = {
  programsPath: string,
  programId: string
}

const ActionButtons = ({ programsPath, programId, program, goFund }) => {
  const { activeTarget, actionButtons } = program.programCardInfos
  return (
    <>
      {actionButtons.canPayGoFund && (
        <PayNowButton small to={`${programsPath}/${programId}/payment`} offlineDisabled>
          Pay now
        </PayNowButton>
      )}
      {actionButtons.canUpdateCartonSales && (
        <TrackCartonSalesButton small to={`${programsPath}/${programId}/carton`} offlineDisabled>
          Update carton sales
        </TrackCartonSalesButton>
      )}

      {goFund.activity === POWER_HOUR ? (
        actionButtons.canAddCartonsTarget ? (
          <PayNowButton small to={`${programsPath}/${programId}/target`} offlineDisabled>
            Add cartons target
          </PayNowButton>
        ) : activeTarget ? (
          <PayNowButton small to={`${programsPath}/${programId}/target/${activeTarget.id}`} offlineDisabled>
            Update cartons target
          </PayNowButton>
        ) : null
      ) : null}

      {actionButtons.canRequestException && (
        <RequestExceptionButton small to={`${programsPath}/${programId}/exception`} offlineDisabled>
          Request exception
        </RequestExceptionButton>
      )}
    </>
  )
}

ActionButtons.propTypes = {
  programsPath: string,
  programId: string,
  program: object,
  goFund: object
}

export const ProgramStartEnd = ({ small, startDate, endDate }) => (
  <ProgramDate>
    <Calendar icon="calendar" small={small} />
    <span>{`${moment.utc(startDate).format('MMM D, YYYY')} - ${moment.utc(endDate).format('MMM D, YYYY')}`}</span>
  </ProgramDate>
)

ProgramStartEnd.propTypes = {
  small: bool,
  startDate: string.isRequired,
  endDate: string
}

const GoFundProgramCard = ({ programsPath, program }) => {
  const {
    brandFocus,
    variantFocus,
    description,
    id,
    goFundId,
    goFundBudget,
    allocation,
    goFund,
    maxPayout,
    perCartonCost,
    targetVolume,
    ctnsBooked,
    programCardInfos
  } = program

  const {
    activeTarget,
    cartonsPayoutAvailable,
    programBudgetRemaining,
    remainingBudget,
    remainingCartons,
    totalPayments,
    isCartonBasedActivity
  } = programCardInfos

  const programId = `${id}_${goFundId}`

  const renderActivityRow = (goFundActivity) => {
    if (
      ![MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_OUT, MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_IN].includes(
        goFundActivity
      )
    )
      return null
    return (
      <Row>
        <GoStat label="Target ctns" value={targetVolume} />
        {goFundActivity === MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_OUT && (
          <GoStat label="$ per ctn" value={`$${perCartonCost}` || '-'} />
        )}

        <GoStat label="Ctns sold" value={ctnsBooked || 0} />
        <GoStat label="Remaining ctns" value={remainingCartons} />
      </Row>
    )
  }

  return (
    <Container>
      <Name>{goFundBudget}</Name>
      <ProgramStartEnd small startDate={goFund.startDate} endDate={goFund.endDate} />
      <SmallCaps>
        {variantFocus || brandFocus} • {(goFund && goFund.paymentType) || 'offline'} payment
      </SmallCaps>

      <Focus>
        {goFund && <b>{goFund.activity}</b>}
        <p>{description}</p>
      </Focus>
      <SmallCaps>Program details</SmallCaps>
      <Row>
        <GoStat label="Program budget" value={`$${allocation.allocatedBudget}` || '-'} />
        <GoStat label="Budget rewarded" value={`$${allocation.spent || 0}` || '-'} />
        <GoStat label="Program balance" value={`$${programBudgetRemaining}` || '-'} />
      </Row>
      <SmallCaps>Store details</SmallCaps>
      <Row>
        <GoStat label="Max payout" value={`$${maxPayout}` || '-'} />
        <GoStat label="Store Rewarded" value={`$${totalPayments}` || '-'} />
        <GoStat label="Store balance budget" value={`$${remainingBudget}` || '-'} />
        {isCartonBasedActivity && <GoStat label="Cartons payout" value={`$${cartonsPayoutAvailable}` || '-'} />}
      </Row>
      {program.activity === POWER_HOUR && activeTarget && (
        <>
          <SmallCaps>Active target</SmallCaps>
          <Row>
            <GoStat label="Target amount" value={`$${activeTarget.cartonTargetAmount}` || '-'} />
            <GoStat label="Per carton amount" value={`$${perCartonCost}` || '-'} />
            <GoStat label="Total amount" value={`$${activeTarget.cartonTargetAmount * perCartonCost}` || '-'} />
          </Row>
        </>
      )}
      {goFund && renderActivityRow(goFund.activity)}

      {programsPath && (
        <Actions>
          <ActionLink to={`${programsPath}/${programId}`}>
            <Icon icon="more" />
          </ActionLink>
        </Actions>
      )}
      {goFund.paymentType === GO_FUND_PAYMENT_TYPES.instant && programsPath && (
        <ActionButtons programsPath={programsPath} programId={programId} program={program} goFund={goFund} />
      )}
    </Container>
  )
}

GoFundProgramCard.propTypes = {
  program: object.isRequired,
  programsPath: string
}

export default GoFundProgramCard
