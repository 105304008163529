import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Scroll from 'react-scroll'
import { func } from 'prop-types'

import ActionHeaderContext from 'context/ActionHeaderContext'

import * as authSelectors from 'store/auth/selectors'
import { fetchCustomerProjectLinks } from 'store/customers/actions'
import { customerProjectLinks } from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'

import ProjectAccordion from 'components/accordion/ProjectAccordion'
import Card from 'components/card'
import CollapseText from 'components/CollapseText'
import Container from 'components/Container'
import ProjectForm from 'components/CustomerProject/ProjectForm'
import ProjectStatusHeader from 'components/CustomerProject/ProjectStatusHeader'
import EmptyState from 'components/EmptyState'
import Spinner from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import { combinedNavHeight } from 'styles/global'

const CustomerProjects = ({ fetchCustomerProjectLinks }) => {
  const { projectId, sectorId } = useParams()
  const [error, setError] = useState()
  const navigate = useNavigate()

  const projects = useSelector((state) => customerProjectLinks(state, { customerId: sectorId }))
  const preferredLanguage = useSelector(authSelectors.preferredLanguage).substring(0, 2)

  const { addAction } = useContext(ActionHeaderContext)

  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER_PROJECT_LINKS, {
    customerId: sectorId
  })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    setError()

    if (sectorId) {
      fetchCustomerProjectLinks(sectorId, dataKey).catch((error) => {
        if (mounted) setError(getErrorMessage(error))
      })
    }

    return () => {
      mounted.current = false
    }
  }, [sectorId])

  useEffect(() => {
    if (projectId) {
      const activeProject = projects.find((pl) => pl.project?.id === +projectId)

      if (activeProject) {
        addAction({
          getProjectName: () => activeProject.project?.title?.[preferredLanguage] || 'Project'
        })
      } else {
        if (projects.length) {
          navigate(`/customer/${sectorId}/pace/close/insight-to-action`)
        }
      }
    }
  }, [projects, projectId])

  useEffect(() => {
    if (projectId) {
      Scroll.scroller.scrollTo(`project-${projectId}`, {
        duration: 400,
        smooth: true,
        offset: -combinedNavHeight,
        isDynamic: true
      })
    }
  }, [])

  if (!projects?.length) {
    return (
      <Container>
        <EmptyState title="Nothing yet" subtitle="There are no insights this cycle." />
      </Container>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <Spinner icon="spinner" />
    }

    if (error) {
      return <EmptyState title="An error occured" subtitle={error} />
    }

    if (!projects || !projects.length) {
      return <EmptyState title="No data available" subtitle="There are no insights this cycle." />
    }

    return (
      <>
        {projects.map((link) => {
          const { project } = link
          if (!project) return null

          return (
            <ProjectAccordion
              title={project.title?.[preferredLanguage] || 'No title provided'}
              details={
                <ProjectStatusHeader
                  status={link.status}
                  creation={project.creation}
                  expiry={project.expiry}
                  firstActionedAt={link.actionLinks.map(({ updatedAt }) => updatedAt).sort()[0]}
                />
              }
              project={project}
              expanded={project.id === +projectId}
              key={project.id}
            >
              {project.text && (
                <p>
                  <CollapseText text={project.text[preferredLanguage]} />
                </p>
              )}
              <ProjectForm project={project} customerProject={link} preferredLanguage={preferredLanguage} />
            </ProjectAccordion>
          )
        })}
      </>
    )
  }

  return (
    <Card title="Projects" span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
      {renderContent()}
    </Card>
  )
}

CustomerProjects.propTypes = {
  fetchCustomerProjectLinks: func
}

const mapActionCreators = {
  fetchCustomerProjectLinks
}

export default connect(null, mapActionCreators)(CustomerProjects)
