import React from 'react'
import { node, number, oneOfType, string } from 'prop-types'
import styled from 'styled-components'

import { green, greyLight, offWhite, red, yellow } from 'styles/colors'
import * as spacing from 'styles/spacing'

const statusOptions = {
  needsApproval: {
    color: greyLight,
    label: 'Needs approval'
  },
  awaitingManagerApproval: {
    color: greyLight,
    label: 'Awaiting manager approval'
  },
  awaitingRetailerApproval: {
    color: greyLight,
    label: 'Awaiting retailer approval'
  },
  new: {
    color: greyLight,
    label: 'Pending'
  },
  pending: {
    color: greyLight,
    label: 'Submitted'
  },
  processing: {
    color: yellow,
    label: 'Received'
  },
  addedToOrder: {
    color: green,
    label: 'Complete'
  },
  cancelled: {
    color: red,
    label: 'Cancelled'
  },
  rejectedByManager: {
    color: red,
    label: 'Rejected by manager'
  },
  rejectedByRetailer: {
    color: red,
    label: 'Rejected by retailer'
  },
  failure: {
    color: red,
    label: 'Failed'
  },
  created: {
    color: greyLight,
    label: 'Submitted'
  },
  extracted: {
    color: yellow,
    label: 'Awaiting approval'
  },
  approved: {
    color: green,
    label: 'Approved'
  },
  rejected: {
    color: red,
    label: 'Rejected'
  },
  modified: {
    color: green,
    label: 'Modified'
  },
  invalid: {
    color: red
  },
  notCompetitive: {
    color: red
  },
  overCeiling: {
    color: red
  },
  expired: {
    color: red
  },
  valid: {
    color: green
  },
  updated: {
    color: green
  },
  incomplete: {
    color: red
  },
  partiallyComplete: {
    color: yellow
  },
  complete: {
    color: green
  },
  noStatus: {
    color: offWhite
  },
  notItCan: {
    color: offWhite
  },
  notStarted: {
    color: greyLight
  },
  inProgress: {
    color: yellow
  },
  clarificationNeeded: {
    color: red
  },
  unresolved: {
    color: red
  },
  outdated: {
    color: red
  },
  resolved: {
    color: green
  },
  pendingFollowUp: {
    color: greyLight
  },
  notRequired: {
    color: greyLight
  }
}

const colors = {
  none: offWhite,
  grey: greyLight,
  yellow,
  red,
  green
}
const Container = styled.div`
  display: inline-flex;
  align-items: center;

  &:before {
    content: '';
    display: block;
    width: ${spacing.small};
    height: ${spacing.small};
    margin-right: ${spacing.small};
    border-radius: 50%;
    background-color: ${(props) =>
      colors[props.color] || props.color || (statusOptions[props.status] || {}).color || offWhite};
  }
`

const Status = ({ status, color, label, children }) => {
  if (!status && !color) return null
  return (
    <Container status={status} color={color}>
      {label || children || (statusOptions[status] || {}).label}
    </Container>
  )
}

Status.propTypes = {
  status: string,
  label: oneOfType([number, string]),
  children: node,
  color: string
}

export default Status
