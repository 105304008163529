import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { capitalize } from 'lodash'
import sortBy from 'lodash/sortBy'
import { func, object } from 'prop-types'

import LangContext from 'context/LangContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchFilters, fetchGeography } from 'store/extraHub/actions'
import { getCurrentFilters, getCurrentGeographies } from 'store/extraHub/selectors'

import Card from 'components/card'
import DataCombined from 'components/DataTable/DataCombined'
import DataTable from 'components/DataTable/DataTable'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { formatPercent } from 'utils/formatters'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import GeographyToggle from '../GeographyToggle'

const createOptions = (values) => {
  return sortBy(
    values.map((value) => ({ label: value.name, value: value.id })),
    'label'
  )
}

const GeographyTable = ({ span, fetchGeography, fetchFilters }) => {
  const { translate } = useContext(LangContext)

  const [geography, setGeography] = useState('region')
  const [error, setError] = useState()
  const [rows, setRows] = useState([])
  const [filter, setFilter] = useState('all')
  const { sectorType, sectorId } = useParams()

  const COLS = [
    {
      field: 'name',
      headerName: translate('common.name')
    },
    {
      field: 'erps',
      headerName: translate('app.geo.store')
    },
    {
      field: 'storeCompletion',
      headerName: translate('app.storeCompletionRate')
    },
    {
      field: 'users',
      headerName: translate('app.userCompleted')
    },
    {
      field: 'userCompletion',
      headerName: translate('app.userCompletionRate')
    },
    {
      field: 'activeCustomers',
      headerName: translate('app.userEngaged')
    }
  ]

  const filterDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.EXTRA_HUB.FILTERS, { sectorType, sectorId })
  const geographyDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.EXTRA_HUB.GEOGRAPHY, {
    sectorType,
    sectorId,
    geography,
    activity: filter
  })

  const filters = useSelector((state) => getCurrentFilters(state))
  const data = useSelector((state) => getCurrentGeographies(state) || [])
  const isFiltersLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: filterDataKey }))
  const isGeographyLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: geographyDataKey }))

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  // Filter
  useEffect(() => {
    fetchFilters({ sectorType, sectorId, dataKey: filterDataKey })
      .then(() => isMounted.current && setError())
      .catch((error) => isMounted.current && setError(error))
  }, [sectorType, sectorId])

  // Table Data
  useEffect(() => {
    fetchGeography({ sectorType, sectorId, geography, dataKey: geographyDataKey, activity: filter })
      .then(() => isMounted.current && setError())
      .catch((error) => isMounted.current && setError(error))
  }, [sectorType, sectorId, geography, filter])

  // Format Table Data
  useEffect(() => {
    console.log(data?.[`${geography}-${filter}`])
    if (isFiltersLoading || isGeographyLoading) return
    if (!data?.[`${geography}-${filter}`]) return

    const newRows = data?.[`${geography}-${filter}`]?.map((row) => ({
      name: row.name,
      erps: (
        <DataCombined
          primary={row.totalCompletedStores.toLocaleString()}
          secondary={`of ${row.totalStores.toLocaleString()}`}
        />
      ),
      storeCompletion: formatPercent(row.storeCompletion),
      users: (
        <DataCombined
          primary={row.totalCompletedUsers.toLocaleString()}
          secondary={`of ${row.totalUsers.toLocaleString()}`}
        />
      ),
      userCompletion: formatPercent(row.userCompletion),
      activeCustomers: row.activeCustomers.toLocaleString()
    }))

    setRows(newRows)
  }, [data, geography, filter, isFiltersLoading, isGeographyLoading])

  const getContent = () => {
    if (isGeographyLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={getErrorMessage(error)} />

    if (!rows || !rows.length) return <EmptyState title="Nothing to display" />

    return <DataTable columns={COLS} rows={rows} fillContainer />
  }

  if (isFiltersLoading) {
    return (
      <Card title="Region Performance" span={span}>
        <WrappedSpinner icon="spinner" />
      </Card>
    )
  }

  const activityOptions = [{ label: 'All Campaigns', value: 'all' }].concat(createOptions(filters || []))

  COLS[0].headerName = capitalize(geography)

  return (
    <Card
      title={`${capitalize(geography)} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <Dropdown
          key="activity-options"
          options={activityOptions}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />,
        <GeographyToggle
          key="geography-toggle"
          geography={geography}
          setGeography={setGeography}
          includeActivity
          includeChannel
          includeOwnership
        />
      ]}
      hideOnSmallScreens={true}
    >
      {getContent()}
    </Card>
  )
}

GeographyTable.propTypes = {
  span: object,
  fetchGeography: func,
  fetchFilters: func
}

export default connect(null, {
  fetchGeography,
  fetchFilters
})(GeographyTable)
