import React, { useContext, useEffect } from 'react'
import PriceCheckCard from 'views/plan/PriceCheckCard'

import MediaContext from 'context/MediaContext'

import BrandCompletion from './BrandCompletion'
import GeographyTable from './GeographyTable'
import PriceCaptureHeader from './PriceCaptureHeader'
import StoreFootprint from './StoreFootprint'

const AmplifyPriceCaptureLayout = () => {
  const { isWideLayout } = useContext(MediaContext)

  useEffect(() => {
    document.title = 'Amplify - Price Capture'
  }, [])

  return (
    <div className="grid w-full grid-cols-12 gap-5 p-5">
      <PriceCaptureHeader parentHeader="Amplify" header="Price Capture" />
      <PriceCheckCard span={{ xs: 12, sm: 12, md: 12, lg: 3, xl: 4 }} displayAmplify={false} />
      <BrandCompletion span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }} />
      <StoreFootprint span={{ xs: 12, sm: 12, md: 12, lg: 5, xl: 4 }} />
      {isWideLayout && <GeographyTable span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} />}
    </div>
  )
}

export default AmplifyPriceCaptureLayout
