import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { currentVersion } from 'appVersion'
import isEqual from 'lodash/isEqual'
import { func } from 'prop-types'

import AppVersionContext from 'context/AppVersionContext'
import LangContext from 'context/LangContext'

import {
  setAddress,
  setLanguage,
  setNotificationPermission,
  setTrackingPermission,
  setWorkHours
} from 'store/auth/actions'

import AddressSheet from 'components/AddressSheet'
import Announcement from 'components/Announcement'
import Button from 'components/button/Button'
import Container from 'components/Container'
import LanguagePicker, { languages } from 'components/LanguagePicker'
import MobileHeader from 'components/MobileHeader'
import TableRow, { ActionableTableRow, TouchableTableRow } from 'components/tableRow/TableRow'
import TableRowGroup from 'components/tableRow/TableRowGroup'
import Toggle from 'components/Toggle'
import WorkHoursSheet from 'components/WorkHoursSheet'

import { isAutoSchedulingEnabled } from 'utils/featureFlags'
import { getAddressString } from 'utils/helpers'

import { blue } from 'styles/colors'

import { LinkableTableRow } from '../components/tableRow/TableRow'

const Settings = ({ setTrackingPermission, setNotificationPermission, setLanguage, setAddress, setWorkHours }) => {
  useEffect(() => {
    document.title = 'Settings'
  }, [])

  const employee = useSelector(({ auth }) => auth.user)
  const {
    notificationsAllowed,
    trackingAllowed,
    preferredLanguage,
    isTeamLead,
    isBam,
    address,
    workHours,
    latitude,
    longitude,
    updateSchedule = false
  } = employee
  const [languagePickerVisible, setLanguagePickerVisible] = useState(false)
  const [addressSheetVisible, setAddressSheetVisible] = useState(false)
  const [workHoursSheetVisible, setWorkHoursSheetVisible] = useState(false)
  const [userUpdatedSchedule, setUserUpdatedSchedule] = useState(false)
  const { translate } = useContext(LangContext)
  const { refreshCacheAndReload, isStaleVersion, loading: appVersionLoading } = useContext(AppVersionContext)
  const autoSchedulingEnabled = isAutoSchedulingEnabled(employee)

  const toggleLanguagePicker = () => {
    setLanguagePickerVisible(!languagePickerVisible)
  }

  const setUserLanguage = (selection) => {
    if (selection !== preferredLanguage) setLanguage(selection)
    toggleLanguagePicker()
  }

  const toggleAddressSheet = () => {
    setAddressSheetVisible(!addressSheetVisible)
  }

  const setUserAddress = ({ latitude: newLatitude, longitude: newLongitude, ...newAddress }) => {
    if (!isEqual(newAddress.address, address) || newLatitude !== latitude || newLongitude !== longitude) {
      setAddress({ ...newAddress, latitude: newLatitude, longitude: newLongitude })
      if (autoSchedulingEnabled) setUserUpdatedSchedule(true)
    }
    toggleAddressSheet()
  }

  const toggleWorkHoursSheet = () => {
    setWorkHoursSheetVisible(!workHoursSheetVisible)
  }

  const setUserWorkHours = (userWorkHours) => {
    if (!isEqual(userWorkHours, workHours)) {
      setWorkHours(userWorkHours)
      if (autoSchedulingEnabled) setUserUpdatedSchedule(true)
    }
    toggleWorkHoursSheet()
  }

  useEffect(() => {
    setUserUpdatedSchedule(updateSchedule)
  }, [updateSchedule])

  return (
    <>
      <MobileHeader isNavOnly />
      <Container>
        <div className="w-full p-px max-md:mt-16">
          {userUpdatedSchedule && (
            <Announcement
              icon="notification"
              text={translate('settings.notifyScheduleUpdate')}
              color={blue}
              link="/schedule"
            />
          )}
          <TableRowGroup heading={translate('common.settings')}>
            <ActionableTableRow
              onClick={toggleWorkHoursSheet}
              AddOn={workHours && <span>{`${workHours.start} - ${workHours.end}`}</span>}
            >
              <span>{translate('app.workHours')}</span>
            </ActionableTableRow>
            <ActionableTableRow onClick={toggleAddressSheet} AddOn={getAddressString(address)} chevron>
              <span>{translate('common.address')}</span>
            </ActionableTableRow>
            <TableRow>
              <span>{translate('common.notifications')}</span>
              <Toggle checked={notificationsAllowed} onClick={() => setNotificationPermission(!notificationsAllowed)} />
            </TableRow>
            <TableRow>
              <span>{translate('settings.locationSettings')}</span>
              <Toggle checked={trackingAllowed} onClick={() => setTrackingPermission(!trackingAllowed)} />
            </TableRow>
            <ActionableTableRow
              onClick={toggleLanguagePicker}
              AddOn={<span>{languages.find((l) => l.name === preferredLanguage).label}</span>}
            >
              <span>{translate('settings.preferredLanguage')}</span>
            </ActionableTableRow>
            <TouchableTableRow link="/settings/last-sync">
              <span>{translate('app.lastSyncTime')}</span>
            </TouchableTableRow>
            <TouchableTableRow link="/settings/mileage">
              <span>{translate('common.mileage')}</span>
            </TouchableTableRow>
          </TableRowGroup>

          {(isTeamLead || isBam) && (
            <TableRowGroup heading={translate('common.management')}>
              <TouchableTableRow link="/settings/delegations">
                <span>{translate('common.delegations')}</span>
              </TouchableTableRow>
            </TableRowGroup>
          )}

          <TableRowGroup heading={translate('common.help')}>
            <LinkableTableRow icon link="mailto:narrosupport@bat.com" target="_blank" rel="noopener noreferrer">
              <span>{translate('app.reportProblem')}</span>
            </LinkableTableRow>

            <TableRow>
              <span>Version {currentVersion}</span>
              {isStaleVersion ? (
                <Button primary small onClick={refreshCacheAndReload} isLoading={appVersionLoading}>
                  Update
                </Button>
              ) : (
                <Button secondary small disabled>
                  Latest version
                </Button>
              )}
            </TableRow>
          </TableRowGroup>

          {/*
        KEEP JUST IN CASE
        <TableRowGroup>
          <ActionableTableRow centered onClick={requestLogout}>
            <span style={{ color: primaryColor }}>{ongoingCall ? 'Cancel call and log out' : 'Log out'}</span>
          </ActionableTableRow>
        </TableRowGroup>
        */}
          <LanguagePicker
            visible={languagePickerVisible}
            setLanguage={setUserLanguage}
            close={() => setLanguagePickerVisible(false)}
            preferredLanguage={preferredLanguage}
          />
          <WorkHoursSheet
            visible={workHoursSheetVisible}
            setWorkHours={(update) => setUserWorkHours({ ...update, updateSchedule: autoSchedulingEnabled })}
            workHours={workHours}
            closeWorkHourSheet={toggleWorkHoursSheet}
            autoSchedulingEnabled={autoSchedulingEnabled}
          />
          <AddressSheet
            visible={addressSheetVisible}
            setUserAddress={(update) => setUserAddress({ ...update, updateSchedule: autoSchedulingEnabled })}
            userAddress={address}
            latLong={{ latitude, longitude }}
            toggleAddressVisible={toggleAddressSheet}
            autoSchedulingEnabled={autoSchedulingEnabled}
          />
        </div>
      </Container>
    </>
  )
}

Settings.propTypes = {
  setTrackingPermission: func.isRequired,
  setNotificationPermission: func.isRequired,
  setLanguage: func.isRequired,
  setAddress: func.isRequired,
  setWorkHours: func.isRequired
}

const mapActionCreators = {
  setTrackingPermission,
  setNotificationPermission,
  setLanguage,
  setAddress,
  setWorkHours
}

export default connect(null, mapActionCreators)(Settings)
