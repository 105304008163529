import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { bool, func, object, string } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchAmplifySelloutGeographyData } from 'store/Sellout/actions'
import { amplifySelloutKAData } from 'store/Sellout/selectors'

import Card from 'components/card'
import DataTable from 'components/DataTable/DataTable'
import DataVariation from 'components/DataTable/DataVariation'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'
import Tooltip from 'components/Tooltip'

import { DATAKEY_TYPES } from 'utils/constants'
import {
  formatCompactCurrency,
  formatCompactNumber,
  formatCurrency,
  formatNumber,
  formatPercent
} from 'utils/formatters'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const CardToolTip = tw(Tooltip)`h-full flex`

function generateRows(data, dataFormatter, tooltipFormatter) {
  if (!data) return []

  return data.map(({ name, mainPeriod, variation }) => ({
    headOffice: name,
    mainPeriod: (
      <CardToolTip isNumber hint={tooltipFormatter(mainPeriod)}>
        {dataFormatter(mainPeriod)}
      </CardToolTip>
    ),
    variation: (
      <CardToolTip isNumber hint={tooltipFormatter(variation)}>
        <DataVariation variation={variation} formatter={dataFormatter} />
      </CardToolTip>
    )
  }))
}

const AmplifySelloutKACard = ({
  span,
  currentProportion,
  currentTimeDisplay,
  currentMetric,
  currentChannel,
  currentVapeCategory,
  fetchAmplifySelloutGeographyData,
  dataType,
  selloutDataLoading
}) => {
  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  const { translate } = useContext(LangContext)
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)

  const [error, setError] = useState()
  if (error) console.error(error)

  const FIRST_COL = {
    field: 'headOffice',
    headerName: translate('app.headOffice')
  }

  const TIME_COLS = {
    rolling: [
      {
        field: 'mainPeriod',
        headerName: 'L4'
      },
      {
        field: 'variation',
        headerName: 'vs. P4'
      }
    ],
    pointInTime: [
      {
        field: 'mainPeriod',
        headerName: 'CTD'
      },
      {
        field: 'variation',
        headerName: 'vs. PC'
      }
    ]
  }

  const cols = [FIRST_COL].concat(TIME_COLS[currentTimeDisplay])

  const filters = useMemo(() => {
    if (currentProductType === 'fmc') return { manufacturer: 'ITCAN' }
    if (currentProductType === 'vape')
      return {
        manufacturer: 'Vuse',
        vapeCategory: currentVapeCategory
      }
    if (currentProductType === 'nrt')
      return {
        manufacturer: 'Zonnic'
      }
  }, [currentProductType, currentVapeCategory])

  const dataKey = useMemo(
    () =>
      createDataKey(DATAKEY_TYPES.AMPLIFY.SELL_OUT.GEOGRAPHY, {
        sectorType: selectedLevel,
        sectorId: currentSector[selectedLevel]?.id,
        productType: currentProductType,
        channel: currentChannel,
        geography: 'headoffice',
        filters,
        dataType
      }),
    [currentSector, selectedLevel, currentProductType, currentChannel, dataType, currentVapeCategory]
  )

  useEffect(() => {
    setError()
    if (currentSector[selectedLevel]?.id && selectedLevel !== 'customer') {
      fetchAmplifySelloutGeographyData(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          type: currentProductType,
          channel: currentChannel,
          geography: 'headoffice',
          filters,
          dataType
        },
        dataKey
      ).catch((error) => {
        if (isMounted.current) setError(getErrorMessage(error))
      })
    }
  }, [dataKey])
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const selloutData = useSelector((state) =>
    amplifySelloutKAData(state, {
      currentSector,
      selectedLevel,
      activeProductType: currentProductType,
      currentChannelFilter: currentChannel,
      currentProportion,
      currentTimeDisplay,
      currentMetric,
      currentChannel,
      currentVapeCategory,
      geography: 'headoffice',
      currentManufacturer: filters.manufacturer,
      dataType
    })
  )

  if (selectedLevel === 'customer')
    return (
      <Card title="KA" span={span} displayAmplify={false}>
        <EmptyState title={translate('app.warn.noRelevantData')} />
      </Card>
    )

  if (selloutDataLoading || isLoading) {
    return (
      <Card title="KA" span={span} displayAmplify={false}>
        <WrappedSpinner icon="spinner" />
      </Card>
    )
  }

  if (isEmpty(selloutData)) {
    return (
      <Card title="KA" span={span} displayAmplify={false}>
        <EmptyState title={translate('app.warn.noData')} />
      </Card>
    )
  }

  const dataFormatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true })
      : currentMetric === 'cost'
      ? formatCompactCurrency
      : formatCompactNumber

  const tooltipFormatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true })
      : currentMetric === 'cost'
      ? formatCurrency
      : formatNumber

  return (
    <Card title={translate('app.acronyms.KA')} span={span} displayAmplify={false}>
      <div className="max-h-[490px] overflow-y-auto">
        <DataTable columns={cols} rows={generateRows(selloutData, dataFormatter, tooltipFormatter)} fillContainer />
      </div>
    </Card>
  )
}

AmplifySelloutKACard.propTypes = {
  span: object,
  currentProportion: string,
  currentTimeDisplay: string,
  currentMetric: string,
  currentChannel: string,
  currentVapeCategory: string,
  dataType: string,
  fetchAmplifySelloutGeographyData: func,
  selloutDataLoading: bool
}

export default connect(null, { fetchAmplifySelloutGeographyData })(AmplifySelloutKACard)
