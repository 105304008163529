import React, { useEffect, useState } from 'react'
import { func, number } from 'prop-types'
import styled from 'styled-components'

import Checkbox from 'components/Checkbox'
import Status from 'components/Status'

import { SURVEY_COMPLETION_LABELS } from 'utils/constants'

import { greyDark } from 'styles/colors'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { fontSemibold, smallFontSize } from 'styles/typography'

const Container = styled.div`
  position: relative;
  margin-bottom: ${spacing.medium};
  padding-left: ${spacing.medium};
  padding-right: ${spacing.medium};

  ${media.breakpoint`
    padding: 0;
  `};
`

const Inner = styled.div`
  display: grid;
  grid-gap: ${spacing.medium};
  grid-template-columns: repeat(2, 1fr);
`

const Item = styled.div`
  display: flex;
  align-items: flex-start;
`

const Main = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: -3px; /* Align number with checkbox label */
`

const Count = styled.span`
  font-size: ${smallFontSize};
  font-weight: ${fontSemibold};
  display: block;
  margin-left: 16px; /* Align number with checkbox label */
`

const Heading = styled.span`
  font-size: ${smallFontSize};
  font-weight: ${fontSemibold};
  display: block;
  color: ${greyDark};
  margin-bottom: ${spacing.medium};
`

const SurveyCompletionFilters = ({
  total,
  complete,
  notStarted,
  inProgress,
  clarificationNeeded,
  updateCompletionFilters
}) => {
  const [completionFilters, setCompletionFilters] = useState({
    complete: false,
    notStarted: false,
    inProgress: false,
    clarificationNeeded: false
  })

  const counts = {
    notStarted,
    inProgress,
    complete,
    clarificationNeeded
  }

  const toggleCompletion = (completionStatus) => {
    setCompletionFilters({
      ...completionFilters,
      [completionStatus]: !completionFilters[completionStatus]
    })
  }

  useEffect(() => {
    updateCompletionFilters(Object.keys(completionFilters).filter((key) => completionFilters[key]))
  }, [completionFilters])

  return (
    <Container>
      <Heading>Filter by:</Heading>
      <Inner>
        {Object.entries(SURVEY_COMPLETION_LABELS).map(([status, label], idx) => (
          <Item key={idx}>
            <Checkbox checked={completionFilters[status]} onClick={() => toggleCompletion(status)} />
            <Main>
              <Status status={status} label={label} />
              <Count>
                {counts[status]}/{total}
              </Count>
            </Main>
          </Item>
        ))}
      </Inner>
    </Container>
  )
}

SurveyCompletionFilters.propTypes = {
  total: number,
  complete: number,
  notStarted: number,
  inProgress: number,
  clarificationNeeded: number,
  updateCompletionFilters: func
}

export default SurveyCompletionFilters
