export function trendingReducer(state, { payload: { id, trending } }) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      extraHub: {
        ...state[id]?.extraHub,
        trending
      }
    }
  })
}

export function engagementReducer(state, { payload: { id, engagement } }) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      extraHub: {
        ...state[id]?.extraHub,
        engagement
      }
    }
  })
}

export function filtersReducer(state, { payload: { id, filters } }) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      extraHub: {
        ...state[id]?.extraHub,
        filters
      }
    }
  })
}

export function geographyReducer(state, { payload: { id, geographies, geography, activity } }) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      extraHub: {
        ...state[id]?.extraHub,
        geographies: {
          ...state[id]?.extraHub?.geographies,
          [`${geography}-${activity}`]: geographies
        }
      }
    }
  })
}
export function activityReducer(state, { payload: { id, activity } }) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      extraHub: {
        ...state[id]?.extraHub,
        activity
      }
    }
  })
}
