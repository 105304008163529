const getRoot = ({ sectorType, sectorId }) => `/${sectorType}/${sectorId}/pace/close`

const HEADER_CONTENT = {
  'consumer-offer': {
    header: () => 'Consumer Offer',
    breadcrumbs: (params) => [
      { text: 'Close', href: `${getRoot(params)}` },
      { text: 'Consumer Offer', href: `${getRoot(params)}/consumer-offer` }
    ]
  },
  'insight-to-action': {
    header: () => 'Projects',
    breadcrumbs: (params) => [
      { text: 'Close', href: `${getRoot(params)}` },
      { text: 'Projects', href: `${getRoot(params)}/insight-to-action` }
    ]
  },
  'insight-to-action/:projectId': {
    header: () => 'Projects',
    breadcrumbs: (params, { getProjectName = () => 'Project 2' }) => [
      { text: 'Close', href: `${getRoot(params)}` },
      { text: 'Projects', href: `${getRoot(params)}/insight-to-action` },
      { text: getProjectName(), href: `${getRoot(params)}/insight-to-action/${params.projectId}` }
    ]
  }
}

export default HEADER_CONTENT
