import { createAction } from 'redux-actions'

import { AMPLIFY_SELECTOR_ACTIONS, PLANNING_SELECTOR_ACTIONS } from 'store/actions'
import { setDataFetch } from 'store/dataFetches/actions'

import { DATA_UPDATE_STATUS } from 'utils/constants'
import { dataIsStillValid, ERRORS, runStressTest, validateStoreDataKey } from 'utils/helpers'

import * as api from './endpoints'

export const fetchPlanningSellin = createAction(
  'Get Planning Sell-in Values',
  (props, dataKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches

    if (dataIsStillValid(dataFetchesState, dataKey)) return
    try {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline

      const { data } = await api.fetchPlanningSellin(props)
      const addResultToScope = PLANNING_SELECTOR_ACTIONS[props.sectorLevel].sellin

      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))

      // STRESS TEST - TO REMOVE
      runStressTest(() => api.fetchPlanningSellin(props))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

export const fetchOrderCompletion = createAction(
  'Get Sell In Order Completion',
  (props, dataKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches

    if (dataIsStillValid(dataFetchesState, dataKey)) return
    try {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline

      const { data } = await api.fetchOrderCompletion(props)
      const addResultToScope = PLANNING_SELECTOR_ACTIONS[props.sectorLevel].sellinOrderCompletion

      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))

      // STRESS TEST - TO REMOVE
      runStressTest(() => api.fetchOrderCompletion(props))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

export const fetchSellinRunRate = createAction(
  'Get Sell In Run Rate',
  (props, dataKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches

    if (dataIsStillValid(dataFetchesState, dataKey)) return
    try {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline

      const { data } = await api.fetchSellinRunRate(props)
      const addResultToScope = PLANNING_SELECTOR_ACTIONS[props.sectorLevel].sellinRunRate

      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))

      // STRESS TEST - TO REMOVE
      runStressTest(() => api.fetchSellinRunRate(props))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

export const fetchSellinGeography = createAction(
  'Get Sell In Geography',
  (props, dataKey) => async (dispatch, getState) =>
    validateStoreDataKey(getState(), dispatch, dataKey, async () => {
      const { data } = await api.fetchSellinGeography(props)
      const addResultToScope = AMPLIFY_SELECTOR_ACTIONS[props.sectorType].sellin.geography

      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )
    })
)
