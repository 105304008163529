import { createAction } from 'redux-actions'

import { AMPLIFY_SELECTOR_ACTIONS } from 'store/actions'

import { validateStoreDataKey } from 'utils/helpers'

import * as api from './endpoints'

export const fetchTrendingActivities = createAction(
  'Fetch Extra Hub Trending Activities Data',
  ({ sectorType, sectorId, dataKey }) =>
    (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { trending }
        } = await api.getTrending({ sectorType, sectorId })

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].extraHub?.trending
        dispatch(
          action({
            id: sectorId,
            trending
          })
        )
      })
)

export const fetchEngagement = createAction(
  'Fetch Extra Hub Engagement Data',
  ({ dataKey, sectorType, sectorId }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { engagement }
        } = await api.getEngagement({ sectorType, sectorId })

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].extraHub?.engagement
        dispatch(
          action({
            id: sectorId,
            engagement
          })
        )
      })
)

export const fetchGeography = createAction(
  'Fetch Extra Hub Geography Data',
  ({ dataKey, sectorType, sectorId, geography, activity }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { geographies }
        } = await api.getGeography({ sectorType, sectorId, geography, activity })

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].extraHub?.geography
        dispatch(
          action({
            id: sectorId,
            geographies,
            geography,
            activity
          })
        )
      })
)

export const fetchFilters = createAction(
  'Fetch Extra Hub Table Filters Data',
  ({ dataKey, sectorType, sectorId }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { filters }
        } = await api.getFilters({ sectorType, sectorId })

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].extraHub?.filters
        dispatch(
          action({
            id: sectorId,
            filters
          })
        )
      })
)

export const fetchActivity = createAction(
  'Fetch Extra Hub Activity Data',
  ({ dataKey, sectorType, sectorId }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { activity }
        } = await api.getActivity({ sectorType, sectorId })

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].extraHub.activity
        dispatch(
          action({
            id: sectorId,
            activity
          })
        )
      })
)
