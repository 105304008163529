import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'

export const mergeGeography = (
  state,
  { payload: { id, type, channel, result, dataType, geography, filters, uniqByCols } }
) => {
  const filterString = sortBy(Object.keys(filters))
    .map((k) => filters[k])
    .filter(Boolean)
    .join('-')
  const stateKey = `amplify-sellout-${type}-${channel}-${dataType}-${geography}-${filterString}`
  const existingEntity = state[id] || {}
  const existingResult = existingEntity[stateKey] || []
  return Object.assign({}, state, {
    [id]: {
      ...existingEntity,
      [stateKey]: uniqBy(existingResult.concat(result), (d) => uniqByCols.map((col) => d[col]).join('-'))
    }
  })
}
