import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import pickBy from 'lodash/pickBy'
import moment from 'moment'
import { bool, func } from 'prop-types'
import { Form, getFormValues, reduxForm, submit } from 'redux-form'
import styled from 'styled-components'

import ActionHeaderContext from 'context/ActionHeaderContext'

import { getCustomer } from 'store/customers/actions'
import * as customerSelector from 'store/customers/selectors'
import { setDataFetch } from 'store/dataFetches/actions'
import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { submitSurveyAnswers } from 'store/surveys/actions'
import { fetchTerritory } from 'store/territories/actions'

import Container from 'components/Container'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import LoadingCard from 'components/LoadingCard'
import Spinner from 'components/Spinner'

import { DATA_UPDATE_STATUS, DATAKEY_TYPES, SECTOR_LEVELS } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

import { borderColor, white } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

import RenderSurveyQuestions from './RenderSurveyQuestions'

const goBack = (navigate) => navigate('..')

const Card = styled.div`
  padding: ${spacing.medium};
  border-top: 1px solid ${borderColor};
  border-bottom: 1px solid ${borderColor};
  margin-bottom: ${spacing.medium};
  background-color: ${white};

  ${media.breakpoint`
    border: 1px solid ${borderColor};
    border-radius: ${borderRadius};
  `};
`

const CustomerSurvey = ({ handleSubmit, submitting, submitSurveyAnswers, pristine, change, dispatch, ...props }) => {
  const navigate = useNavigate()
  const { surveyId, sectorId, customerId: paramCustomerId, sectorType } = useParams()

  useEffect(() => {
    if (paramCustomerId && sectorType === SECTOR_LEVELS.CUSTOMER) {
      navigate(`/${sectorType}/${sectorId}/actions/surveys/${surveyId}`)
    }
  }, [sectorType])

  const customerId = sectorType === SECTOR_LEVELS.TERRITORY ? paramCustomerId : sectorId

  const { survey, questions, answers, customerSurvey } =
    useSelector((state) => customerSelector.customerSurvey(state, { surveyId, customerId })) || {}
  const isMobileViewOnly = useSelector(({ auth }) => auth.user.isMobileViewOnly)
  const customer = useSelector((state) => customerSelector.currentCustomer(state, { customerId }))

  const formatAndSubmitSurvey = async ({ startedAt, ...values }) => {
    const newAnswers = questions.reduce((acc, question) => {
      const answer = values[question.id]
      const existingAnswers = answers.filter(({ questionId }) => questionId === question.id)

      if (question.type === 'sox' && !existingAnswers?.length) return acc

      const existingSingleAnswer = existingAnswers[0] || { questionId: question.id }
      const controlNotSatisfiedAnswer = { text: 'N/A - Hidden' }

      const isHiddenControlledQuestion =
        question.controlQuestionId && values[question.controlQuestionId] !== question.controlAnswerId

      if (question.type === 'sox') {
        const updatedSoxAnswers = isHiddenControlledQuestion
          ? existingAnswers.map((a) => ({ ...a, answer: controlNotSatisfiedAnswer }))
          : existingAnswers.map((a) => {
              if (a.verificationStatus === 'PASS') return a
              const newSoxResponse = pickBy(answer && answer[`ans_${a.id}`], (val) => !isEmpty(val))
              if (isEmpty(newSoxResponse) || isEqual(newSoxResponse, a.answer)) return a
              return {
                ...a,
                answer: newSoxResponse,
                verificationStatus: a.verificationStatus === 'FAIL' ? 'RESUBMITTED' : a.verificationStatus
              }
            })
        return acc.concat(updatedSoxAnswers)
      }
      if (isHiddenControlledQuestion) {
        return acc.concat({ ...existingSingleAnswer, answer: controlNotSatisfiedAnswer, options: null })
      }
      if (!existingAnswers.length && isEmpty(answer)) return acc
      if (isEmpty(answer)) return acc.concat({ ...existingSingleAnswer, answer: null, options: null })
      if (['text', 'number'].includes(question.type)) {
        return acc.concat({ ...existingSingleAnswer, answer: { text: answer }, options: null })
      }
      if (['dropdown', 'multi'].includes(question.type)) {
        return acc.concat({ ...existingSingleAnswer, answer: null, options: [].concat(answer).map((id) => ({ id })) })
      }
      if (question.type === 'images') {
        return acc.concat({ ...existingSingleAnswer, answer: { imgs: answer }, options: null })
      }
      console.dir({ error: 'INVALID QUESTION TYPE', question, answer, existingAnswers })
      return acc
    }, [])

    // mark the data that needs refresh
    dispatch(
      setDataFetch({ dataKey: createDataKey(DATAKEY_TYPES.CUSTOMER, { customerId }), status: DATA_UPDATE_STATUS.OLD })
    )

    return submitSurveyAnswers(
      {
        id: customerSurvey?.id,
        customerId,
        surveyId,
        startedAt,
        finishedAt: new Date().toISOString(),
        answers: newAnswers
      },
      () => goBack(navigate)
    )
  }

  useEffect(() => {
    change('startedAt', new Date().toISOString())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const currentlyLocked =
    survey?.lockStartDate &&
    survey?.lockEndDate &&
    moment().isBetween(survey?.lockStartDate, survey?.lockEndDate, 'day', '[]')

  const isLocked = currentlyLocked && (survey.allLocked || customerSurvey.isLocked) // && customerSurvey.completion !== 'notStarted'
  const noEditsAllowed = isLocked && customerSurvey.completion !== 'clarificationNeeded'

  const { addAction } = useContext(ActionHeaderContext)
  useEffect(() => {
    addAction({
      getSurveyName: () => survey?.name || 'Survey',
      isDisabled: () => noEditsAllowed || pristine || isMobileViewOnly,
      onSubmit: () => {
        dispatch(submit('survey'))
      },
      isSubmitting: () => submitting,
      getCustomerName: () => customer?.name || 'Customer',
      getSectorLevel: () => sectorType
    })
  }, [survey?.name, noEditsAllowed, pristine, isMobileViewOnly, submitting, customer, sectorType])

  if (!survey) return null

  return (
    <Form onSubmit={handleSubmit(formatAndSubmitSurvey)}>
      <Container padded>
        <Card>
          <Fieldset>
            {noEditsAllowed && (
              <FieldsetItem>
                <GlobalAlert warning icon="lock">
                  This survey cannot be edited
                </GlobalAlert>
              </FieldsetItem>
            )}
            <RenderSurveyQuestions questions={questions} answers={answers} isLocked={isLocked} {...props} />
          </Fieldset>
        </Card>
      </Container>
    </Form>
  )
}

CustomerSurvey.propTypes = {
  submitSurveyAnswers: func.isRequired,
  handleSubmit: func.isRequired,
  submitting: bool,
  pristine: bool,
  change: func,
  dispatch: func
}

const mapActionCreators = {
  submitSurveyAnswers
}

const selector = getFormValues('survey')
const mapStateToProps = (state, props) => {
  return {
    employeeId: state.auth.user.id,
    formValues: selector(state),
    initialValues: customerSelector.customerSurveyInitialValues(state, props),
    backPath: '..',
    onSubmitSuccess: () => {
      goBack(props.navigate)
    }
  }
}

const CustomerSurveyForm = reduxForm({
  form: 'survey'
})(CustomerSurvey)

const CustomerSurveyFormParams = connect(mapStateToProps, mapActionCreators)(CustomerSurveyForm)

const SurveyForm = ({ connectedGetCustomer, connectedFetchTerritory }) => {
  const navigate = useNavigate()
  const { sectorType, sectorId, surveyId, customerId: paramCustomerId } = useParams()
  const [loading, setLoading] = useState()
  const isMounted = useRef()

  const customerId = sectorType === SECTOR_LEVELS.TERRITORY ? paramCustomerId : sectorId
  const territoryId = sectorType === SECTOR_LEVELS.TERRITORY && sectorId

  const customerDataKey = createDataKey(DATAKEY_TYPES.CUSTOMER, { customerId })
  const territoryDataKey = createDataKey(DATAKEY_TYPES.TERRITORY, { territoryId })
  const customerDataLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: customerDataKey }))
  const territoryDataLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: territoryDataKey }))
  const isLoading = loading || customerDataLoading || territoryDataLoading

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const promises = []
    setLoading(true)
    promises.push(connectedGetCustomer(customerId, customerDataKey))
    if (territoryId) {
      promises.push(connectedFetchTerritory(territoryId, territoryDataKey))
    }
    Promise.all(promises).then(() => {
      if (isMounted.current) setLoading(false)
    })
  }, [customerId])

  if (territoryDataLoading) return <LoadingCard dataKey={territoryDataKey} />
  if (customerDataLoading) return <LoadingCard dataKey={customerDataKey} />
  if (isLoading)
    return (
      <div className="flex items-center justify-center">
        <Spinner icon="spinner" />
      </div>
    )

  return <CustomerSurveyFormParams customerId={customerId} surveyId={surveyId} navigate={navigate} />
}

SurveyForm.propTypes = {
  connectedGetCustomer: func,
  connectedFetchTerritory: func
}

export default connect(null, {
  connectedGetCustomer: getCustomer,
  connectedFetchTerritory: fetchTerritory
})(SurveyForm)
