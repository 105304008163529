import React, { useMemo } from 'react'
import { array } from 'prop-types'

import BasicAccordion from 'components/accordion/BasicAccordion'
import Container from 'components/Container'
import KeyAccountOffers from 'components/KeyAccountOffers'
import KeyCard from 'components/KeyCard'

const KeyAccounts = ({ consumerOffers, keyCards }) => {
  const offers = useMemo(() => {
    if (!consumerOffers.length) return null

    return consumerOffers.reduce(
      (acc, offer) => {
        const category = acc[offer.category] || {}
        const brand = category[offer.brand] || {}
        const packSize = brand[`${offer.packSize}`] || []
        return {
          ...acc,
          [offer.category]: {
            ...category,
            [offer.brand]: {
              ...brand,
              [`${offer.packSize}`]: packSize.concat(offer)
            }
          }
        }
      },
      {
        FMC: {},
        VUSE: {}
      }
    )
  }, [consumerOffers])

  const hasConsumerOffers = offers && Object.keys(offers.FMC).concat(Object.keys(offers.VUSE)).length > 0

  return (
    <>
      <Container noPadding fullWidth>
        <div>
          {hasConsumerOffers && <KeyAccountOffers fmcOffers={offers.FMC} vuseOffers={offers.VUSE} />}
          {keyCards.length > 0 && (
            <BasicAccordion title="Key Cards" preExpand>
              {keyCards.map((card, idx) => (
                <KeyCard key={idx} card={card} />
              ))}
            </BasicAccordion>
          )}
        </div>
        <h5 className="mb-4 mt-6 text-2xs font-bold uppercase">Quick links</h5>
        <div className="flex flex-col gap-1">
          <a
            className="w-min text-nowrap text-brand-600 hover:underline"
            target="_blank"
            href="https://bat.sharepoint.com/:f:/s/KeyAccountTracker/ElwtXFSJs2VFoXPFDvMrD5cBVCeYakc-2k-8nTWTUywMOg?e=5aHYJ5"
            rel="noreferrer"
          >
            What&apos;s new? (Sales Bulletin)
          </a>
          <a
            className="w-min text-nowrap text-brand-600 hover:underline"
            target="_blank"
            href="https://bat.sharepoint.com/sites/KeyAccountTracker/Shared%20Documents/Forms/AllItems.aspx?originalPath=aHR0cHM6Ly9iYXQuc2hhcmVwb2ludC5jb20vOmY6L3MvS2V5QWNjb3VudFRyYWNrZXIvRWx3dFhGU0pzMlZGb1hQRkR2TXJENWNCVkNlWWFrYy0yay04blRXVFV5d01PZz9ydGltZT1ZalMxa2ZhYTJFZw&viewid=800571a9%2D4437%2D4522%2Da9a9%2D41393e6f4fb0&id=%2Fsites%2FKeyAccountTracker%2FShared%20Documents%2FGeneral%2FMemos"
            rel="noreferrer"
          >
            INTERNAL Memo
          </a>
          <a
            className="w-min text-nowrap text-brand-600 hover:underline"
            target="_blank"
            href="https://bat.sharepoint.com/:x:/r/sites/KeyAccountTracker/_layouts/15/guestaccess.aspx?e=IEgplI&wdLOR=c9E66D299-89A2-4ECE-91DF- 78211F89302F&share=ERBDfb4Jo61Ii95KpnDfQ2QB2YJCQMrOSA9l0SF7F7gafw"
            rel="noreferrer"
          >
            KA Trackers
          </a>
        </div>
      </Container>
    </>
  )
}

KeyAccounts.propTypes = {
  consumerOffers: array.isRequired,
  keyCards: array.isRequired
}

export default KeyAccounts
