import React from 'react'
import { useParams } from 'react-router-dom'

import { SECTOR_LEVELS } from 'utils/constants'

import CustomerProjects from './CustomerProjects'
import TerritoryProjects from './TerritoryProjects'

const I2AView = () => {
  const { sectorType } = useParams()

  if (sectorType === SECTOR_LEVELS.CUSTOMER) {
    return <CustomerProjects />
  }

  return <TerritoryProjects />
}

export default I2AView
