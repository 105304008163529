import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import { bool, func, object, string } from 'prop-types'

import SectorContext from 'context/SectorContext'

import { submitCustomerProjectResponse } from 'store/customers/actions'

import Accordion from 'components/accordion/BasicAccordion'
import Button from 'components/button/Button'
import ActionsQuestionsFeedback from 'components/CustomerProject/ActionsQuestionsFeedback'
import CustomerProjectMessages from 'components/CustomerProject/Messages'
import ErrorMessage from 'components/ErrorMessage'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import { WrappedSpinner } from 'components/Spinner'

// import { isSAQBlackout } from 'utils/featureFlags'

// const kaLink = {
//   en: 'View KA SLAs & Consumer Offers',
//   fr: 'Voir SLAs & Offres Commerciales'
// }

function getFormInitialValues({ project, customerProject }) {
  const { id: defaultFeedbackId } = project.feedbacks[0]
  const defaultActionValues = project.actions.reduce(
    (acc, { id }) => ({ ...acc, [`a_${id}`]: { complete: false } }),
    {}
  )
  return pickBy(
    {
      comment: customerProject.comment,
      actions:
        customerProject?.actionLinks?.reduce(
          (acc, { actionId, score, complete }) => ({
            ...acc,
            [`a_${actionId}`]: { complete: complete || Boolean(score), score: score ? String(score) : null }
          }),
          defaultActionValues
        ) || defaultActionValues,
      answers:
        customerProject?.answerLinks?.reduce((acc, { questionId, answerId, textAnswer }) => {
          if (!questionId || (!answerId && !textAnswer)) return acc
          const answer = pickBy({ answerId: String(answerId || ''), textAnswer }, Boolean)
          return {
            ...acc,
            [`q_${questionId}`]: answer
          }
        }, {}) || null,
      feedback: { id: customerProject.feedback ? String(customerProject.feedback.id) : String(defaultFeedbackId) }
    },
    Boolean
  )
}

const formatProjectResponse = ({ actions, answers, feedback, comment }, customerProject) => {
  const submittedAt = new Date().toISOString()

  const updatedActions = Object.entries(actions)
    .map(([actId, { complete, score }]) => ({
      actionId: +actId.replace('a_', ''),
      complete,
      score: +score || null,
      updatedAt: submittedAt
    }))
    .filter(({ actionId, complete, score }) => {
      const existingAction = customerProject.actionLinks.find((actionLink) => actionLink.actionId === actionId)
      return !existingAction || complete !== existingAction.complete || score !== existingAction.score
    })
  const updatedAnswers = Object.entries(answers)
    .map(([qId, { answerId, textAnswer }]) => ({
      questionId: +qId.replace('q_', ''),
      answerId: +answerId || null,
      textAnswer: textAnswer?.trim() || null,
      updatedAt: submittedAt
    }))
    .filter(({ questionId, answerId, textAnswer }) => {
      const existingAnswer = customerProject.answerLinks.find((qId) => qId === questionId)
      return !existingAnswer || answerId !== existingAnswer.answerId || textAnswer !== existingAnswer.textAnswer
    })

  const updatedComment = comment?.trim() === customerProject.comment ? null : comment?.trim()
  const updatedFeedback = +feedback.id === customerProject.feedback?.id ? null : feedback
  return { updatedActions, updatedAnswers, updatedFeedback, updatedComment, submittedAt }
}
const ProjectForm = (props) => {
  const {
    project,
    customerProject,
    invalid,
    submitting,
    submitCustomerProjectResponse,
    locationState,
    submit,
    formValues
  } = props
  const { currentSector, selectedLevel } = useContext(SectorContext)
  const [error, setError] = useState()
  const [projectIsLoading, setProjectIsLoading] = useState(false)
  const customer = currentSector[selectedLevel]

  const projectResolved = useMemo(() => customerProject?.status === 'resolved' || false, [customerProject?.status])
  const projectPending = useMemo(() => customerProject?.status === 'pending' || false, [customerProject?.status])
  const navigate = useNavigate()
  // const location = useLocation()
  const preferredLanguage = useSelector(({ auth }) => auth.user.preferredLanguage.substring(0, 2))
  const employee = useSelector((state) => state.auth.user)

  const [saqInitialValues, setSaqInitialValues] = useState()
  const hasCompleteAction = useMemo(
    () => Boolean(find(formValues?.actions, ({ complete }) => complete)),
    [formValues?.actions]
  )

  useEffect(() => {
    if (!customer?.saqCreationDisabled && hasCompleteAction && saqInitialValues) submit()
  }, [customer?.saqCreationDisabled])

  const submitResponse = async ({ actions, answers, feedback, comment }) => {
    setProjectIsLoading(true)
    setError()

    const { updatedAnswers, updatedActions, updatedFeedback, updatedComment, submittedAt } = formatProjectResponse(
      { actions, answers, feedback, comment },
      customerProject
    )
    const valuesUnchanged = !updatedActions?.length && !updatedAnswers?.length && !updatedFeedback && !updatedComment

    if (valuesUnchanged) return

    const values = {
      customerId: customerProject.customerId,
      projectId: customerProject.projectId,
      submittedAt,
      status: customerProject.status.startsWith('followUp') ? 'followUpPending' : 'pending',
      answerLinks: updatedAnswers,
      actionLinks: updatedActions,
      feedback: updatedFeedback,
      comment: updatedComment
    }

    return submitCustomerProjectResponse(values)
      .then(() => navigate(`/customer/${customerProject.customerId}/pace/close/insight-to-action`))
      .catch((err) => setError(err.message))
      .finally(() => setProjectIsLoading(false))
  }

  if (!project.customerSectionTitle) return null

  if (projectIsLoading) return <WrappedSpinner icon="spinner" />

  const isBlackoutPeriod = false // isSAQBlackout(employee)
  return (
    <Formik
      onSubmit={submitResponse}
      initialValues={getFormInitialValues({ project, customerProject })}
      enableReinitialize
      validate={validateCustomerProject}
    >
      {({ dirty, errors, isSubmitting }) => {
        return (
          <Form>
            <Accordion title={project.customerSectionTitle[preferredLanguage]} isSubrow preExpand>
              <CustomerProjectMessages
                messages={customerProject.messages}
                hasSAQ={customerProject.saqAvailable}
                preferredLanguage={preferredLanguage}
                customerId={customerProject.customerId}
                projectId={customerProject.projectId}
                orders={customerProject.orders}
                locationState={locationState}
                status={customerProject.status}
                hasCompleteAction={hasCompleteAction}
                setSaqInitialValues={setSaqInitialValues}
                isSubmitting={submitting}
                customerSaqsDisabled={isBlackoutPeriod || customer?.saqCreationDisabled}
              />
            </Accordion>
            {/* {customerProject.linkOffers && (
              <KeyAccountsLink
                to={{
                  pathname: `/customers/${customerProject.customerId}/investments/key-accounts`,
                  state: { prevRoute: location.pathname }
                }}
                label={kaLink[preferredLanguage]}
              />
            )} */}
            <Accordion title="Action now" isSubrow preExpand>
              <ActionsQuestionsFeedback key={`${customerProject.customerId}-${customerProject.projectId}`} {...props} />
              {!isEmpty(errors) && <ErrorMessage>{errors.actions}</ErrorMessage>}
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <Fieldset>
                <FieldsetItem>
                  <Button
                    type="submit"
                    primary
                    full
                    disabled={!dirty || !isEmpty(errors) || invalid || isSubmitting || employee.isMobileViewOnly}
                    isLoading={isSubmitting}
                  >
                    {(projectResolved || projectPending) && dirty ? 'Apply changes' : 'Done'}
                  </Button>
                </FieldsetItem>
              </Fieldset>
            </Accordion>
          </Form>
        )
      }}
    </Formik>
  )
}

const validateCustomerProject = (values) => {
  const errors = {}
  const minOneActionComplete =
    !values.actions || Object.values(values.actions).some(({ complete }) => Boolean(complete))
  if (!minOneActionComplete) errors.actions = 'At least one action must be completed.'
  return errors
}

ProjectForm.propTypes = {
  project: object.isRequired,
  customerProject: object.isRequired,
  pristine: bool,
  invalid: bool,
  submitting: bool,
  submitCustomerProjectResponse: func,
  handleSubmit: func,
  error: string,
  anyTouched: bool,
  formValues: object,
  change: func,
  locationState: object,
  submit: func,
  submitSucceeded: bool,
  initialized: bool
}

export default connect(null, {
  submitCustomerProjectResponse
})(ProjectForm)
