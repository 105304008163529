import React, { useContext, useEffect } from 'react'

import LangContext from 'context/LangContext'
import MediaContext from 'context/MediaContext'

import Brands from './Brands'
import BVLS from './BVLS'
import Cap from './Cap'
import Geography from './Geography'
import { InventoryCaptureProvider } from './InventoryCaptureContext'
import InventoryCaptureOosHeader from './InventoryCaptureOosHeader'
import Trending from './Trending'

const AmplifyOOSInvCapLayout = () => {
  const { translate } = useContext(LangContext)
  const { isWideLayout } = useContext(MediaContext)

  useEffect(() => {
    document.title = 'Amplify - OOS & Inv'
  }, [])

  return (
    <InventoryCaptureProvider>
      <div className="grid w-full grid-cols-12 gap-5 p-5">
        <InventoryCaptureOosHeader parentHeader="Amplify" header={translate('app.OOSInventory')} />
        <Cap span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 2 }} />
        <Trending span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 4 }} />
        <Brands span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 3 }} />
        <BVLS span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 3 }} />
        {isWideLayout && <Geography span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} />}
      </div>
    </InventoryCaptureProvider>
  )
}

export default AmplifyOOSInvCapLayout
