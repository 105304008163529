import { isMobile } from 'react-device-detect'
import { getIn } from 'formik'
import camelCase from 'lodash/camelCase'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import max from 'lodash/max'
import min from 'lodash/min'
import pick from 'lodash/pick'
import pullAt from 'lodash/pullAt'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'
import sum from 'lodash/sum'
import sumBy from 'lodash/sumBy'
import toArray from 'lodash/toArray'
import toUpper from 'lodash/toUpper'
import moment from 'moment'
import { customAlphabet } from 'nanoid'

import { setDataFetch } from 'store/dataFetches/actions'

import {
  GO_FUND_PAYMENT_TYPES,
  KPI_OPTIONS,
  MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_IN,
  MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_OUT,
  POWER_HOUR,
  SHARE_GROWTH_PROGRAM,
  VOLUME_OFFTAKE_PROGRAM
} from 'utils/constants'

import { red } from 'styles/colors'

import { DATA_EXPIRATION_MINUTES, DATA_UPDATE_STATUS, STRESS_TEST_AMOUNT } from './constants'

// pass in a formatting function to specify how keys should be formatted.
export const deepNormalizeObjectKeys = (keyFormatter) =>
  function normalizer(obj) {
    const objIsArray = Array.isArray(obj)
    const newObj = {}
    for (const key in obj) {
      let value = obj[key]
      if (value && typeof value === 'object') {
        value = normalizer(value)
      }

      newObj[keyFormatter(key)] = value
    }
    // arrays are returned as objects with numbered keys, so they need to be converted back
    return objIsArray ? toArray(newObj) : newObj
  }

export const camelFormatter = deepNormalizeObjectKeys(camelCase)

export const arrayinator = (obj, length = 6, array = []) => {
  return length > 0 ? arrayinator(obj, length - 1, [...array, obj]) : array
}

export const calculatedDistance = (storeLocation, userCoords) => {
  if (isEmpty(storeLocation) || isEmpty(userCoords)) return '-'
  const lat1 = storeLocation.latitude || storeLocation[0]
  const lon1 = storeLocation.longitude || storeLocation[1]
  const lat2 = userCoords.latitude || userCoords[0]
  const lon2 = userCoords.longitude || userCoords[1]
  if (!lat1 || !lon1 || !lat2 || !lon2) return '-'
  return PythagorasEquirectangular(lat1, lon1, lat2, lon2)
}

// Convert Degress to Radians
function Deg2Rad(deg) {
  return (deg * Math.PI) / 180
}

// return the difference in distance between 2 sets of coordinates
function PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
  lat1 = Deg2Rad(lat1)
  lat2 = Deg2Rad(lat2)
  lon1 = Deg2Rad(lon1)
  lon2 = Deg2Rad(lon2)
  const R = 6371 // km
  const x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2)
  const y = lat2 - lat1
  const d = Math.sqrt(x * x + y * y) * R
  return d.toFixed(2)
}

export const parseSearchQuery = (search) =>
  search &&
  search
    .split(/\?|&/g)
    .reduce((acc, queryParam) => ({ ...acc, [queryParam.split('=')[0]]: queryParam.split('=')[1] }), {})

export const getAverageRating = (intel) => {
  const intelRatings = intel.map(({ impactRating }) => impactRating).filter(Boolean)
  return sum(intelRatings) / intelRatings.length
}

export const getFourUpcomingOrderDates = (orderDates) => {
  if (!orderDates || !orderDates.length) return null
  const startOfToday = moment().startOf('day')
  const dates = orderDates.filter((date) => startOfToday.isBefore(date)).slice(0, 4)
  return dates.length ? dates : null
}

export const formatUpc = (upcString) => {
  const upcLength = upcString.length
  switch (upcLength) {
    case 7:
      return { format: 'ean8', upcString }
    case 6:
      return { format: 'upce', upcString }
    case 13:
      return { format: 'ean13', upcString }
    default:
      return { format: 'upc', upcString: upcString.padStart(11, '0') }
  }
}

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

export function pricify(price) {
  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 0
  }).format(price)
}

export const lastFetchStale = (lastFetch = 0) => Date.now() - lastFetch > 600000

export function kpiOptionsForBrand(brand) {
  if (!brand) return KPI_OPTIONS.all
  const brandSpecificOptions = KPI_OPTIONS[brand] || []
  return KPI_OPTIONS.all.concat(brandSpecificOptions)
}

const kpiSorters = {
  awr: 'rank',
  shrChg: 'posShareChgRank',
  shr: 'posShareRank'
}

export const adjustKpiOption = (option) => {
  return kpiSorters[option] || option
}

const alphabet = '0123456789abcdefghijklmnopqrstuvwxyz-'
const nanoid = customAlphabet(alphabet, 36) // use the same characters and length of current order uuids
// Make sure nanoid doesn't start with - since excel messes up the formatting for it.
export const excelSafeNanoid = () => {
  const newId = nanoid()
  return newId.startsWith('-') ? excelSafeNanoid() : newId
}

const getProgramCutOffDays = (activity) => ([VOLUME_OFFTAKE_PROGRAM, SHARE_GROWTH_PROGRAM].includes(activity) ? 10 : 3)
export const getProgramStatusAndFund = (goFund = {}, program = {}, preferredLanguage = 'english') => {
  program.goFundBudget = `${goFund.englishName} / ${goFund.frenchName}`
  program.goBudgetEndDate = goFund.endDate
  const isPast = program.goFundId ? moment().add(7, 'days').isAfter(program.endDate) : moment().isAfter(program.endDate)
  const cutOffDays = getProgramCutOffDays(program.activity)
  const goPaymentCutOffDate =
    program.goFundId && moment.min(moment(program.goBudgetEndDate), moment(program.endDate)).add(cutOffDays, 'days')
  const goFundIsPastCutOff = program.goFundId && moment().isAfter(goPaymentCutOffDate, 'day')

  const unpaidGoFundHasAdjustment = program.finalCost && !program.officialPaymentAmount
  const isCompleted = program.goFundId
    ? program.officialPaymentAmount || (!unpaidGoFundHasAdjustment && goFundIsPastCutOff)
    : program.finalCost
  const isEnded = !isCompleted && (isPast || unpaidGoFundHasAdjustment)
  program.status = isCompleted ? 'completed' : isEnded ? 'ended' : 'current'
  if (goPaymentCutOffDate) {
    program.daysTilPayDue = goPaymentCutOffDate.diff(moment().startOf('day'), 'days')
  }
  program.programCardInfos = getProgramCardInfos({ program })

  return program
}

export const isNullish = (value) => {
  return value === undefined || value === null
}

export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}

export function setForegroundColor(hex, { dark, light }) {
  const rgb = hexToRgb(hex)
  const sum = Math.round((parseInt(rgb.r) * 299 + parseInt(rgb.g) * 587 + parseInt(rgb.b) * 114) / 1000)
  return sum > 128 ? dark || 'black' : light || 'white'
}

export function getHourDifferenceTimeString(start, end) {
  return (new Date(`1/1/1970 ${end}:00`) - new Date(`1/1/1970 ${start}:00`)) / 3600000
}

export function getEventType({ callType, scheduledType, category }) {
  if (callType) return callType
  if (scheduledType) return scheduledType
  if (category && /vacation|holiday/gi.test(category)) return 'time-off'
  if (category && /lunch/gi.test(category)) return 'lunch'
  if (category && /oot/gi.test(category)) return 'training'
}

export function getAddressString(address, includedElements = ['line1', 'city', 'state', 'country']) {
  if (isEmpty(pick(camelFormatter(address), includedElements))) return '' // return nothing if country is the only element.
  const selectedAddress = pick(camelFormatter({ ...address, country: 'Canada' }), includedElements)
  const addressString = includedElements
    .map((e) => selectedAddress[e])
    .filter(Boolean)
    .join(', ')
    .trim()
  if (!addressString.length) return ''
  return addressString
}

export function getTimeStringFromSeconds(seconds) {
  const minutesElapsed = Math.floor(seconds / 60) || 0
  const hoursElapsed = Math.floor(minutesElapsed / 60) || 0
  const secondsElapsed = Math.floor(seconds % 60) || 0

  return `${hoursElapsed ? String(hoursElapsed).padStart(2, '0') + ':' : ''}${String(minutesElapsed % 60).padStart(
    2,
    '0'
  )}:${String(secondsElapsed).padStart(2, '0')}`
}

export function getDeviceType() {
  return isMobile ? 'Mobile' : 'Desktop'
}

export const checkDateOverlap = (start1, end1, start2, end2) => {
  const start2Overlaps = start2 && start1 && end1 && moment(start2).isBetween(start1, end1, null, '[)')
  const end2Overlaps = end2 && start1 && end1 && moment(end2).isBetween(start1, end1, null, '(]')
  const start1Overlaps = start1 && start2 && end2 && moment(start1).isBetween(start2, end2, null, '[)')
  const end1Overlaps = end1 && start2 && end2 && moment(end1).isBetween(start2, end2, null, '(]')
  return start2Overlaps || end2Overlaps || start1Overlaps || end1Overlaps
}

// FORM MIGRATION UTILS

export const getInputFieldProps = ({ input, field, ...rest }) => {
  return input || field || pick(rest, ['name', 'onChange', 'onBlur', 'value']) // redux-form provides input, formik provides field
}

export const getMeta = ({ form, field, meta }) => {
  if (meta) return meta // redux-form provides meta
  if (form && field) {
    // formik provides form and field
    const formMeta = form.getFieldMeta(field.name)
    // use getIn to accomodate fieldArrays
    return {
      ...formMeta,
      value: getIn(form.values, field.name),
      error: formMeta.error || getIn(form.errors, field.name),
      touched: formMeta.touched || getIn(form.touched, field.name)
    }
  }
  return {}
}

export const isGoFundInPayablePeriod = ({ goFund }) => {
  const todaysDate = moment()
  const startOfPayablePeriod = moment(goFund.startDate)
  return todaysDate.isBetween(startOfPayablePeriod, moment(goFund.endDate).add(2, 'weeks'), 'day')
}

// For all program types, the current date is between the start date and 2 weeks after the end date of a program AND there is budget remaining
// For carton based programs, AND there are cartons booked within the target volume that have not yet been paid out.
export const canPayGoFundNow = ({ goFund, remainingBudget }) => {
  if (!goFund) return false
  if (goFund.paymentType !== GO_FUND_PAYMENT_TYPES.instant) return false
  if (remainingBudget <= 0) return false
  return isGoFundInPayablePeriod({ goFund })
}

export const canUpdateCarton = ({ goFund }) => {
  if (!goFund) return false
  if (goFund.paymentType !== GO_FUND_PAYMENT_TYPES.instant) return false
  return isGoFundInPayablePeriod({ goFund })
}

// Can request an exception if 1 week before the end of program and less than 3 weeks after the end of the program
export const canRequestGoFundException = ({ goFund }) => {
  if (!goFund) return false
  const todaysDate = moment()

  return todaysDate.isBetween(
    moment(goFund.endDate).subtract(1, 'weeks'),
    moment(goFund.endDate).add(3, 'weeks'),
    'day'
  )
}

export function getPowerBalanceTargetsEarned({ program }) {
  if (!program.targets) return 0
  return program.targets.reduce((acc, target) => {
    const { completed, cartonActualAmount, cartonTargetAmount, perCartonCost } = target
    if (!completed) return acc
    const targetReached = cartonActualAmount >= cartonTargetAmount
    if (targetReached) return (acc += cartonTargetAmount * perCartonCost)
    return acc
  }, 0)
}

export function getPowerHourProgramBalance({ program }) {
  const { allocation } = program
  const targetPayouts = getPowerBalanceTargetsEarned({ program })

  const programPaymentsAmount = getCustomerGoFundProgramTotalPayments(program.payments)
  return Math.min(
    program.maxPayout,
    allocation.allocatedBudget - allocation.spent,
    targetPayouts - programPaymentsAmount
  )
}

function isPowerHourAvailable({ goFund }) {
  return goFund.paymentType === GO_FUND_PAYMENT_TYPES.instant && goFund.activity === POWER_HOUR
}

function hasActiveHourPowerTarget({ program }) {
  return program.targets && program.targets.length && program.targets.find((target) => !target.completed)
}

export function getPowerHourStoreBalanceBudget({ program }) {
  const programBalance = getPowerHourProgramBalance({ program })

  return Math.max(programBalance, 0)
}

export function powerHoursFundsLeft({ program }) {
  const { allocation } = program
  const programBalance = getPowerHourProgramBalance({ program })

  /*
    Either funds left in the allocation, or the max payout of a store
  */
  return Math.min(
    Math.max(allocation.allocatedBudget - allocation.spent - programBalance, 0),
    Math.max(program.maxPayout - programBalance, 0)
  )
}

export const canAddCartonsTarget = ({ program }) => {
  if (!program?.goFund) return false
  const { goFund } = program

  const storeBalanceBudget = getPowerHourStoreBalanceBudget({ program })
  const storeRewarded = getCustomerGoFundProgramTotalPayments(program.payments)

  const storeCanStillPay = storeBalanceBudget + storeRewarded < program.maxPayout
  const allocationFundsStillAvailable = program.allocation.spent < program.allocation.allocatedBudget

  return (
    isPowerHourAvailable({ goFund }) &&
    !hasActiveHourPowerTarget({ program }) &&
    storeCanStillPay &&
    allocationFundsStillAvailable
  )
}

export function getProgramActiveTarget({ program }) {
  if (!program?.targets) return null
  return program.targets.find((target) => !target.completed)
}

export const isCartonBasedActivity = ({ activity }) => {
  return [MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_IN, MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_OUT].includes(
    activity
  )
}

export function getCustomerGoFundProgramTotalPayments(programPayments) {
  if (!programPayments?.length) return 0

  return sumBy(programPayments, 'paymentAmount')
}

export const getUnspentAllocation = ({ allocatedBudget, spent }) => Math.max(allocatedBudget - (spent || 0), 0)

export function getGoFundProgramRemainingBudget({ program }) {
  if (!program?.goFund) return 0
  const { goFund } = program
  const customerPayments = getCustomerGoFundProgramTotalPayments(program.payments)
  const valuesToCompare = [getUnspentAllocation(program.allocation)]

  if (program.maxPayout) {
    const amtRemainingFromMaxPayout = program.maxPayout - customerPayments
    valuesToCompare.push(Math.max(amtRemainingFromMaxPayout, 0))
  }

  if (isCartonBasedActivity(goFund)) {
    const maxAmountFromAllowedCartons = Math.min(program.targetVolume, program.ctnsBooked || 0) * program.perCartonCost
    valuesToCompare.push(Math.max(maxAmountFromAllowedCartons - customerPayments, 0))
  }

  return min(valuesToCompare)
}

// Allows a uniform handling of errors thrown by Axios and errors thrown by the code
function errorFormatter(errorMessage) {
  return { response: { data: { message: errorMessage } } }
}

const ERROR_OPTIONS = {
  missingTerritoryId: 'missingTerritoryId',
  missingCustomerId: 'missingCustomerId',
  offline: 'offline',
  generic: 'generic'
}

export const ERRORS = {
  missingTerritoryId: errorFormatter(ERROR_OPTIONS.missingTerritoryId),
  missingCustomerId: errorFormatter(ERROR_OPTIONS.missingCustomerId),
  offline: errorFormatter(ERROR_OPTIONS.offline),
  generic: errorFormatter(ERROR_OPTIONS.generic)
}

const ERROR_MESSAGES = {
  [ERROR_OPTIONS.missingTerritoryId]: 'The selected territory is invalid.',
  [ERROR_OPTIONS.missingCustomerId]: 'The selected customer is invalid.',
  [ERROR_OPTIONS.offline]: 'You are currently offline.',
  [ERROR_OPTIONS.generic]: 'An error has occured. Please try again later.'
}

export function getErrorMessage(error) {
  const { response } = error

  if (response) {
    const errorMessage = response.data.message
      ? response.data.message
      : response.data
      ? JSON.stringify({ status: response.status, message: response.data })
      : 'generic'

    const knownError = ERROR_MESSAGES[errorMessage]
    if (knownError) return knownError
  }

  return `The following error occurred: ${error}. Please contact support.`
}

export function dataIsStillValid(dataFetchesState, dataKey) {
  const existingFetch = dataFetchesState[dataKey]

  if (!existingFetch) return false
  if (existingFetch.status === DATA_UPDATE_STATUS.OLD) return false
  if (existingFetch.status === DATA_UPDATE_STATUS.ERROR) return false
  if (existingFetch.status === DATA_UPDATE_STATUS.LOADING) return false // Was true before, but causes freezes with data staying loading forever.
  return moment().diff(moment(existingFetch.lastFetch), 'minutes') < DATA_EXPIRATION_MINUTES
}

export function getProgramCardInfos({ program }) {
  const {
    activity,
    targetVolume,
    ctnsBooked,
    goFund,
    incrementalTarget,
    seasonalTargetRequired,
    posCtnVolume,
    targetShare,
    startingShare,
    totalMarketVolume
  } = program
  const remainingBudget =
    activity === POWER_HOUR ? getPowerHourStoreBalanceBudget({ program }) : getGoFundProgramRemainingBudget({ program })
  const remainingCartons = max([targetVolume - ctnsBooked, 0])
  const activeTarget = getProgramActiveTarget({ program })
  const totalPayments = getCustomerGoFundProgramTotalPayments(program.payments)
  const cartonsPayoutAvailable = program.ctnsBooked * program.perCartonCost
  const programBudgetRemaining = Math.max(program.allocation.allocatedBudget - program.allocation.spent, 0)
  const allocationRemaining = program.allocation.allocatedBudget - (program.allocation.spent || 0)

  const balanceTargetsEarned = getPowerBalanceTargetsEarned({ program })

  const shareGrowthTargetShare = (targetShare * 100).toFixed(2)
  const shareGrowthStartingShare = (startingShare * 100).toFixed(2)
  const shareGrowthL4Share = posCtnVolume && totalMarketVolume ? posCtnVolume / totalMarketVolume : 0
  const shareGrowthPerformance = shareGrowthL4Share - targetShare
  const shareGrowthPerfColor = shareGrowthL4Share < 0 ? red : null

  const trackedStatsTarget = targetVolume || incrementalTarget || seasonalTargetRequired
  const trackedStatsActualCtns = (activity === VOLUME_OFFTAKE_PROGRAM ? posCtnVolume : ctnsBooked) || 0

  const canPayGoFund = canPayGoFundNow({ goFund, remainingBudget })
  const canUpdateCartonSales =
    goFund.activity === MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_OUT &&
    program.targetVolume - (program.ctnsBooked || 0) > 0
  const canRequestException = canRequestGoFundException({ goFund })
  const canAddCtnsTarget = canAddCartonsTarget({ program })

  return {
    remainingBudget,
    remainingCartons,
    activeTarget,
    totalPayments,
    cartonsPayoutAvailable,
    programBudgetRemaining,
    isCartonBasedActivity: isCartonBasedActivity(goFund),
    allocationRemaining,
    powerHour: {
      balanceTargetsEarned,
      availableFunds: Math.min(allocationRemaining, program.maxPayout) - balanceTargetsEarned
    },
    shareGrowthProgramStats: {
      targetShare: shareGrowthTargetShare,
      startingShare: shareGrowthStartingShare,
      l4Share: (shareGrowthL4Share * 100).toFixed(2),
      performance: shareGrowthPerformance,
      perfColor: shareGrowthPerfColor
    },
    trackedProgramStats: {
      target: trackedStatsTarget,
      actualCtns: trackedStatsActualCtns,
      performance:
        trackedStatsActualCtns && trackedStatsTarget && Math.round((trackedStatsActualCtns / trackedStatsTarget) * 100)
    },
    actionButtons: {
      canPayGoFund,
      canUpdateCartonSales,
      canRequestException,
      canAddCartonsTarget: canAddCtnsTarget
    }
  }
}

function paramsAreDefined(...params) {
  return some(params, (p) => !(p === undefined || p === null))
}

/**
 * Creates a datakey string used to optimize api imports
 * @param {Object} type A member of the DATAKEY_TYPES constant
 * @param {Object} values The values to insert into the dataKey string
 * @returns string
 */
export function createDataKey(type, values) {
  const { keys, create } = type

  if (!keys || !create) throw new Error('Invalid type parameter')

  if (!paramsAreDefined(values, ...keys.map((key) => values[key])))
    throw new Error(`Missing parameter. Expected to find "${keys}" in the values parameters`)

  const res = create(values)
  if (!res) console.error('Data Key Creation returned undefined! Have you forgotten a return?...')
  return res
}

/**
 * Helper function to validate a datakey on store level
 * @param {Object} state Redux state
 * @param {Function} dispatch Redux dispatch
 * @param {String} dataKey The datakey to validate
 * @param {async Function} callback The actual action implementation
 */
export const validateStoreDataKey = async (state, dispatch, dataKey, callback) => {
  const dataFetchesState = state.dataFetches
  if (dataIsStillValid(dataFetchesState, dataKey)) return

  try {
    dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
    if (!window.navigator.onLine) throw ERRORS.offline
    await callback()
    dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
  } catch (e) {
    console.error(e)
    dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
    throw e
  }
}

/**
 * @param {string[]} values
 */
export const createOptions = (values) => {
  const options = sortBy(
    values.map((value) => ({ label: toUpper(value), value })),
    'label'
  )
  const naIndex = options.findIndex((i) => i.value === 'N/A')
  if (naIndex >= 0) {
    const na = pullAt(options, [naIndex])
    options.push(...na)
  }
  return options
}

export const parseNumberString = (number) => {
  if (!number && number !== 0) return null
  return new Intl.NumberFormat('en-CA', {
    notation: 'compact',
    maximumFractionDigits: 1,
    minimumFractionDigits: 1
  }).format(number)
}

export const isWeekend = (date) => {
  return [0, 6].includes(moment(date).day())
}

export const runStressTest = (toRun) => {
  for (let i = 0; i < STRESS_TEST_AMOUNT; i++) {
    toRun()
  }
}

export function generateYAxis({ entries, dataFormat }) {
  if (entries.length && entries.some(({ data }) => data)) {
    return ['auto', 'auto']
  }
  if (dataFormat === 'percent') return [0, 100]
  return [0, 10000]
}

export function listArrayContent(arr, lastSeparator) {
  if (arr.length === 0) return ''
  if (arr.length === 1) return first(arr)
  const lastElement = last(arr)
  return lastSeparator ? `${arr.join(', ')} ${lastSeparator} ${lastElement}` : `${arr.join(', ')} ${lastElement}`
}
