import React, { useContext, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { func } from 'prop-types'

import ActionHeaderContext from 'context/ActionHeaderContext'

import { getCustomer } from 'store/customers/actions'
import * as customerSelectors from 'store/customers/selectors'
import * as territorySelectors from 'store/territories/selectors'

import Button from 'components/button/Button'
import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import GlobalAlert from 'components/GlobalAlert'
import SAQTable from 'components/SAQTable'

const SAQList = ({ getCustomer }) => {
  const { addAction } = useContext(ActionHeaderContext)
  const navigate = useNavigate()
  const { sectorId: customerId } = useParams()

  const bannerOwnership = useSelector((state) => customerSelectors.getBannerOwnership(state, { customerId }))
  const customer = useSelector((state) => customerSelectors.customerFromUrl(state, { customerId }))
  const region = useSelector((state) => territorySelectors.getRegion(state, { customerId }))
  const { AWR13, WTD } = useSelector((state) => customerSelectors.getPerfData(state, { customerId }))
  const orderComplianceThresholds = useSelector((state) =>
    customerSelectors.orderComplianceThresholds(state, { customerId })
  )

  const isCreditBlocked = customer.creditBlock === 'Y'
  const isCentrallyBlocked = customer.centralOrderBlock === '01'

  useEffect(() => {
    if (customerId) getCustomer(customerId)
  }, [customerId, getCustomer])

  useEffect(() => {
    addAction({
      getCreateButton: () =>
        !customer?.saqCreationDisabled ? (
          <Button key={customerId} onClick={() => navigate('create')} primary>
            New Order
          </Button>
        ) : null
    })
  }, [customerId, customer?.saqCreationDisabled])

  const isOrderApprovalExempt =
    customer?.orderApprovalExempt || bannerOwnership?.orderApprovalExempt || region?.orderApprovalExempt

  return (
    <>
      <Container>
        {customer.saqCreationDisabled
          ? (isCreditBlocked || isCentrallyBlocked) && (
              <GlobalAlert>Account is {isCentrallyBlocked ? 'centrally' : 'credit'} blocked</GlobalAlert>
            )
          : !isOrderApprovalExempt && (
              <div className="mx-4">
                <GlobalAlert warning>
                  <p>
                    <small>AWR13: {AWR13 || '0'}</small>
                    <br />
                    <small>WTD: {WTD || '0'}</small>
                  </p>
                  <p>
                    <small>{orderComplianceThresholds.emergency} cartons left to order before reaching threshold</small>
                    <br />
                    <small>{orderComplianceThresholds.saq} cartons left to SAQ before reaching threshold</small>
                  </p>
                  <p>
                    <small>
                      If over threshold, the authorization email will be sent to{' '}
                      <u>{customer?.primaryContact?.email}</u>. If this email address is incorrect, please contact the
                      Marketing Systems team.
                    </small>
                  </p>
                </GlobalAlert>
              </div>
            )}

        {customer.orders?.length ? (
          <SAQTable orders={customer.orders} linkToDetails={(orderId) => navigate(orderId)} />
        ) : (
          <EmptyState title="Nothing yet" subtitle="There are no SAQs for this customer." />
        )}
      </Container>
      <Outlet />
    </>
  )
}

SAQList.propTypes = {
  getCustomer: func
}

export default connect(null, { getCustomer })(SAQList)
