import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import config from 'config'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'

import ClickableTile from 'components/ClickableTile'

import { SECTOR_LEVELS } from 'utils/constants'

const Container = tw.div`grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 p-5 max-md:pt-20 gap-4 justify-center`

const CloseIndex = () => {
  const { sectorType } = useParams()
  const { translate } = useContext(LangContext)

  const TILES = [
    {
      title: translate('close.consumerOffer'),
      description: translate('close.consumerOffer.description'),
      to: 'consumer-offer',
      order: 1,
      comingSoon: false,
      disabled: sectorType === SECTOR_LEVELS.TERRITORY
    },
    {
      title: translate('close.i2a'),
      description: '', // translate('close.i2a.description')
      to: 'insight-to-action',
      order: 2,
      comingSoon: !config.featureFlags.i2a
    }
  ]

  return (
    <Container>
      {TILES.map((tile) => (
        <ClickableTile key={tile.title} content={tile} comingSoon={tile.comingSoon} />
      ))}
    </Container>
  )
}

export default CloseIndex
