import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel } from 'store/sector/selectors'

const getCurrentExtraHub = createSelector(dataSourceFromSectorLevel, (sectorData) => sectorData?.extraHub || {})
export const getCurrentTrending = createSelector(getCurrentExtraHub, (hub) => {
  if (!hub.trending) return []

  return hub.trending.map((m) => ({
    name: m.month,
    erp: m.storeCompletion ?? null,
    user: m.userCompletion ?? null
  }))
})

export const getCurrentEngagement = createSelector(
  getCurrentExtraHub,
  (hub) =>
    hub?.engagement || {
      customerCount: 0,
      activeCustomers: 0,
      engagement: 0
    }
)

export const getCurrentFilters = createSelector(getCurrentExtraHub, (hub) => hub?.filters || [])

export const getCurrentGeographies = createSelector(getCurrentExtraHub, (hub) => hub?.geographies || [])

export const getCurrentActivity = createSelector(getCurrentExtraHub, (hub) => {
  return {
    erp: hub?.activity?.storeCompletion || 0,
    totalErp: hub?.activity?.totalStores || 0,
    user: hub?.activity?.userCompletion || 0,
    totalUser: hub?.activity?.totalUsers || 0,
    coins: hub?.activity?.coinsEarned || 0
  }
})
