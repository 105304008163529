import React, { useContext } from 'react'
import { string } from 'prop-types'

import MediaContext from 'context/MediaContext'

import ProductTypePicker from 'components/ProductTypePicker'
import ViewHeader from 'components/ViewHeader'
const ExtraHubHeader = ({ parentHeader, header }) => {
  const { isWideLayout } = useContext(MediaContext)
  const filters = isWideLayout ? [] : [<ProductTypePicker key="amplify-extra-hub-product-type-picker" />]
  return <ViewHeader parentHeader={parentHeader} header={header} filters={filters} />
}

ExtraHubHeader.propTypes = {
  parentHeader: string.isRequired,
  header: string.isRequired
}

export default ExtraHubHeader
