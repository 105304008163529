import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { bool, object, string } from 'prop-types'
import tw from 'twin.macro'

import SectorContext from 'context/SectorContext'

import { amplifySelloutBrandPerfData } from 'store/Sellout/selectors'

import Card from 'components/card'
import DataTable from 'components/DataTable/DataTable'
import DataVariation from 'components/DataTable/DataVariation'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'
import Tooltip from 'components/Tooltip'

import {
  formatCompactCurrency,
  formatCompactNumber,
  formatCurrency,
  formatNumber,
  formatPercent
} from 'utils/formatters'

const FIRST_COL = {
  vape: {
    field: 'name',
    headerName: 'Brand Variant'
  },
  fmc: {
    field: 'name',
    headerName: 'Brand'
  },
  nrt: {
    field: 'name',
    headerName: 'Brand'
  }
}

const TIME_COLS = {
  rolling: [
    {
      field: 'mainPeriod',
      headerName: 'L4'
    },
    {
      field: 'variation',
      headerName: 'vs. P4'
    }
  ],
  pointInTime: [
    {
      field: 'mainPeriod',
      headerName: 'CTD'
    },
    {
      field: 'variation',
      headerName: 'vs. PC'
    }
  ]
}

const CardToolTip = tw(Tooltip)`h-full flex`

function generateRows(data, dataFormatter, tooltipFormatter) {
  if (!data) return []

  return data.map(({ name, mainPeriod, variation }) => ({
    name,
    mainPeriod: (
      <CardToolTip isNumber hint={tooltipFormatter(mainPeriod)}>
        {dataFormatter(mainPeriod)}
      </CardToolTip>
    ),
    variation: (
      <CardToolTip isNumber hint={tooltipFormatter(variation)}>
        <DataVariation variation={variation} formatter={dataFormatter} />
      </CardToolTip>
    )
  }))
}

const AmplifySelloutBrandCard = ({
  span,
  currentProportion,
  currentTimeDisplay,
  currentMetric,
  currentChannel,
  currentVapeCategory,
  dataType,
  selloutDataLoading
}) => {
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const cols = [FIRST_COL[currentProductType]].concat(TIME_COLS[currentTimeDisplay])
  const selloutData = useSelector((state) =>
    amplifySelloutBrandPerfData(state, {
      currentSector,
      selectedLevel,
      activeProductType: currentProductType,
      currentChannelFilter: currentChannel,
      currentProportion,
      currentTimeDisplay,
      currentMetric,
      currentChannel,
      currentVapeCategory,
      dataType
    })
  )
  const dataFormatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true })
      : currentMetric === 'cost'
      ? formatCompactCurrency
      : formatCompactNumber
  const tooltipFormatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true })
      : currentMetric === 'cost'
      ? formatCurrency
      : formatNumber
  const cardProps = {
    title: currentProductType === 'fmc' ? 'Brand' : 'Brand Variant',
    span,
    displayAmplify: false
  }

  if (selloutDataLoading) {
    return (
      <Card {...cardProps}>
        <WrappedSpinner icon="spinner" />
      </Card>
    )
  }
  if (isEmpty(selloutData)) {
    return (
      <Card {...cardProps}>
        <EmptyState title="No data" />
      </Card>
    )
  }
  return (
    <Card {...cardProps}>
      <div className="max-h-[490px] overflow-y-auto">
        <DataTable columns={cols} rows={generateRows(selloutData, dataFormatter, tooltipFormatter)} fillContainer />
      </div>
    </Card>
  )
}

AmplifySelloutBrandCard.propTypes = {
  span: object,
  currentProportion: string,
  currentTimeDisplay: string,
  currentMetric: string,
  currentChannel: string,
  currentVapeCategory: string,
  dataType: string,
  selloutDataLoading: bool
}

export default AmplifySelloutBrandCard
