import { createAction } from 'redux-actions'

import { AMPLIFY_SELECTOR_ACTIONS, PLANNING_SELECTOR_ACTIONS } from 'store/actions'
import { setDataFetch } from 'store/dataFetches/actions'

import { DATA_UPDATE_STATUS } from 'utils/constants'
import { dataIsStillValid, ERRORS, runStressTest } from 'utils/helpers'

import * as api from './endpoints'

export const fetchPlanningSellout = createAction(
  'Get Planning Sell-out Values',
  (props, dataKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches

    if (dataIsStillValid(dataFetchesState, dataKey)) return
    try {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline
      const { data } = await api.fetchPlanningSellout(props)
      const addResultToScope = PLANNING_SELECTOR_ACTIONS[props.sectorLevel].sellout
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )

      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))

      // STRESS TEST - TO REMOVE
      runStressTest(() => api.fetchPlanningSellout(props))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

const geographyIdCol = {
  customer: 'customerId',
  headoffice: 'headOffice',
  territory: 'territoryId',
  district: 'districtId',
  region: 'regionId',
  province: 'province',
  sku: 'productId'
}

const dataTypeIdCols = {
  fmc: ['brand', 'manufacturer'],
  vape: ['brand', 'vapeCategory', 'variantLabel'],
  nrt: ['brand', 'vapeCategory', 'variantLabel']
}

const getUniqByCols = ({ geography, type }) => {
  return [].concat(geographyIdCol[geography]).concat(dataTypeIdCols[type]).filter(Boolean)
}

export const fetchAmplifySellout = createAction(
  'Get Amplify Sell-out Values',
  (props, dataKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches

    if (dataIsStillValid(dataFetchesState, dataKey)) return
    try {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline
      const { data } = await api.fetchAmplifySellout(props)
      const addResultToScope = AMPLIFY_SELECTOR_ACTIONS[props.sectorLevel].sellout.main
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )

      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))

      // STRESS TEST - TO REMOVE
      runStressTest(() => api.fetchAmplifySellout(props))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

export const fetchAmplifySelloutGeographyData = createAction(
  'Get Amplify Sell-out Geography Values',
  ({ filters, ...props }, dataKey) =>
    async (dispatch, getState) => {
      const dataFetchesState = getState().dataFetches

      if (dataIsStillValid(dataFetchesState, dataKey)) return
      try {
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
        if (!window.navigator.onLine) throw ERRORS.offline
        const { data } = await api.fetchAmplifySelloutGeographyData({ ...filters, ...props })
        const addResultToScope = AMPLIFY_SELECTOR_ACTIONS[props.sectorLevel].sellout.geography

        dispatch(
          addResultToScope({
            ...data,
            ...props,
            filters,
            uniqByCols: getUniqByCols(props)
          })
        )

        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))

        // STRESS TEST - TO REMOVE
        runStressTest(() => api.fetchAmplifySelloutGeographyData({ ...filters, ...props }))
      } catch (e) {
        console.error(e)
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
        throw e
      }
    }
)

// export const fetchAmplifySelloutSkuData = createAction(
//   'Get Amplify Sell-out Sku Values',
//   (props, dataKey) => async (dispatch, getState) => {
//     const dataFetchesState = getState().dataFetches

//     if (dataIsStillValid(dataFetchesState, dataKey)) return
//     try {
//       dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
//       if (!window.navigator.onLine) throw ERRORS.offline
//       const { data } = await api.fetchAmplifySelloutSkuData(props)
//       // console.log('SELECTOR LEVEL', props.sectorLevel, AMPLIFY_SELECTOR_ACTIONS[props.sectorLevel].sellout)
//       const addResultToScope = AMPLIFY_SELECTOR_ACTIONS[props.sectorLevel].sellout.geography
//       // console.log({ data, props })
//       dispatch(
//         addResultToScope({
//           ...data,
//           ...props,
//           geography: 'sku'
//         })
//       )

//       dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
//     } catch (e) {
//       console.error(e)
//       dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
//       throw e
//     }
//   }
// )
