import React from 'react'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import { bool, node, string } from 'prop-types'

const ToolTip = ({ hint, isNumber, children }) => (
  <RadixTooltip.Root>
    <RadixTooltip.Trigger asChild>
      <div>{children}</div>
    </RadixTooltip.Trigger>
    <RadixTooltip.Portal>
      <RadixTooltip.Content
        sideOffset={5}
        className="z-[200] max-w-64 rounded bg-slate-800 px-3 py-2 text-center text-sm text-white shadow data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade"
      >
        {isNumber && hint ? hint.toLocaleString('default') : hint}
        <RadixTooltip.Arrow />
      </RadixTooltip.Content>
    </RadixTooltip.Portal>
  </RadixTooltip.Root>
)

ToolTip.propTypes = {
  children: node.isRequired,
  isNumber: bool,
  hint: string
}

export default ToolTip
