import React, { useState } from 'react'
import { node } from 'prop-types'

const CalendarWrapper = ({ children, ...rest }) => {
  const [isStyleSheetLoaded, setIsStyleSheetLoaded] = useState(false)

  const handleStylesheetLoaded = () => {
    setIsStyleSheetLoaded(true)
  }

  return (
    <div {...rest}>
      <link rel="stylesheet" href="/styles/calendarDefaultStyles.css" onLoad={handleStylesheetLoaded} />

      {isStyleSheetLoaded && <>{children}</>}
    </div>
  )
}

CalendarWrapper.propTypes = {
  children: node.isRequired
}

export default CalendarWrapper
