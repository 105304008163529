import { createAction } from 'redux-actions'

import { PLANNING_SELECTOR_ACTIONS } from 'store/actions'

import { validateStoreDataKey } from 'utils/helpers'

import * as api from './endpoints'

export const fetchPlanningPriceCheck = createAction(
  'Get Planning Price Check Values',
  ({ dataKey, sectorType, sectorId, category = 'fmc' }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const { data } = await api.fetchPlanningPriceCheck({ sectorType, sectorId, category })

        const addResultToScope = PLANNING_SELECTOR_ACTIONS[sectorType].priceCheck
        dispatch(
          addResultToScope({
            id: sectorId,
            activeProductType: category,
            data
          })
        )
      })
)
