import React from 'react'
import { bool, func, number } from 'prop-types'

import IconButton from 'components/button/IconButton'

const Pagination = ({ currentPage, onClickNext, onClickPrev, disabled = false, disableNextButton = false }) => {
  return (
    <div className="flex items-center gap-2">
      <IconButton ghost left="true" icon="left-chevron" onClick={onClickPrev} disabled={disabled || currentPage <= 1} />
      <span className="w-8 text-center text-lg">{currentPage}</span>
      <IconButton
        ghost
        right="true"
        icon="right-chevron"
        onClick={onClickNext}
        disabled={disabled || disableNextButton}
      />
    </div>
  )
}

Pagination.propTypes = {
  currentPage: number.isRequired,
  onClickNext: func.isRequired,
  onClickPrev: func.isRequired,
  disabled: bool,
  disableNextButton: bool
}

export default Pagination
