import queryString from 'query-string'

import { api } from 'store/api'

const getRoot = (sectorType, sectorId) => `/price-capture-compliance/${sectorType}/${sectorId}`
export const getStoreFootprint = (sectorType, sectorId, productType) =>
  api.get(`${getRoot(sectorType, sectorId)}/store-footprint?${queryString.stringify({ productType })}`)

export const getBrandCompletion = (sectorType, sectorId, productType) =>
  api.get(`${getRoot(sectorType, sectorId)}/brand-completion?${queryString.stringify({ productType })}`)

export const getFilters = (sectorType, sectorId, productType) =>
  api.get(`${getRoot(sectorType, sectorId)}/filters?${queryString.stringify({ productType })}`)

export const getGeography = (sectorType, sectorId, geography, productType, offset, limit, filters) =>
  api.get(
    `${getRoot(sectorType, sectorId)}/geography?${queryString.stringify({
      geography,
      productType,
      offset,
      limit,
      ...filters
    })}`
  )

export const getStrategyCompliance = (sectorType, sectorId, productType) =>
  api.get(`${getRoot(sectorType, sectorId)}/strategy-compliance?${queryString.stringify({ productType })}`)

export const getEdlpCompliance = (sectorType, sectorId) => api.get(`${getRoot(sectorType, sectorId)}/edlp-compliance`)

export const getPriceComplianceGeographyFilters = (sectorType, sectorId, productType) =>
  api.get(
    `${getRoot(sectorType, sectorId)}/price-compliance-geography-filters?${queryString.stringify({ productType })}`
  )

export const getPriceComplianceGeography = (sectorType, sectorId, geography, productType, filters) =>
  api.get(
    `${getRoot(sectorType, sectorId)}/price-compliance-geography?${queryString.stringify({
      ...filters,
      geography,
      productType
    })}`
  )
