import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func, object } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchMaterialsInventory } from 'store/inventory/actions'
import { getCurrentMaterials } from 'store/inventory/selectors'

import Card from 'components/card'
import DataCompare from 'components/DataTable/DataCompare'
import DataTable from 'components/DataTable/DataTable'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { formatNumber } from 'utils/formatters'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import InventoryCaptureContext from './InventoryCaptureContext'

const BVLS = ({ span, fetchMaterialsInventory }) => {
  const { sectorType, sectorId } = useParams()
  const [error, setError] = useState()
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])
  const [sortBy, setSortBy] = useState({ column: 'oos', order: 'desc' })

  const { currentProductType: productType } = useContext(SectorContext)
  const { translate } = useContext(LangContext)
  const { timeframe, vapeCategory } = useContext(InventoryCaptureContext)

  const COLS = [
    {
      field: 'oos',
      headerName: translate('app.OOS')
    },
    {
      field: 'inventoryCapture',
      headerName: translate('app.InvCapture')
    }
  ]

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.INVENTORY_OOS.MATERIALS, {
    sectorType,
    sectorId,
    productType,
    timeframe,
    vapeCategory,
    sortBy: sortBy.column,
    direction: sortBy.order
  })
  const isMaterialLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const materials = useSelector((state) => getCurrentMaterials(state))

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (productType === 'fmc' && vapeCategory !== 'all') return

    fetchMaterialsInventory({
      dataKey,
      sectorType,
      sectorId,
      timeframe: Number(timeframe),
      productType,
      vapeCategory,
      sortBy: sortBy.column,
      direction: sortBy.order
    })
      .then(() => {
        if (isMounted.current) {
          setError()
        }
      })
      .catch((e) => {
        if (isMounted.current) {
          setError(e)
        }
      })
  }, [sectorType, sectorId, productType, timeframe, vapeCategory, sortBy])

  useEffect(() => {
    const values = materials[`${productType}-${vapeCategory}-${timeframe}-${sortBy.column}-${sortBy.order}`] || []
    const newRows = values.map((product) => ({
      product: product.material,
      oos: (
        <DataCompare
          last={`${formatNumber(product.oos * 100) || '-'}`}
          variation={Number(formatNumber(product.oosDiff * 100)) || 0}
          stacked
          isPercent
          reverseVariation
          timeframe={timeframe}
          variationBefore
        />
      ),
      inventoryCapture: (
        <DataCompare
          last={`${formatNumber(product.inventoryCapture * 100) || '-'}`}
          variation={Number(formatNumber(product.inventoryCaptureDiff * 100)) || 0}
          stacked
          isPercent
          timeframe={timeframe}
          variationBefore
        />
      )
    }))

    setRows(newRows)

    const updatedColumns = COLS.map((col) => ({ ...col, subHeader: timeframe === '1' ? 'vs PW' : 'vs P4' }))
    setColumns([
      {
        field: 'product',
        headerName: translate('app.geo.SKU')
      },
      ...updatedColumns
    ])
  }, [materials, productType, timeframe, vapeCategory, sortBy])

  const setSorted = (columnClicked) => {
    if (columnClicked === sortBy.column) {
      if (sortBy.order === 'desc') {
        setSortBy({ column: columnClicked, order: 'asc' })
      } else {
        setSortBy({ column: columnClicked, order: 'desc' })
      }
    } else {
      setSortBy({ column: columnClicked, order: 'desc' })
    }
  }

  const renderContent = () => {
    if (isMaterialLoading) return <WrappedSpinner icon="spinner" />

    if (error) {
      return <EmptyState title={getErrorMessage(error)} />
    }

    return rows.length ? (
      <div className="max-h-72 overflow-y-auto">
        <DataTable
          onColumnClick={setSorted}
          activeColumn={sortBy}
          unClickableColumns={['product']}
          columns={columns}
          rows={rows}
          fillContainer
        />
      </div>
    ) : (
      <EmptyState title="No data yet" />
    )
  }

  return (
    <Card title={translate('app.geo.SKU')} span={span} displayAmplify={false}>
      {renderContent()}
    </Card>
  )
}

BVLS.propTypes = {
  span: object,
  fetchMaterialsInventory: func
}

export default connect(null, {
  fetchMaterialsInventory
})(BVLS)
